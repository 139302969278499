import React from 'react'
import { EmptyDataShippingIcon } from '../../../../asset/icon/shipping'

export default function EmptyDataShipping() {
  return (
    <div className='flex justify-center flex-col items-center'>
      <EmptyDataShippingIcon/>
      <h6 className='text-gray-500 text-md font-semibold'>Pengiriman kosong</h6>
      <p className='text-gray-400'>Kamu belum memiliki pesanan yang perlu dikirimkan</p>
    </div>
  )
}
