import React from 'react'
import Header from '../../mainDashboard/header'
import { MBGIconOrange } from '../../../asset/icon/mainDashboard/sideBar'
import { Link, useNavigate } from 'react-router-dom'
import { CartIcon, LogoutIcon, MessageIcon, NotificationWthNumber, PinIcon, StoreIcon, UserIcon, WalletIcon } from '../../../asset/icon/helpCenterDashboard/navbar'
import { categoryList, dataCart, dataChat, dataDetailKonsumen, dataNotifikasi } from './dataDummy'

export default function Navbar() {
    const navigate = useNavigate()
    const data = false
    const isHovered = false
    const role = 'vendor'
  return (
    <div className="w-full z-20 shadow-sm fixed bg-white ">
    <Header />
    <div className="mx-[40px] py-[10px] text-primaryColor flex justify-between gap-[20px] items-center">
        <div onClick={() => navigate(`/`)} className="cursor-pointer">
            {/* <MBGIconOrange /> */} Pusat Bantuan
        </div>
        {/* <div
            onClick={handleToggle(setShowSharedCategory)}
            className="h-[50px] px-[26px] py-[13px] flex-1 mr-[20px] rounded-[100px] border border-zinc-200 items-center gap-2.5 inline-flex"
        >
            <div className="relative w-full">
                <input
                    placeholder="Cari Makanan"
                    className="text-neutral-400 outline-none w-full text-base font-normal font-Inter leading-normal p-2"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                />
                {showResults && (data?.datas?.length ?? 0) > 0 && (
                    <div className="absolute mt-1 w-full bg-white shadow-lg border border-gray-200 rounded-md z-10">
                        {data && data?.datas?.length > 0 && (
                            <ul className="max-h-48 overflow-y-auto">
                                {data?.datas?.map((item, index) => (
                                    <li
                                        onClick={() => handleNavigateProduct(item.nama)}
                                        key={index}
                                        className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <div className="flex gap-2 items-center">
                                            <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.678 16.6067L9.96296 10.8907C7.42063 12.6981 3.91739 12.256 1.90383 9.87358C-0.109735 7.49116 0.038116 3.96324 2.24396 1.75765C4.44922 -0.4489 7.97748 -0.59739 10.3603 1.41607C12.7432 3.42953 13.1856 6.9331 11.378 9.47565L17.093 15.1917L15.679 16.6057L15.678 16.6067ZM6.48596 1.99964C4.58966 1.99921 2.95365 3.33033 2.56843 5.18709C2.18321 7.04385 3.15467 8.91593 4.89463 9.66989C6.6346 10.4239 8.66486 9.85248 9.7562 8.30171C10.8475 6.75093 10.7 4.64697 9.40296 3.26365L10.008 3.86365L9.32596 3.18365L9.31396 3.17165C8.56574 2.41883 7.54735 1.99678 6.48596 1.99964Z"
                                                    fill="#25282B"
                                                />
                                            </svg>
                                            <p className="font-medium">{item.nama}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                        {data && data?.toko?.length > 0 && (
                            <ul className="max-h-42 overflow-y-auto scrollbar-hide">
                                {data?.toko.map((item, index) => (
                                    <li
                                        key={index}
                                        className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <img src={item.profile_pict} alt="Profile" className='h-[38px] w-[38px] rounded-full' />
                                        <div>
                                            <p className="font-medium">{item.namaToko}</p>
                                            <p className="text-sm text-gray-500">{item.userId}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>
        </div> */}
        <div className="flex items-right overflow-hidden">
            <div className="flex h-[26px] pt-0.5 justify-between items-center gap-8 inline-flex">
                {dataChat && dataChat?.data ? (
                    <div onClick={() => navigate('/profile/chat')} className="w-6 h-6 relative">
                        <MessageIcon msgLength={dataChat?.data.length} />
                    </div>
                ) : (
                    <div onClick={() => navigate('/profile/chat')} className="w-6 h-6 relative">
                        <MessageIcon msgLength={0} />
                    </div>
                )}
                <div onClick={() => navigate('/profile/notification')} className="w-6 h-6 relative">
                    <NotificationWthNumber notifLength={dataNotifikasi?.total || 0} />
                </div>
                {/* <div onClick={() => navigate('/detail-product/cart-products')} className="w-6 h-6 relative">
                    <CartIcon cartLength={dataCart.data?.datas.length || 0} />
                </div> */}
            </div>
            <div className="ml-8  h-full origin-top-left border-[1px] w-[1px] border-zinc-200 my-auto"></div>
            {/* Profil */}
            <div className="flex items-center ml-auto space-x-2">
                {dataDetailKonsumen ? (
                    <div
                        className="flex items-center space-x-2 cursor-pointer"
                        // onMouseEnter={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                    >
                        <img
                            // src={dataDetailKonsumen?.datas?.profile_pict || ''}
                            alt="profile"
                            className="w-[32px] h-[32px] object-cover rounded-full bg-[#31DC41]"
                        />

                        <p className="text-[16px]  font-inter font-bold text-right">
                            {dataDetailKonsumen && dataDetailKonsumen?.datas && dataDetailKonsumen?.datas?.nama ? (
                                <span
                                    title={dataDetailKonsumen?.datas?.nama}
                                    className="inline-block capitalize max-w-full whitespace-nowrap overflow-hidden"
                                >
                                    {dataDetailKonsumen?.datas?.nama?.length > 7
                                        ? `${dataDetailKonsumen?.datas?.nama?.slice(0, 8)}...`
                                        : dataDetailKonsumen?.datas?.nama}
                                </span>
                            ) : (
                                'No Name'
                            )}
                        </p>
                    </div>
                ) : (
                    <div
                        className="flex items-center space-x-2"
                        // onMouseEnter={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                    >
                        <div className="bg-white"><UserIcon/></div>
                        <p className="text-[16px] font-inter font-bold">No Data Available</p>
                    </div>
                )}
            </div>
            {isHovered && (
                <div
                    className="fixed mt-[30px] mr-[24px] bg-white shadow-md rounded-lg right-[0px] p-[10px] text-left"
                    // onMouseEnter={handleMouseEnter}
                    // onMouseLeave={handleMouseLeave}
                >
                    <div
                        onClick={() => navigate(`/profile/setting`)}
                        className="flex mb-[5px] p-[5px] hover:bg-slate-100 active:bg-slate-300 hover:rounded-md cursor-pointer"
                    >
                        <img
                            // src={dataDetailKonsumen?.datas?.profile_pict || ''}
                            alt="profile"
                            className="w-[30px] h-[30px] object-cover rounded-full bg-[#31DC41]"
                        />
                        <p className="font-inter p-[5px]">Profile</p>
                    </div>
                    <div
                        // onClick={handleLogout}
                        className="flex  p-[5px] hover:bg-slate-100 active:bg-slate-300 hover:rounded-md cursor-pointer"
                    >
                        <LogoutIcon/>
                        <p className="pl-[5px]">Keluar</p>
                    </div>
                </div>
            )}
            {/*role vendor  di tampilkan yang ini */}
            {isHovered && role === 'vendor' && (
                <div
                    // ref={dropdownSharedRef}
                    className="fixed mt-[30px] bg-white border shadow-md rounded-lg right-[0px] w-[180px] p-[10px] text-left"
                    // onMouseEnter={handleMouseEnter}
                    // onMouseLeave={handleMouseLeave}
                >
                    <Link to={`/profile`}>
                        <div className="flex gap-[16px] mt-[8px] border-b  px-[8px] py-[4px]">
                            <img
                                // src={userData?.datas.profile_pict}
                                alt="profile"
                                className="w-[30px] h-[30px] object-cover rounded-full bg-[#31DC41]"
                            />

                            <p className=" text-[14px] font-normal font-[inter] py-[6px] mr-[30px]">Profil</p>
                            <p className="bg-[#CC0A58] w-[8px] h-[8px] rounded-full my-[10px]"></p>
                        </div>
                    </Link>
                    <Link to={`/vendor/pesanan`}>
                        <div className="flex gap-[16px]  border-b px-[8px] py-[4px] ">
                            <div className="w-[30px] h-[30px] bg-[#D9ECEA] rounded-full p-[8px]">
                                <StoreIcon/>
                            </div>
                            <p className=" text-[14px] font-normal font-[inter] py-[6px]">Toko Saya</p>
                        </div>
                    </Link>
                    <div
                        // onClick={handleLogout}
                        className="flex gap-[16px] ml-[8px] hover:bg-slate-100 active:bg-slate-300 hover:rounded-md cursor-pointer"
                    >
                        <div className="w-[30px] h-[30px]  p-[8px]"><LogoutIcon/></div>
                        <p className="p-[5px]">Keluar</p>
                    </div>
                </div>
            )}
        </div>
    </div>
    {/* <div className="flex justify-between mx-[40px] pb-[10px]">
        {categoryList ? (
            <div className="h-6 justify-start items-center inline-flex">
                {categoryList.data.slice(0, 4).map((item, index) => (
                    <div
                        key={index}
                        className="capitalize pr-[24px] cursor-pointer "
                        onClick={() => handleNavigateToCategory(item._id, item.name)}
                    >
                        <p className="text-neutral-400 text-base font-normal font-Inter leading-tight">
                            {item.name}
                        </p>
                    </div>
                ))}
                <div>
                    <span className="text-[#A6A7A8] pr-[24px]">|</span>
                </div>
                <div>
                    <button
                        onClick={handleToggle(setShowCategory)}
                        className="text-gray-500 text-base font-medium font-Inter leading-tight"
                    >
                        Pilih Kategori
                    </button>
                </div>
            </div>
        ):null}
        <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
                <WalletIcon/>
                <p className="h-4 text-gray-500 text-sm font-medium font-Inter leading-[16.80px]">
                    Saldo Rp 5k
                </p>
            </div>
            {data ? (
                <div className="flex gap-[8px]">
                    <PinIcon/>
                    alamat produsen
                    {detailAddress.datas[0].regency}
                </div>
            ) : (
                <div className="flex items-center space-x-1">
                    <PinIcon/>
                    <p className="text-zinc-800 text-base font-medium font-Inter leading-tight">
                        Isi Alamat{' '}
                        <span className="text-gray-500 text-base font-medium font-Inter leading-tight">
                            Kamu dulu
                        </span>
                    </p>
                </div>
            )}
        </div>
    </div> */}
</div>
  )
}
