import React from 'react'
import ReadMore from './readMore';
import { DataDetailChat } from '../../../../../model/chat/detail';

interface ChatBubleState {
    data: DataDetailChat
}
export default function ChatBuble({ data }: ChatBubleState) {
    const chatMsg = (chat: string) => {
        try {
            const parsedData = JSON.parse(chat);
            return parsedData.msg || "Format pesan tidak valid";
        } catch (error) {
            return "Error parsing message";
        }
    };
    const formatTgl = (tgl: string) => {
        const date = new Date(tgl);

        // Memformat waktu menjadi hh:mm
        const formattedTime = date.toLocaleTimeString("id-ID", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
        });

        return `${formattedTime}`;
    }
    return (
        <div className='w-full flex flex-col gap-[16px] mt-[8px]'>
            {data.messages.map((msg) => (
                <div
                    key={msg._id}
                    className={`flex ${msg.sender.role === 'supplier' ? 'justify-end' : 'justify-start'} mb-4`}
                >
                    <div
                        className={`p-2 rounded-lg max-w-[360px] ${chatMsg(msg.content).length < 50 ? 'items-center' : 'flex-col'} flex  ${msg.sender.role === 'supplier' ? 'bg-blue-100 rounded-xl rounded-tr-none' : 'bg-gray-100 rounded-tl-none'}`}
                        style={{ wordBreak: 'break-word', overflowWrap: 'break-word', paddingBottom: '8px' }}
                    >
                        <div className="flex-1">
                            <ReadMore text={chatMsg(msg.content)} maxWords={20} />
                        </div>
                        <span className={`${chatMsg(msg.content).length > 50 ? 'mt-[-16px]' : 'ml-[8px] mt-[-20px]'} text-xs text-gray-400 mt-2`} style={{ alignSelf: 'flex-end' }}>
                            {formatTgl(msg.timestamp)}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}
