interface togle {
    head: string;
    text: string;
    action: () => void;
    consition: boolean;
}
const TogleCard = ({ head, text, action, consition }: togle) => {
    return (
        <div className="rounded-lg p-[8px] w-full flex justify-between items-center">
            <div className="max-w-[335px]">
                <h2 className="text-[#4A4D4F] text-[16px] font-semibold">{head}</h2>
                <p className="text-[#818284] text-[14px] font-normal">{text}</p>
            </div>
            <div className="flex justify-end">
                <div
                    onClick={action}
                    className={`relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer transition-colors ${
                        consition ? 'bg-primaryColor' : 'bg-gray-300'
                    }`}
                >
                    <span
                        className={`inline-block w-4 h-4 transform rounded-full bg-white transition-transform ${
                            consition ? 'translate-x-6' : 'translate-x-1'
                        }`}
                    />
                </div>
            </div>
        </div>
    );
};

export default TogleCard;