import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StepFindCategoryState {
  step: number;
}

const initialState: StepFindCategoryState = {
  step: 1,
};

const findCategoryCatalog = createSlice({
  name: "catalog find category",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
        state.step = action.payload;
      },
  },
});

export const { setStep } = findCategoryCatalog.actions;
export default findCategoryCatalog.reducer;
