export const getColorHandler = (status: string) => {
    switch (status) {
        case 'Dikemas':
            return '#FFECB2';
        case 'Kadaluarsa':
            return '#F7F7F7';
        case 'Menunggu Distributor':
            return '#FFE6E6';
        case 'diserahkan ke distributor':
            return '#EEE0FF';
        case 'menunggu penjemputan':
            return '#E6F2F1';
        case 'sedang dalam perjalanan':
            return '#E3F5E0';
        case 'telah diterima konsumen':
            return '#E6F2F1';
        default:
            return '#0000';
    }
};

export const getColorTextHandler = (status: string) => {
    switch (status) {
        case 'Dikemas':
            return '#CB9A06';
        case 'Kadaluarsa':
            return '#25282B';
        case 'Menunggu Distributor':
            return '#FB60BD';
        case 'diserahkan ke distributor':
            return '#9747FF';
        case 'menunggu penjemputan':
            return '#BF962C';
        case 'sedang dalam perjalanan':
            return '#46BB2F';
        case 'telah diterima konsumen':
            return '#2CBFA8';
        default:
            return '#0000';
    }
};