import React, { useState } from 'react'
import CategoryDropDown from './CategoryDropDown'
import { useAppSelector } from '../../../../../../redux/store';

interface categoryInputProps{
  setIdCategory: (value: string) => void
  value: string
}
export default function Categoryinput({
  setIdCategory,
  value
}: categoryInputProps) {
  const [showDropDown, setShowDropDown] = useState(false);
  // const value = useAppSelector((state) => state.CatalogAddStatus.dataInputNonVarian?.category)
  const handleSummit = () => {
    setShowDropDown(!showDropDown)
  }
  return (
    <div>
      <p className="text-[16px] mb-[8px] font-inter font-semibold">Kategori Produk</p>
      <input
        type="text"
        placeholder="Menu Utama/Makanan dan Minuman/Makanan Utama"
        readOnly
        value={value}
        onClick={() => setShowDropDown(!showDropDown)}
        className="py-[16px] border border-slate-300 w-full h-[50px] rounded-lg pl-[20px] outline-none capitalize"
      />
      {showDropDown && (
        <CategoryDropDown submit={handleSummit} setIdCategory={setIdCategory} />
      )}
    </div>
  )
}
