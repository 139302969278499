import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    status: null,
    message: "",
  },
  reducers: {
    setError: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    clearError: (state) => {
      state.status = null;
      state.message = "";
    },
  },
});

export const { setError, clearError } = errorSlice.actions;
export default errorSlice.reducer;