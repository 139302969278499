import React from 'react'
import { TriangleWarningIcon } from '../../../../../asset/icon/catalog/detail'

export default function PendingReviewAlert() {
  return (
    <div className="bg-[#F7F7F7] flex justify-between items-center h-[40px] py-3 px-3">
            <div className="space-x-3 flex">
                <TriangleWarningIcon />
                <p className="font-inter font-normal text-sm text-center text-[#1069E5]">
                    Produk kamu sedang dalam proses tinjauan superapp. Produk kamu akan segera terunggah setelah
                    tinjauan selesai.
                </p>
            </div>
        </div>
  )
}
