// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../../api/axiosInstance";
import { processedFoodDetailProps } from "../../../../model/catalog/add/type/processedFoodList/processedFoodDetail/interface";

interface foodClasificationDetailState {
  loading: boolean;
  error: string | null;
  processedFoodDetail: processedFoodDetailProps | null;
}

const initialState: foodClasificationDetailState = {
  loading: false,
  error: null,
  processedFoodDetail: null,
};

// AsyncThunk untuk mengambil detail produsen
export const getProcessedFoodDetail = createAsyncThunk(
  "processedFoodDetail",
  async (typeName: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/pangan?kelompok_pangan=${typeName}&jenis_pangan=makanan olahan`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const processedFoodDetail = createSlice({
  name: "food category type processedFoodDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProcessedFoodDetail.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(getProcessedFoodDetail.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.processedFoodDetail = action.payload; // Simpan data yang berhasil di-fetch
      })
      .addCase(getProcessedFoodDetail.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default processedFoodDetail.reducer;
