// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../api/axiosInstance";
import { DeviceLoginListProps } from "../../../../../model/profile/setting/loginActivity/interface";

interface deviceActivityListState {
  loading: boolean; 
  error: string | null;
  deviceList: DeviceLoginListProps | null; 
}

const initialState: deviceActivityListState = {
  loading: false,
  error: null,
  deviceList: null,
};

// AsyncThunk untuk mengambil addressList
export const getDeviceLoginList = createAsyncThunk(
  "profile/security/loginActivity",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`user/login-activities`);
      return response.data; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const loginActivityDeviceList = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceLoginList.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(getDeviceLoginList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.deviceList = action.payload; 
      })
      .addCase(getDeviceLoginList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default loginActivityDeviceList.reducer;
