import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useAppSelector } from "../../../redux/store";
import LoadingSpinner from "../../loadSpinner";
import OtpInput from "react-otp-input";
import axiosInstance from "../../../api/axiosInstance";

export default function InputSection() {
  const location = useLocation();
  const navigate = useNavigate();
  const dataDetailProdusen = useAppSelector(
    (state: RootState) => state.DetailProdusen.produsenDetail
  );
  const changeData = useAppSelector(
    (state: RootState) => state.DataChangeBio.dataChangeBio
  );
  const dataDellAccount = useAppSelector((state) => state.DellAccountReason);
  const [timer, setTimer] = useState(300); // time otp expired
  const [reSendTime, setResendTime] = useState(60); // time otp expired
  const [resendEnabled, setResendEnabled] = useState(false);
  const [OtpNumber, setOtpNumber] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const enabled: boolean = OtpNumber.length === 4;
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };
  const inputStyles: React.CSSProperties = {
    width: "50px",
    height: "50px",
    borderRadius: "12px",
    border: isErr
      ? "1px solid red"
      : enabled
      ? "1px solid #1454B8"
      : "1px solid #C7C9D9",
    outline: "none",
  };
  //----------------------------any Function-----------------------------------------------
  const handleChangeEmail = () => {
    const body = {
      email: changeData?.newEmail,
      id: location.state.id,
    };
    axiosInstance
      .post("/user/edit-auth", body)
      .then((res) => {
        console.log(res);
        navigate("/profile/setting", { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangePhone = () => {
    const body = {
      phone: changeData?.newPhone,
      id: location.state.id,
    };
    axiosInstance
      .post("/user/edit-auth", body)
      .then((res) => {
        console.log(res);
        navigate("/profile/setting", { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleVerifyNewEmail = () => {
    const body = {
      email: changeData?.newEmail,
      id: location.state.id,
    };
    axiosInstance
      .post("/user/otp/edit-auth/new", body)
      .then((res) => {
        console.log(res);
        console.log("udah ngehit ke email baru");
        setOtpNumber("");
        navigate("/otp", {
          state: { source: "change-email-verify", id: location.state.id },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleVerifyNewPhone = () => {
    const body = {
      phone: changeData?.newPhone,
      id: location.state.id,
    };
    axiosInstance
      .post("/user/otp/edit-auth/new", body)
      .then((res) => {
        console.log(res);
        console.log("udah ngehit ke nope baru");
        setOtpNumber("");
        navigate("/user/otp", {
          state: { source: "change-phone-verify", id: location.state.id },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //----------------------------spesifik handling here-------------------------------------
  //function for handling resend and submit otp by source dell account---------------------------------
  const handlingDellAccount = () => {
    const body = {
        reason: dataDellAccount.reason
    }
    axiosInstance.post(`/user/delete-account-request`, body)
    .then((res) => {
        console.log(res);
        navigate('/delete-account/request-submitted')
    }).catch((err) => {
        console.log(err);
    })
    console.log("handling dell account");
  };
  const handleResendOtpDellAccount = () => {
    const body = dataDetailProdusen?.datas?.userId.email.content;
    axiosInstance
      .post(`/user/otp/delete-account`, body)
      .then((res) => {
        console.log(res);
        navigate("/auth/otp?source=delete-account");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitOtpDellAccount = () => {
    const body = {
      kode_otp: OtpNumber,
      id: dataDellAccount.id,
    };
    axiosInstance
      .post(`/verify-otp/delete`, body)
      .then((res) => {
        handlingDellAccount();
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("handling dell acoount here");
  };
  //function for handling resend and submit otp by source dell account---------------------------------
  const handleSubmitForgotPin = () => {
    setIsLoading(true);
    const body = {
      kode_otp: OtpNumber,
      token_reset: location.state.resetToken,
      id: location.state.id,
    };
    axiosInstance
      .post(`/verify-otp/reset-credentials`, body)
      .then((res) => {
        console.log(res);
        navigate("/pin/reset", {
          state: {
            source: "reset-pin",
            id: location.state.id,
            token: location.state.resetToken,
          },
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setIsErr(true);
        }
        setIsErr(true);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    console.log(body);
    console.log("submit forgot pin here");
  };
  const handleResendOtpForgotPin = () => {
    const body = {
      email: dataDetailProdusen?.datas?.userId.email.content,
    };
    axiosInstance
      .post(`/forget/pin`, body)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitVerifyPhoneNumber = () => {
    setIsLoading(true);
    const body = {
      kode_otp: OtpNumber,
      id: location.state.id,
    };
    axiosInstance
      .post(`/verify-otp/verify-credentials?type=phone`, body)
      .then((res) => {
        console.log(res);
        navigate("/profile/setting");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleResendVerifyPhoneNumber = () => {
    const body = {
      phone: dataDetailProdusen?.datas?.userId.phone.content,
    };
    axiosInstance
      .post(`/user/verify-credentials`, body)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitChangeEmail = () => {
    setIsLoading(true);
    const body = {
      id: location.state.id,
      kode_otp: OtpNumber,
    };
    axiosInstance
      .post("/verify-otp/verify-reset-auth/old", body)
      .then((res) => {
        console.log(res);
        handleVerifyNewEmail();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setIsErr(true);
        }
        setIsErr(true);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleResendChangeEmail = () => {
    const body = {
      email: dataDetailProdusen?.datas?.userId.email.content,
    };
    axiosInstance
      .post("/user/otp/edit-auth/old", body)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitChangeNewEmail = () => {
    setIsLoading(true);
    const body = {
      id: location.state.id,
      kode_otp: OtpNumber,
    };
    axiosInstance
      .post("/verify-otp/verify-reset-auth/new", body)
      .then((res) => {
        console.log(res);
        handleChangeEmail();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setIsErr(true);
        }
        setIsErr(true);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleResendChangeNewEmail = () => {
    const body = {
      email: changeData?.newEmail,
      id: location.state.id,
    };
    axiosInstance
      .post("/user/otp/edit-auth/new", body)
      .then((res) => {
        console.log(res);
        console.log("udah ngehit ke email baru");
        setOtpNumber("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitChangePhone = () => {
    setIsLoading(true);
    const body = {
      id: location.state.id,
      kode_otp: OtpNumber,
    };
    axiosInstance
      .post("/verify-otp/verify-reset-auth/old", body)
      .then((res) => {
        console.log(res);
        handleVerifyNewPhone();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setIsErr(true);
        }
        setIsErr(true);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleResendChangePhone = () => {
    const body = {
      phone: dataDetailProdusen?.datas?.userId.phone.content,
    };
    axiosInstance
      .post("/user/otp/edit-auth/old", body)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitChangeNewPhone = () => {
    setIsLoading(true);
    const body = {
      id: location.state.id,
      kode_otp: OtpNumber,
    };
    axiosInstance
      .post("/verify-otp/verify-reset-auth/new", body)
      .then((res) => {
        console.log(res);
        handleChangePhone();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setIsErr(true);
        }
        setIsErr(true);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleResendChangeNewPhone = () => {
    const body = {
      phone: changeData?.newPhone,
      id: location.state.id,
    };
    axiosInstance
      .post("/user/otp/edit-auth/new", body)
      .then((res) => {
        setOtpNumber("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = () => {
    if (location.state.source === "forgot-pin") {
      handleSubmitForgotPin();
    } else if (location.state.source === "verify-phoneNumber") {
      handleSubmitVerifyPhoneNumber();
    } else if (location.state.source === "change-email") {
      handleSubmitChangeEmail();
    } else if (location.state.source === "change-email-verify") {
      handleSubmitChangeNewEmail();
    } else if (location.state.source === "change-phone") {
      handleSubmitChangePhone();
    } else if (location.state.source === "change-phone-verify") {
      handleSubmitChangeNewPhone();
    } else if (location.state.source === "delete-account") {
      handleSubmitOtpDellAccount();
    } else {
      alert(
        `handling submit by ${location.state.source} belum tersedia, harap hubungi developer`
      );
    }
  };
  const handleOtpChange = (value: string) => {
    const numericValue = value.replace(/\D/g, "").slice(0, 4);
    setOtpNumber(numericValue);
  };
  const handleResendOtp = () => {
    setTimer(300);
    setResendTime(60);
    setResendEnabled(false);
    if (location.state.source === "forgot-pin") {
      handleResendOtpForgotPin();
    } else if (location.state.source === "verify-phoneNumber") {
      handleResendVerifyPhoneNumber();
    } else if (location.state.source === "change-email") {
      handleResendChangeEmail();
    } else if (location.state.source === "change-email-verify") {
      handleResendChangeNewEmail();
    } else if (location.state.source === "change-phone") {
      handleResendChangePhone();
    } else if (location.state.source === "change-phone-verify") {
      handleResendChangeNewPhone();
    } else if (location.state.source === "delete-account") {
      handleResendOtpDellAccount();
    } else {
      alert(
        `handling resend Otp by ${location.state.source} belum tersedia, harap hubungi developer`
      );
    }
  };
  useEffect(() => {
    if (timer === 0) {
      setResendEnabled(true);
      return;
    }

    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(countdown);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer, reSendTime]);
  return (
    <div>
      <div className="w-[513px] h-auto bg-[#FFFF] rounded-xl border border-[#C7C9D9] py-[50px] px-[50px] flex justify-center flex-col">
        <div className="flex flex-col w-[395px]">
          <p className="font-inter font-bold text-[32px] leading-9 text-center text-[#222831]">
            Masukan Kode OTP
          </p>
          <div className="h-11 mt-5">
            <p className="font-inter font-medium text-lg text-center text-custom-second">
              Masukan kode OTP yang sudah terkirim lewat{" "}
              {location.state.source === "change-email" ||
              location.state.source === "forgot-pin" ||
              location.state.source === "delete-account"
                ? `Email ${dataDetailProdusen?.datas.userId.email.content}`
                : location.state.source === "change-email-verify"
                ? `Email ${changeData?.newEmail}`
                : location.state.source === "change-phone"
                ? `Nomor ${dataDetailProdusen?.datas.userId.phone.content}`
                : location.state.source === "change-phone-verify"
                ? `Nomor ${changeData?.newPhone}`
                : null}
            </p>
          </div>
        </div>
        {/* ----- WRAPPER INPUT OTP ---- */}
        <div className="w-[413px] h-auto mt-[30px] text-center">
          <div className="h-auto mb-[20px] ">
            <OtpInput
              value={OtpNumber}
              onChange={handleOtpChange}
              numInputs={4}
              renderSeparator={<span style={{ marginLeft: "33px" }}></span>}
              containerStyle={{ display: "flex", justifyContent: "center" }}
              renderInput={(
                props: React.InputHTMLAttributes<HTMLInputElement>
              ) => <input {...props} />}
              shouldAutoFocus={false}
              inputStyle={inputStyles}
            />
            {isErr && (enabled || isErr) ? (
              <p className="text-sm text-red-700 text-center mt-2">
                Kode OTP Salah
              </p>
            ) : null}
          </div>
          <span>{formatTime(timer)}</span>
          <div className="mt-[20px] text-center">
            <button
              onClick={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              className={`${
                enabled && !isLoading && !isErr
                  ? "bg-primaryColor"
                  : "bg-[#F3F4F8] cursor-not-allowed "
              } rounded-full py-3 px-5 w-[413px] mb-[10px] outline-none ${
                isLoading ? "cursor-progress" : ""
              }`}
              disabled={!enabled || isLoading || isErr}
            >
              <p
                className={`${
                  enabled && !isLoading && !isErr
                    ? "text-[#FFFF]"
                    : "text-[#BFBFBF]"
                } font-roboto font-medium text-sm text-center`}
              >
                {isLoading ? (
                  <LoadingSpinner width="18px" height="18px" />
                ) : (
                  "Kirim Kode OTP"
                )}
              </p>
            </button>
            <button
              onClick={handleResendOtp}
              disabled={!resendEnabled}
              className={` font-inter text-sm font-semibold text-primaryColor text-center cursor-pointer ${
                !resendEnabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Kirim Ulang Kode OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
