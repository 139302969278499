import React from "react";
import HelpCenterSection from "../../../component/helpCenterDashboard/section";

export default function HelpCenterMain() {
  return (
    <div>
      <div className="text-[#FFFFFF] font-bold text-[32px]">
        <div>Selamat Siang,</div>
        <div>Ada yang bisa kami bantu?</div>
      </div>
      <HelpCenterSection />
    </div>
  );
}
