import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../api/axiosInstance";
import { ChatInterface } from "../../../../../model/chat/list";

export const GetListChat = createAsyncThunk("chat/list", async () => {
  const response = await axiosInstance.get(`/chat/list`);
  return response.data;
});

// Redux slice
const ChatList = createSlice({
  name: "ChatList",
  initialState: {
    data: null as ChatInterface | null,
    loading: false,
    error: null as string | null | undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetListChat.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetListChat.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetListChat.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default ChatList.reducer;
