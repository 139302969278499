import React from "react";
import ListData from "./listData";
import ShippingSkeleton from "./skeleton";
import EmptyDataShipping from "./emptyData";
import { RootState, useAppSelector } from "../../../redux/store";

export default function ShippingList() {
  const { shippingData } = useAppSelector(
    (state: RootState) => state.ShippingData
  );
  const loadingShipping = useAppSelector(
    (state: RootState) => state.ShippingData.loading
  );
  const loadingDetailProdusen = useAppSelector(
    (state: RootState) => state.DetailProdusen.loading
  );
  const loading = loadingShipping || loadingDetailProdusen;

  return (
    <>
      {loading ? (
        <ShippingSkeleton />
      ) : shippingData && shippingData.datas.length > 0 ? (
        <ListData />
      ) : (
        <EmptyDataShipping />
      )}
    </>
  );
}
