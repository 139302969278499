// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../../api/axiosInstance";
import { mainCategoryProps } from "../../../../../../model/catalog/add/category/mainCategory";

interface MainCategoryState {
  loading: boolean; 
  error: string | null;
  mainCategory: mainCategoryProps | null; 
}

const initialState: MainCategoryState = {
  loading: false,
  error: null,
  mainCategory: null,
};

// AsyncThunk untuk mengambil detail produsen
export const getMainCategory = createAsyncThunk(
  "catalog/getmaincategory",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/category/all-main-category`);
      return response.data; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const catalogMainCategory = createSlice({
  name: "main category list catalog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMainCategory.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(getMainCategory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.mainCategory = action.payload; // Simpan data yang berhasil di-fetch
      })
      .addCase(getMainCategory.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default catalogMainCategory.reducer;
