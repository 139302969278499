import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../api/axiosInstance";
import { shippingProps } from "../../../../model/shipping";
interface ShippingState {
  shippingData: shippingProps | null;
  loading: boolean;
  error: string | null;
}
const initialState: ShippingState = {
  shippingData: null,
  loading: false,
  error: null,
};
export const getShippingData = createAsyncThunk("shipping/list", async () => {
  const response = await axiosInstance.get(`/supplier/toko/proses-pengiriman`);
  return response.data;
});

// Redux slice
const Notification = createSlice({
  name: "get shipping data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShippingData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getShippingData.fulfilled, (state, action) => {
        state.loading = false;
        state.shippingData = action.payload;
      })
      .addCase(getShippingData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch shipping list";
      });
  },
});

export default Notification.reducer;
