import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { SubHelpCenterListProps } from "../../../../../model/help-center/sub";
import axiosInstance from "../../../../../api/axiosInstance";

interface subHelpCenterListState {
  loading: boolean;
  error: string | null;
  subHelpCenterList: SubHelpCenterListProps | null;
};


const initialState: subHelpCenterListState = {
  loading: false,
  error: null,
  subHelpCenterList: null,
};

export const getAllSubHelpCenterList = createAsyncThunk(
  "get all sub help center",
  async (id: string) => {
    try {
      const response = await axiosInstance.get(
        `/sub-category-informasi-bantuan/list-sub-judul/${id}`
      );
      return response.data;
    } catch (error: any) {
      return error.response?.data || "Terjadi kesalahan";
    }
  }
);

const subHelpCenter = createSlice({
  name: "sub help center list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubHelpCenterList.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(
        getAllSubHelpCenterList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.subHelpCenterList = action.payload; // Simpan data yang berhasil di-fetch
        }
      )
      .addCase(
        getAllSubHelpCenterList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload; // Simpan pesan error jika request gagal
        }
      );
  },
});

export default subHelpCenter.reducer;
