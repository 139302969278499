import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { HelpCenterListProps } from "../../../../../model/help-center/main";
import axiosInstance from "../../../../../api/axiosInstance";

interface helpCenterListState {
  loading: boolean;
  error: string | null;
  helpCenterList: HelpCenterListProps | null;
}

const initialState: helpCenterListState = {
  loading: false,
  error: null,
  helpCenterList: null,
};

export const getAllHelpCenterList = createAsyncThunk(
  "get all help center",
  async () => {
    try {
      const response = await axiosInstance.get(
        `/category-utama-informasi-bantuan/list-judul-utama?role=supplier`
      );
      return response.data;
    } catch (error: any) {
      return error.response?.data || "Terjadi kesalahan";
    }
  }
);

const helpCenter = createSlice({
  name: "help center list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllHelpCenterList.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(
        getAllHelpCenterList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.helpCenterList = action.payload; // Simpan data yang berhasil di-fetch
        }
      )
      .addCase(
        getAllHelpCenterList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload; // Simpan pesan error jika request gagal
        }
      );
  },
});

export default helpCenter.reducer;
