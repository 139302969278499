import React from "react";
import { RootState, useAppSelector } from "../../../../redux/store";
import { detailListChatState } from "../../../../model/chat/list";
import { FormatChatDateTime, GetFormatedMessageValue } from "../../../../utils/chat";
interface ListState {
  filter: string;
  idChatActive: string;
  setChatActiveState: (value: string) => void;
}
export default function List({
  filter,
  idChatActive,
  setChatActiveState,
}: ListState) {
  const data = useAppSelector((state: RootState) => state.ChatList.data?.data);
  return (
    <div className="flex flex-col gap-[10px] mt-[12px] h-full overflow-y-auto no-scrollbar">
      {data &&
      (filter.length > 0
        ? data?.filter((item) =>
            item.lawanBicara.nama.toLowerCase().includes(filter.toLowerCase())
          )
        : data
      ).length > 0 ? (
        (filter.length > 0
          ? data.filter((item) =>
              item.lawanBicara.nama.toLowerCase().includes(filter.toLowerCase())
            )
          : data
        ).map((item, index) => (
          <div onClick={() => setChatActiveState(item._id)} key={index}>
            <div
              className={`${
                item._id === idChatActive ? "bg-secondaryColor" : ""
              } cursor-pointer border-[#DDE5E9] border-[1px] py-[15px] px-[10px] rounded-[12px] flex w-full`}
            >
              <img
                alt="profile"
                src={item.lawanBicara.profile_pict}
                className="rounded-full w-[67px] object-cover"
              ></img>
              <div className="ml-[10px] flex-1 min-w-0">
                <h6 className="font-medium text-[20px] truncate">
                  {item.lawanBicara.nama}
                </h6>

                {item.messages.length > 0 ? (
                  <p className="font-medium text-[14px] mt-[8px] truncate capitalize">
                    {GetFormatedMessageValue(
                      item.messages[item.messages.length - 1].content
                    )}
                  </p>
                ) : (
                  <p className="font-medium text-[14px] mt-[8px]">
                    Tidak ada pesan
                  </p>
                )}
              </div>
              {item.messages.length > 0 ? (
                <p className="text-[12px] text-[#6D7588] ml-auto mt-[8px]">
                  {FormatChatDateTime(
                    item.messages[item.messages.length - 1].timestamp
                  )}
                </p>
              ) : (
                <p className="text-[12px] text-[#6D7588] ml-auto mt-[8px]">-</p>
              )}
            </div>
          </div>
        ))
      ) : (
        // Tampilkan pesan jika tidak ada hasil
        <p className="text-center text-gray-500 mt-4">
          Tidak ada hasil untuk <strong>"{filter}"</strong>.
        </p>
      )}
    </div>
  );
}
