import React, { useEffect } from "react";
import { Category } from "../../../../../../../model/catalog/add/category";
import { CategorySection } from "./categoryCard";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../redux/store";
import { getMainCategory } from "../../../../../../../redux/slices/pages/catalog/categoryProduct/mainCategory";
import LoadingSpinner from "../../../../../../loadSpinner";
import { getSecondCategory } from "../../../../../../../redux/slices/pages/catalog/categoryProduct/secondCategory";

interface CategoryDropDownProps {
  submit: () => void;
  setIdCategory: (value: string) => void;
}

export default function CategoryDropDown({
  submit,
  setIdCategory,
}: CategoryDropDownProps) {
  const dispatch = useAppDispatch();
  const [isLoadingMain, setIsLoadingMain] = React.useState<boolean>(false);
  const [isLoadingSecond , setIsLoadingSecond] = React.useState<boolean>(false);
  const stepFindCategory = useAppSelector((state) => state.FindCategory.step);
  const dataMainCategory = useAppSelector((state) => state.MainCategory);
  const dataSecondCategory = useAppSelector((state) => state.SecondCategory);
  const dataThirdCategory = useAppSelector((state) => state.ThirdCategory);

  useEffect(() => {
    const fetchMainCategories = async () => {
      setIsLoadingMain(true);
      try {
        await dispatch(getMainCategory());
      } finally {
        setIsLoadingMain(false);
      }
    };

    fetchMainCategories();
  }, []);

  const handleMainCategoryClick = async (categoryId: string) => {
    setIsLoadingSecond(true);
    try {
      await dispatch(getSecondCategory(categoryId)); // Panggil kategori kedua berdasarkan ID
      setIdCategory(categoryId); // Set ID kategori utama
    } finally {
      setIsLoadingSecond(false);
    }
  };
  
  return (
    <div className="py-6">
      <h1 className="mb-6 text-2xl font-bold text-gray-900">Kategori</h1>
      {isLoadingMain ? (
        <div className="flex justify-center items-center h-40">
          <LoadingSpinner width="36px" height="36px" />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {dataMainCategory &&
            dataMainCategory.mainCategory &&
            (stepFindCategory === 1 || 2 || 3) && (
              <CategorySection
                key="main"
                mainCategories={dataMainCategory.mainCategory?.data}
                onCategoryClick={(category) =>
                  console.log("Clicked:", category)
                }
                setCategoryId={setIdCategory}
              />
            )}
          {dataSecondCategory &&
            dataSecondCategory.secondCategory &&
            (stepFindCategory === 2 || 3) && (
              <CategorySection
                key="second"
                secondCategory={
                  dataSecondCategory.secondCategory.data.sub_categories
                }
                onCategoryClick={(category) =>
                  console.log("Clicked:", category)
                }
                setCategoryId={setIdCategory}
              />
            )}
          {dataThirdCategory &&
            dataThirdCategory.thirdCategory &&
            stepFindCategory === 3 && (
              <CategorySection
                key="third"
                thirdCategory={dataThirdCategory.thirdCategory?.datas.contents}
                onCategoryClick={(category) =>
                  console.log("Clicked:", category)
                }
                setCategoryId={setIdCategory}
              />
            )}
        </div>
      )}
      <section className="flex justify-end mt-4">
        <button
          onClick={submit}
          className={`px-6 py-3 rounded-full bg-primaryColor text-white`}
        >
          Selesai
        </button>
      </section>
    </div>
  );
}
