export interface MyStoreListProps {
  message: string;
  data: MyStoreDetail;
}

export interface MyStoreDetail {
  namaToko: string;
  nilai_pinalti: number;
  nilai_review: number;
  pengikut: number;
  penilaian_produk: null;
  profile_pict: string;
  store_description: string;
  userId: string;
  address: Address;
  waktu_operasional: WaktuOperasional[];
  _id: string;
  __v: number;
}

export interface WaktuOperasional {
  hari: string;
  buka: boolean;
  jam_operasional: JamOperasional;
}

export interface JamOperasional {
  mulai?: string;
  tutup?: string;
}

export interface Address {
  pinAlamat: PinAlamat;
  _id: string;
  province: string;
  regency: string;
  district: string;
  village: string;
  code_pos: number;
  address_description: string;
  userId: string;
  isPic: boolean;
  isMain: boolean;
  isStore: boolean;
  isSchool: boolean;
  isUsed: boolean;
  label_alamat: string;
  __v: number;
}

export interface PinAlamat {
  long: Long;
  lat: Lat;
}

export interface Long {
  $numberDecimal: string;
}

export interface Lat {
  $numberDecimal: string;
}

export const nameTimeDays: string[] = [
  "senin",
  "selasa",
  "rabu",
  "kamis",
  "jumat",
  "sabtu",
  "minggu",
];

export interface UpdatedDetailTokoPayload {
  waktu_operasional: WaktuOperasional[];
  store_description: string;
}
