export const InputElementReadOnly = ({
  title,
  value,
  width = "100",
  height = "48",
  type = "text",
  onClick,
  cursor = "not-allowed",
  placeholder = "This placeholder...",
}: {
  title: string;
  value: any | undefined;
  width?: string;
  height?: string;
  type?: string;
  onClick?: () => void;
  cursor?: string;
  placeholder?: string;
}) => {
  return (
    <div className="flex flex-col space-y-2">
      <p className="font-inter font-semibold text-sm text-[#404040] capitalize">
        {title}
      </p>
      <input
        type={type}
        value={value !== undefined ? value : ""}
        onClick={onClick}
        readOnly
        placeholder={placeholder}
        className={`border border-[#DDE5E9] rounded-lg outline-none font-inter font-normal text-sm text-[#4A4D4F] p-4 bg-[#DDE5E9] cursor-${cursor}`}
        style={{ width: `${width}%`, height: `${height}px` }}
      />
    </div>
  );
};

interface InputTextareaProps {
  title: string;
  value: string | undefined;
  height?: string;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const InputTextarea: React.FC<InputTextareaProps> = ({
  title,
  value,
  handleChange,
  height = "120px",
}) => {
  return (
    <div className="mt-3">
      <p className="font-inter font-semibold text-sm text-[#404040] capitalize">
        {title}
      </p>
      <textarea
        value={value !== undefined ? value : ""}
        onChange={handleChange}
        placeholder="Type something here..."
        style={{ height }}
        className="p-4 border border-[#D9D9D9] font-inter font-normal text-sm text-[#4A4D4F] resize-none rounded-lg w-full outline-none"
      ></textarea>
    </div>
  );
};

export const InputElementEditable = ({
  title,
  value,
  onChange,
  width = "100",
  height = "48",
  type = "text",
  placeholder = "Enter text here...",
}: {
  title: string;
  value: any | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  height?: string;
  type?: string;
  placeholder?: string;
}) => {
  return (
    <div className="flex flex-col space-y-2">
      <p className="font-inter font-semibold text-sm text-[#404040] capitalize">
        {title}
      </p>
      <input
        type={type}
        value={value !== undefined ? value : ""}
        onChange={onChange}
        placeholder={placeholder}
        className={`border border-[#DDE5E9] rounded-lg outline-none font-inter font-normal text-sm text-[#4A4D4F] p-4 bg-white cursor-text`}
        style={{ width: `${width}%`, height: `${height}px` }}
      />
    </div>
  );
};

interface toggleProps {
  head: string;
  text: string;
  action: () => void;
  consition: boolean;
}
export const ToggleCard = ({ head, text, action, consition }: toggleProps) => {
  return (
    <div className="rounded-lg p-[8px] w-full flex justify-between items-center">
      <div className="max-w-[335px]">
        <h2 className="text-[#4A4D4F] text-[16px] font-semibold">{head}</h2>
        <p className="text-[#818284] text-[14px] font-normal">{text}</p>
      </div>
      <div className="flex justify-end">
        <div
          onClick={action}
          className={`relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer transition-colors ${
            consition ? "bg-custom-primaryvendor" : "bg-gray-300"
          }`}
        >
          <span
            className={`inline-block w-4 h-4 transform rounded-full bg-white transition-transform ${
              consition ? "translate-x-6" : "translate-x-1"
            }`}
          />
        </div>
      </div>
    </div>
  );
};
