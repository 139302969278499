import React from "react";
import HelpCenter from "./penjual";

function HelpCenterSection() {
  const [status, setStatus] = React.useState<string>("Penjual");
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const renderContent = () => {
    switch (status) {
      case "Pembeli":
        return <HelpCenter type="pembeli" searchTerm={searchTerm} />;
      case "Penjual":
        return <HelpCenter type="penjual" searchTerm={searchTerm} />;
      default:
        return <div>Konten tidak ditemukan.</div>;
    }
  };
  return (
    <div className="pt-8">
      <section
        className={`w-[1128px] bg-[#FFFFFF] rounded-[16px] mx-auto py-[24px] px-[16px] shadow-md`}
      >
        <div className="flex">
          <div
            className={`px-10 py-2 text-sm cursor-pointer ${
              status === "Pembeli"
                ? "font-medium text-primaryColor border-b-2 border-primaryColor"
                : "text-[#818284] font-normal"
            }`}
            onClick={() => setStatus("Pembeli")}
          >
            Pembeli
          </div>
          <div
            className={`px-10 py-2 text-sm cursor-pointer ${
              status === "Penjual"
                ? "font-medium text-primaryColor border-b-2 border-primaryColor"
                : "text-[#818284] font-normal"
            }`}
            onClick={() => setStatus("Penjual")}
          >
            Penjual
          </div>
        </div>
        <div className="h-[50px] my-14 px-[26px] py-[13px] flex-1 mr-[20px] rounded-[100px] border border-zinc-200 items-center gap-2.5 inline-flex">
          <div className="relative w-full">
            <input
              placeholder={`Cari di ${status}`}
              className="text-neutral-400 outline-none w-[754px] text-base font-normal font-Inter leading-normal p-2"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="text-[#343637] font-bold text-base mb-4">
          Kategori Kendala
        </div>
        <div>{renderContent()}</div>
      </section>
    </div>
  );
}

export default HelpCenterSection;
