import React, { useState } from "react";
import List from "./list";
import Empty from "./empty";
import ChatListSkeleton from "./skeleton";
import { RootState, useAppSelector } from "../../../redux/store";

interface ListChatState {
  setChatActiveState: (value: string) => void;
  idChatActive: string;
}
export default function ListChat({
  setChatActiveState,
  idChatActive,
}: ListChatState) {
  const [filter, setFilter] = useState("");
  const { data, loading } = useAppSelector(
    (state: RootState) => state.ChatList
  );
  return (
    <div className="w-1/3 h-full px-3">
      <h6 className="font-medium text-[24px] mb-[15px]">Pesan</h6>
      <input
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Cari Nama"
        className="w-full border-[0.5px] border-[#DDE5E9] py-[5px] px-[16px] rounded-[8px]"
      ></input>
      {loading ? (
        <ChatListSkeleton />
      ) : data ? (
        <List
          filter={filter}
          setChatActiveState={setChatActiveState}
          idChatActive={idChatActive}
        />
      ) : (
        <Empty />
      )}
    </div>
  );
}
