import React from 'react'
import { Notifikasi } from '../../../../model/notification'
import { FormatedTimeToWib } from '../../../../utils/notification'
interface notificationCardState {
    data: Notifikasi
}

export default function NotificationCard({
    data
}: notificationCardState) {
    return (
        <div className='bg-[#F3F6FB] rounded-[16px] py-[8px] px-[24px] w-full flex gap-[24px] items-center'>
            <img alt="" className='w-[56px] h-[56px] rounded-[7px]' src={data.image_product}></img>
            <div>
                <h4 className='text-[#222831] text-[18px] font-semibold'>{data.status}</h4>
                <h6 className='text-[#828282] text-[14px] font-normal my-[8px]'>{data.message}</h6>
                <p className='text-[#C7C9D9] text-[12px] font-normal'>{FormatedTimeToWib(data.createdAt)}</p>
            </div>
        </div>
    )
}
