import { FacebookIcon, InstagramIcon, MBGIconOrange, TwitterIcon, WordpressIcon, YoutubeIcon } from "../../../asset/icon/helpCenterDashboard/footer";

export default function Footer() {
    return (
        <footer className="w-full bg-white py-8 border-t border-gray-200 mb-[38px] mt-[160px]">
            <div className="mx-auto flex flex-col md:flex-row justify-between items-start space-y-6 md:space-y-0">
                <div className="text-center md:text-left">
                    <MBGIconOrange />
                    <p className="text-gray-500 pt-2">Tetap Terhubung dan Terinspirasi dengan Kami</p>
                </div>

                <div className="text-center md:text-left">
                    <h2 className="mb-2 font-semibold text-gray-700">Lokasi</h2>
                    <p className="text-gray-500">Capital World Tower</p>
                    <p className="text-gray-500">Mega Kuningan, Jakarta Selatan</p>
                </div>

                <div className="text-center md:text-left">
                    <h2 className="mb-2 font-semibold text-gray-700">Kontak kami</h2>
                    <p className="text-gray-500">+62 7865 8976 87</p>
                    <p className="text-gray-500">mbg@m.co.id</p>
                </div>

                <div className="text-center md:text-left md:pb-auto">
                    <h2 className="mb-2 font-semibold text-gray-700">Keamanan</h2>
                    <p className="text-orange-500 hover:underline">Privacy & Policy</p>
                </div>

                <div className="text-start">
                    <h3 className="mb-2 font-semibold text-gray-700">Social</h3>
                    <div className="flex gap-[20px]">
                        <FacebookIcon />
                        <InstagramIcon />
                        <TwitterIcon />
                        <YoutubeIcon />
                        <WordpressIcon />
                    </div>
                </div>
            </div>
        </footer>
    );
}
