import { SearchBarProps } from "../../../../../../../../../model/catalog/add/category";

export const SearchBar = ({ placeholder, onChange }: SearchBarProps) => {
    return (
      <div className="relative">
        <div className="absolute inset-y-0 left-3 flex items-center">
          <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
        <input
          type="text"
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          className="w-full rounded-lg border border-gray-200 py-2.5 pl-10 pr-4 text-sm placeholder:text-gray-400 focus:border-blue-500 focus:outline-none"
        />
      </div>
    );
  };