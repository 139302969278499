// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  MyStoreListProps,
  MyStoreDetail,
  UpdatedDetailTokoPayload,
} from "../../../../../model/merchant";
import axiosInstance from "../../../../../api/axiosInstance";

interface MyStoreDetailState {
  loading: boolean;
  error: string | null;
  myStore: MyStoreListProps | null;
}

const initialState: MyStoreDetailState = {
  loading: false,
  error: null,
  myStore: null,
};

export const updatedProfilePict = createAsyncThunk<
  MyStoreDetail,
  { formData: FormData }
>("Merchant/updatedProfilePict", async ({ formData }) => {
  try {
    const response = await axiosInstance.put(
      `/supplier/update/toko`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Response dari updatedProfilePict:", response.data);
    return response.data.data;
  } catch (error: any) {
    console.error("Error updatedProfilePict:", error);
    throw new Error(
      error.response?.data?.message || "Gagal mengupdate foto profile"
    );
  }
});

export const updatedDetailToko = createAsyncThunk<
  MyStoreDetail,
  UpdatedDetailTokoPayload
>(
  "DetailToko/updatedDetailToko",
  async ({ waktu_operasional, store_description }) => {
    try {
      const payload = {
        waktu_operasional,
        store_description,
      };
      const response = await axiosInstance.put(
        `/supplier/update/toko`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response dari updatedDetailToko:", response.data);
      return response.data.data;
    } catch (error: any) {
      console.error("Error updatedDetailToko:", error);
      throw new Error(error.response?.data?.message || "Gagal mengupdate data");
    }
  }
);

const myStorePictUpdateSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatedProfilePict.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updatedProfilePict.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.myStore = action.payload;
        }
      )
      .addCase(
        updatedProfilePict.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(updatedDetailToko.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updatedDetailToko.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.myStore = action.payload;
        }
      )
      .addCase(
        updatedDetailToko.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default myStorePictUpdateSlice.reducer;
