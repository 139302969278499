import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const deviceIdKey = "device_id";
let deviceId = cookies.get(deviceIdKey);

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "X-Header-DeviceID": deviceId,
  },
});
const getToken = () => cookies.get("token");

const getRefreshToken = () => cookies.get("refreshToken");
const setAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `${token}`;
};
const domain =
  process.env.NODE_ENV === "production"
    ? ".superdigitalapps.my.id"
    : "localhost";

export const setToken = (token: string, refreshToken?: string) => {
  cookies.set("token", token, {
    path: "/",
    domain,
    secure: process.env.NODE_ENV === "production",
    sameSite: "lax",
  });
  if (refreshToken) {
    cookies.set("refreshToken", refreshToken, {
      path: "/",
      domain,
      secure: process.env.NODE_ENV === "production",
      sameSite: "lax",
    });
  }
  setAuthorizationHeader(token);
};

export const removeToken = () => {
  cookies.remove("token", { path: "/" });
  cookies.remove("refreshToken", { path: "/" });
  delete axiosInstance.defaults.headers.common["Authorization"];
};

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const subscribeTokenRefresh = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = (token: string) => {
  refreshSubscribers.map((callback) => callback(token));
  refreshSubscribers = [];
};

const token = getToken();
if (token) {
  setAuthorizationHeader(token);
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const refreshToken = getRefreshToken();
          if (!refreshToken) throw new Error("Refresh token tidak tersedia");
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/user/token`,
            {},
            {
              headers: {
                refreshToken: `${refreshToken}`,
              },
            }
          );

          const { token: newtoken } = response.data;

          setToken(newtoken);

          onRefreshed(newtoken);

          isRefreshing = false;

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          removeToken();
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((newtoken) => {
          originalRequest.headers["Authorization"] = `${newtoken}`;
          resolve(axiosInstance(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

const refreshTokenAutomatically = () => {
  setInterval(async () => {
    const refreshToken = getRefreshToken();

    if (refreshToken && !isRefreshing) {
      isRefreshing = true;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/token`,
          {},
          {
            headers: {
              refreshtoken: `${refreshToken}`,
            },
          }
        );

        const { token: newtoken } = response.data;

        setToken(newtoken);

        isRefreshing = false;
      } catch (refreshError) {
        console.error("Gagal refresh token otomatis:", refreshError);
        isRefreshing = false;
      }
    }
  }, 240000);
};

refreshTokenAutomatically();

export default axiosInstance;
