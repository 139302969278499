import { TimePickerIcon } from "../../../../../asset/icon/merchant";

interface TimePickerProps {
  startTime: string;
  endTime: string;
  setStartTime: (startTime: string) => void;
  setEndTime: (endTime: string) => void;
  isTwentyFourHours: boolean;
}

const TimePickerForm: React.FC<TimePickerProps> = ({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  isTwentyFourHours,
}) => {
  const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value);
  };
  const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value);
  };

  return (
    <form className="max-w-[16rem] mx-auto flex justify-between items-center gap-9 sm:gap-6 md:gap-8 lg:gap-2">
      <div>
        <div className="relative">
          <div
            className={`${
              isTwentyFourHours ? "cursor-not-allowed" : "pointer-events-none"
            } absolute inset-y-0 end-0 top-0 flex items-center pr-3.5`}
          >
            <TimePickerIcon />
          </div>
          <input
            type="time"
            id="start-time"
            className={`${
              isTwentyFourHours ? "bg-[#EDEDED]" : "bg-gray-50"
            } border leading-none border-gray-300 text-gray-900 text-sm 
                        rounded-lg focus:ring-[#007F73] focus:border-[#007F73] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
                        dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#007F73] dark:focus:border-[#007F73]`}
            min="00:00"
            max="23:59"
            value={startTime}
            onChange={handleStartTimeChange}
            disabled={isTwentyFourHours}
            required
          />
        </div>
      </div>
      <p className="font-inter font-normal text-sm text-[#25282B] capitalize">
        Sampai
      </p>
      <div>
        <div className="relative">
          <div
            className={`${
              isTwentyFourHours ? "cursor-not-allowed" : "pointer-events-none"
            } absolute inset-y-0 end-0 top-0 flex items-center pr-3.5`}
          >
            <TimePickerIcon />
          </div>
          <input
            type="time"
            id="end-time"
            className={`${
              isTwentyFourHours ? "bg-[#EDEDED]" : "bg-gray-50"
            } border leading-none border-gray-300 text-gray-900 
                        text-sm rounded-lg focus:ring-[#007F73] focus:border-[#007F73] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                         dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#007F73] dark:focus:border-[#007F73]`}
            min="00:00"
            max="23:59"
            value={endTime}
            onChange={handleEndTimeChange}
            disabled={isTwentyFourHours}
            required
          />
        </div>
      </div>
    </form>
  );
};

export default TimePickerForm;
