import { useState } from "react";

interface bubleChat {
  text: string;
  maxWords: number;
}

const ReadMore = ({ text, maxWords = 20 }: bubleChat) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const wordsArray = text.split(" ");

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const displayedText = isReadMore
    ? wordsArray.slice(0, maxWords).join(" ")
    : text;

  return (
    <p className="text-sm">
      {displayedText}
      {wordsArray.length > maxWords && (
        <span
          onClick={toggleReadMore}
          className="text-blue-500 cursor-pointer ml-[4px]"
        >
          {isReadMore ? "Selengkapnya..." : "Lebih sedikit..."}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
