import Cookies from "universal-cookie";
import io from "socket.io-client";

const cookies = new Cookies();
const token = cookies.get("token");
const socket = io(`${process.env.REACT_APP_API_URL_Socket}`, {
  transports: ["websocket"],
  auth: {
    token,
  },
});

socket.on("connect", () => {
  console.log("Socket terhubung dengan ID:", socket.id);
});

socket.on("connect_error", (error: any) => {
  console.error("Gagal terhubung:", error);
});

export const WebSocketService = socket;
