import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Udin from "../component/profile/setting/payment/udin";
import Dana from "../component/profile/setting/payment/dana";
import Gopay from "../component/profile/setting/payment/gopay";
import Ovo from "../component/profile/setting/payment/ovo";
import CreditDebit from "../component/profile/setting/payment/creditDebit";
import Mbg from "../component/profile/setting/notification/mbg";
import Email from "../component/profile/setting/notification/email";
import Sms from "../component/profile/setting/notification/sms";
import ChangePassword from "../component/profile/setting/security/changePassword";
import ChangePin from "../component/profile/setting/security/changePin";
import LoginActivity from "../component/profile/setting/security/loginActivity";
import DeleteAccount from "../component/profile/setting/security/deleteAccount";
import NotificationPage from "../pages/notification";
import ShippingList from "../component/shippingManagement/shippingList";
import Chat from "../component/chat";
import HelpCenterMain from "../pages/helpCenter/main";
import HelpCenterDashboard from "./helpCenterDashboard";
import Language from "../pages/profile/language";
import PrivacyNotice from "../component/profile/termsPrivacy/privacyNotice";
import SystemSetting from "../component/profile/termsPrivacy/systemSetting";
import SearchHistory from "../component/profile/termsPrivacy/searchHistory";
import Contoh from "../pages/contoh";
import DetailProduct from "../component/catalog/detail";
import UpdateProduct from "../component/catalog/edit";
import PagePin from "../pages/pin";
import PageOtp from "../pages/otp";
import PagePinReset from "../pages/pinReset";
import HelpCenterSub from "../pages/helpCenter/sub";
import SubHelpCenterSection from "../component/helpCenterDashboard/section-sub";
import SpecificHelpCenterSection from "../component/helpCenterDashboard/specific-section";
import DetailAnswerSection from "../component/helpCenterDashboard/detail-answer-section";
import EditMyTokoPage from "../component/merchant/update";
import RoleProtection from "../middleware/roleProtection";
const Income = React.lazy(() => import("../pages/income"));
const ShippingManagement = React.lazy(
  () => import("../pages/shippingManagement")
);
const CreditServices = React.lazy(() => import("../pages/creaditServices"));
const Review = React.lazy(() => import("../pages/review"));
const DataAnalysis = React.lazy(() => import("../pages/dataAnalisis"));
const CatalogAdd = React.lazy(() => import("../component/catalog/add"));
const Catalog = React.lazy(() => import("../component/catalog/list"));
const PromotionProductAdd = React.lazy(
  () => import("../component/promotionProduct/add")
);
const ProductPromotionList = React.lazy(
  () => import("../component/promotionProduct/list")
);
const MainDashboard = React.lazy(() => import("./mainDashboard"));
const Home = React.lazy(() => import("../pages/home"));
const Merchant = React.lazy(() => import("../pages/merchant"));
const ProfileDashboard = React.lazy(() => import("./profileDashboard"));
// const Header = React.lazy(() => import("./"))
const Settings = React.lazy(() => import("../pages/profile/setting"));
const AddressList = React.lazy(
  () => import("../component/profile/setting/addressList")
);
const Biodata = React.lazy(
  () => import("../component/profile/setting/biodata")
);
const Payment = React.lazy(
  () => import("../component/profile/setting/payment")
);
const NotificationSetting = React.lazy(
  () => import("../component/profile/setting/notification")
);
const Security = React.lazy(
  () => import("../component/profile/setting/security")
);
const DomisiliAddress = React.lazy(
  () => import("../component/profile/setting/addressList/domisili")
);
const DestinationAddress = React.lazy(
  () => import("../component/profile/setting/addressList/destination")
);
const TermsPrivacy = React.lazy(() => import("../pages/profile/termsPrivacy"));
const DeleteAccountSection = React.lazy(
  () => import("../component/DeleteAccountSection")
);
const DeleteAccountReason = React.lazy(
  () => import("../component/DeleteAccountReason")
);
const DellAccountRequest = React.lazy(
  () => import("../component/DeleteAccountRequest")
);
const LoadingComponent = React.lazy(
  () => import("super_apps_frontend/LoadingComponent")
);
const NotFoundPage = React.lazy(() => import("../pages/404"));
const ServerErrorPage = React.lazy(() => import("../pages/500"));

export const AppRoutes = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        {/* <RoleProtection> */}
          <MainDashboard />
        {/* </RoleProtection> */}
      </Suspense>
    ),
    children: [
      { index: true, element: <Home /> },
      { path: "/merchant", element: <Merchant /> },
      {
        path: "/catalog",
        children: [
          { index: true, element: <Catalog /> },
          { path: "add", element: <CatalogAdd /> },
          { path: "detail/:namaProduct", element: <DetailProduct /> },
          { path: "edit/:product", element: <UpdateProduct /> },
        ],
      },
      {
        path: "/product-promotion",
        children: [
          { index: true, element: <ProductPromotionList /> },
          { path: "add", element: <PromotionProductAdd /> },
        ],
      },
      { path: "/income", element: <Income /> },
      {
        path: "/shipping-management",
        element: <ShippingManagement />,
        children: [{ index: true, element: <ShippingList /> }],
      },
      { path: "/credit-services", element: <CreditServices /> },
      { path: "/review", element: <Review /> },
      { path: "/data-analysis", element: <DataAnalysis /> },
      { path: "/notification", element: <NotificationPage /> },
      { path: "/chat", element: <Chat /> },
      { path: "/merchant/update", element: <EditMyTokoPage /> },
    ],
  },
  //profile path
  {
    path: "/profile",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <ProfileDashboard />
      </Suspense>
    ),
    children: [
      {
        path: "setting",
        element: <Settings />,
        children: [
          { index: true, element: <Biodata /> },
          {
            path: "address-list",
            element: <AddressList />,
            children: [
              { index: true, element: <DomisiliAddress /> },
              { path: "destination", element: <DestinationAddress /> },
            ],
          },
          {
            path: "payment",
            element: <Payment />,
            children: [
              { index: true, element: <Udin /> },
              { path: "dana", element: <Dana /> },
              { path: "gopay", element: <Gopay /> },
              { path: "ovo", element: <Ovo /> },
              { path: "credit-debit", element: <CreditDebit /> },
            ],
          },
          {
            path: "notification",
            element: <NotificationSetting />,
            children: [
              { index: true, element: <Mbg /> },
              { path: "email", element: <Email /> },
              { path: "sms", element: <Sms /> },
            ],
          },
          {
            path: "security",
            element: <Security />,
            children: [
              { index: true, element: <ChangePassword /> },
              { path: "change-pin", element: <ChangePin /> },
              { path: "login-activity", element: <LoginActivity /> },
              { path: "delete-account", element: <DeleteAccount /> },
            ],
          },
        ],
      },
      {
        path: "language",
        element: <Language />,
      },
      {
        path: "terms-and-privacy",
        element: <TermsPrivacy />,
        children: [
          { index: true, element: <SystemSetting /> },
          { path: "search-history", element: <SearchHistory /> },
          { path: "privacy-notice", element: <PrivacyNotice /> },
        ],
      },
    ],
  },
  {
    path: "/help-center",
    element: <HelpCenterDashboard />,
    children: [{ index: true, element: <HelpCenterMain /> }],
  },
  {
    path: "/help-center-supplier/sub/:id",
    element: <HelpCenterDashboard />,
    children: [{ index: true, element: <SubHelpCenterSection /> }],
  },
  {
    path: "/help-center-supplier/specific-details/:id",
    element: <HelpCenterDashboard />,
    children: [{ index: true, element: <SpecificHelpCenterSection /> }],
  },
  {
    path: "/help-center-supplier/detail-answer/:id",
    element: <HelpCenterDashboard />,
    children: [{ index: true, element: <DetailAnswerSection /> }],
  },
  //pin
  {
    path: "pin",
    element: <PagePin />,
  },
  //pin
  {
    path: "pin/reset",
    element: <PagePinReset />,
  },
  //otp
  {
    path: "otp",
    element: <PageOtp />,
  },
  //err page
  {
    path: "404",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <NotFoundPage />
      </Suspense>
    ),
  },
  {
    path: "500",
    element: <ServerErrorPage />,
  },
  // Wildcard route for 404
  {
    path: "*",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <NotFoundPage />
      </Suspense>
    ),
  },
  {
    path: "/contoh",
    element: <Contoh />,
  },
  // delete account
  {
    path: "/delete-account",
    element: <DeleteAccountSection />,
  },
  {
    path: "/delete-account/reason",
    element: <DeleteAccountReason />,
  },
  {
    path: "/delete-account/request-submitted",
    element: <DellAccountRequest />,
  },
]);

export default AppRoutes;
