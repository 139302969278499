import React from 'react'
import { TriangleRedIcon } from '../../../../../asset/icon/catalog/detail'

export default function RejectAlert() {
  return (
    <div className="bg-[#FEF6F6] flex justify-between items-center h-[50px] py-3 px-3">
    <div className="space-x-3 flex">
        <TriangleRedIcon />
        <p className="font-inter font-normal text-sm text-justify text-[#BF0000]">
            Setelah peninjauan menyeluruh, kami mendapati bahwa produk Anda tidak memenuhi persyaratan superapp
            dan karena itu tidak dapat diterima. Kami sarankan untuk mengunggah produk lain yang legal dan
            sesuai dengan regulasi.
        </p>
    </div>
</div>
  )
}
