import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface modalChatState {
  isShowModal: boolean;
}

const initialState: modalChatState = {
  isShowModal: false,
};

const modalChatSlice = createSlice({
  name: "modal Chat",
  initialState,
  reducers: {
    setShowModalChat: (state, action: PayloadAction<boolean>) => {
        state.isShowModal = action.payload;
      },
  },
});

export const { setShowModalChat } = modalChatSlice.actions;
export default modalChatSlice.reducer;
