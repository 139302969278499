import React from 'react';
import { RouterProvider } from 'react-router-dom'; // Import RouterProvider
import AppRoutes from './route/index'; // Import AppRoutes
import { Provider } from 'react-redux';
import store from './redux/store';

const App: React.FC = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <RouterProvider router={AppRoutes} />
      </Provider>
    </div>
  );
};

export default App;
