import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../api/axiosInstance";
import { orderDetailProps } from "../../../../../model/orderList";

interface OrderState {
  orderList: orderDetailProps[];
  loading: boolean;
  error: string | null;
  updatePesanan: orderDetailProps[];
}

const initialState: OrderState = {
  updatePesanan: [],
  orderList: [],
  loading: false,
  error: null,
};

export const getOrderlist = createAsyncThunk<orderDetailProps[], void>(
  "Pesanan/getOrderlist",
  async () => {
    try {
      const response = await axiosInstance.get(`/order/seller/list`);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching pesanan list:", error);
      throw error;
    }
  }
);

export const updatePesanan = createAsyncThunk<orderDetailProps, FormData>(
  "Pesanan/updatePesanan",
  async (formData) => {
    try {
      const response = await axiosInstance.put("/order/confirm", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.data;
    } catch (error: any) {
      console.error("Error saat mengubah data:", error);
      throw new Error(error.response?.data?.message || "Gagal mengubah data");
    }
  }
);

const PesananSlice = createSlice({
  name: "Pesanan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderlist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getOrderlist.fulfilled,
        (state, action: PayloadAction<orderDetailProps[]>) => {
          state.loading = false;
          state.orderList = action.payload;
        }
      )
      .addCase(getOrderlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch pesanan list";
        console.error("Error fetching pesanan list:", action.error.message);
      })
      .addCase(updatePesanan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updatePesanan.fulfilled,
        (state, action: PayloadAction<orderDetailProps>) => {
          state.loading = false;
          console.log("State addPesanan sebelum push:", state);
          state.updatePesanan.push(action.payload);
        }
      )
      .addCase(updatePesanan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to create kendaraan";
        console.error("Error creating kendaraan:", action.error.message);
      });
  },
});

export const PesananService = {
  action: PesananSlice.actions,
  getOrderlist,
};
const PesananReducer = PesananSlice.reducer;
export default PesananReducer;
