import React from "react";
import { PasswordChangeIcon } from "../../../../../asset/icon/profile/setting/security";

export default function ChangePin() {
  return (
    <div className="pb-[157px] border-l-[1px] border-[#DDE5E9] flex flex-col items-center">
      <h5 className="mt-[24px] text-[#25282B] font-bold text-[24px]">
        Atur PIN
      </h5>
      <p className="mt-[48px] mb-[24px] text-[#1A1C1E] text-[18px] font-medium">
        Ganti PIN MBG
      </p>
      <PasswordChangeIcon />
      <div
        // onClick={() => navigate('/user/pin/verify', { state: { from: 'profile-setting' } })}
        className="text-center py-[8px] w-[70%] bg-custom-primaryvendor text-[#fff] rounded-full mt-[48px]"
      >
        Ganti PIN Baru
      </div>
    </div>
  );
}
