import React, { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  RootState,
} from "../../../redux/store";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getAllSpecHelpCenterList } from "../../../redux/slices/pages/help-center/specific";
import { AshArrowRightIcon } from "../../../asset/icon/helpCenterDashboard";
import LoadingSpinner from "../../loadSpinner";
import { JawabanTypes } from "../../../model/help-center/specific";

function DetailAnswerSection() {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(""); // State untuk pencarian
  const dispatch = useAppDispatch();
  const specificHelpCenterState = useAppSelector(
    (state: RootState) =>
      state.SpecificHelpCenterList.specificHelpCenterList?.dataList
  );

  const location = useLocation();
  const { question, id, answer } = location.state || {}; // Ambil nama dari state

  useEffect(() => {
    const fetchData = async () => {
      if (typeof id === "string") {
        setLoading(true);
        await dispatch(getAllSpecHelpCenterList(id));
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id]);

  const specificItem = specificHelpCenterState?.find(
    (item) => item.id_sub_informasi_bantuan === id
  );

  // Filter data berdasarkan searchTerm
  const filteredAnswers = answer.filter((data: JawabanTypes) => {
    const matchesDescription = data.description
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesSubDescription = data.sub_description.some((subDesc) => {
      const matchesTextSubDesc = subDesc.text_sub_description
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());

      const matchesSpesifikDesc = subDesc.spesifik_description?.some((desc) =>
        desc.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return matchesTextSubDesc || matchesSpesifikDesc;
    });

    return matchesDescription || matchesSubDescription;
  });

  return (
    <div>
      <div className="text-[#FFFFFF] font-bold text-[32px]">
        <div>{question}</div>
        <div className="text-lg font-normal">Temukan Kendalamu</div>
      </div>
      <div className="pt-8">
        <section
          className={`w-[1128px] bg-[#FFFFFF] rounded-[16px] mx-auto py-[24px] px-[16px] shadow-md`}
        >
          {/* Input Pencarian */}
          <div className="h-[50px] mb-14 mt-6 px-[26px] py-[13px] flex-1 mr-[20px] rounded-[100px] border border-zinc-200 items-center gap-2.5 inline-flex">
            <div className="relative w-full">
              <input
                placeholder="Cari di detail jawaban"
                className="text-neutral-400 outline-none w-[754px] text-base font-normal font-Inter leading-normal p-2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center">
              <LoadingSpinner width="36px" height="36px" />
            </div>
          ) : (
            <div>
              <div className="flex justify-between items-center">
                <div className="py-4 text-[#4A4D4F] text-base font-normal">
                  {filteredAnswers.map((data: JawabanTypes, index: number) => (
                    <div key={index} className="flex items-start gap-2">
                      <div>{index + 1}.</div>
                      <div>
                        <div>{data.description}</div>
                        {/* Cek apakah sub_description ada dan valid */}
                        {data.sub_description &&
                          data.sub_description.some(
                            (subDesc) =>
                              subDesc.text_sub_description ||
                              (subDesc.spesifik_description &&
                                subDesc.spesifik_description.some(
                                  (desc) => desc
                                ))
                          ) && (
                            <ul className="list-disc pl-5">
                              {data.sub_description.map(
                                (subDesc) =>
                                  (subDesc.text_sub_description ||
                                    (subDesc.spesifik_description &&
                                      subDesc.spesifik_description.some(
                                        (desc) => desc
                                      ))) && (
                                    <li key={subDesc._id}>
                                      {/* Tampilkan text_sub_description jika ada */}
                                      {subDesc.text_sub_description && (
                                        <div>
                                          {subDesc.text_sub_description}
                                        </div>
                                      )}
                                      {/* Cek apakah spesifik_description ada dan tidak kosong */}
                                      {subDesc.spesifik_description &&
                                        subDesc.spesifik_description.some(
                                          (desc) => desc
                                        ) && (
                                          <ul className="list-disc pl-5">
                                            {subDesc.spesifik_description.map(
                                              (spesifikDesc, spesifikIndex) =>
                                                spesifikDesc && (
                                                  <li key={spesifikIndex}>
                                                    {spesifikDesc}
                                                  </li>
                                                )
                                            )}
                                          </ul>
                                        )}
                                    </li>
                                  )
                              )}
                            </ul>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Tampilkan pesan jika hasil pencarian kosong */}
              {filteredAnswers.length === 0 && (
                <div className="text-center text-neutral-400 py-4">
                  Tidak ada hasil yang ditemukan untuk "{searchTerm}".
                </div>
              )}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default DetailAnswerSection;
