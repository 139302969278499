import React from 'react'
import { GopayIcon } from '../../../../../asset/icon/profile/payment'

export default function Gopay() {
  return (
    <div className="text-center flex flex-col justify-center py-[20px] px-[30px] gap-[10px] border-l-[1px]">
    <h3 className="text-[#25282B] text-[24px] font-bold">Gopay</h3>
    <div className="mx-auto flex justify-center items-center my-[74px] rounded-[16px]">
        <GopayIcon/>
    </div>
    <h6 className="text-[#222831] text-[24px] font-bold">
    Aktifkan Gopay anda
    </h6>
    <p className='mx-auto text-[14px] text-[#6D7588] font-normal max-w-[256px] text-center'>Aktifkan gopay anda agar memudahkan proses pembayaran anda</p>
    <button
        disabled
        className="bg-[#E1E1E1] rounded-full text-[#BCBCBC] py-[12px] mt-[74px]">
        Aktifkan
    </button>
</div>
  )
}
