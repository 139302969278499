export const FoodIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_812_121920)">
      <rect x="4" y="2" width="40" height="40" rx="20" fill="#FAE7EE" />
      <path
        d="M31.9982 20.5C31.9624 21.9253 31.5842 23.3247 30.8917 24.5941C30.1993 25.8634 29.2103 26.9699 27.9986 27.8312V30.25C27.9986 30.4489 27.9144 30.6397 27.7643 30.7803C27.6143 30.921 27.4109 31 27.1987 31C26.9866 31 26.7831 30.921 26.6331 30.7803C26.4831 30.6397 26.3988 30.4489 26.3988 30.25V14.5C26.3916 14.2182 26.4738 13.9407 26.6348 13.7027C26.7958 13.4647 27.0286 13.2768 27.3035 13.1627C27.6193 13.0423 27.9658 13.0107 28.3005 13.0718C28.6351 13.1329 28.9436 13.2842 29.1881 13.507C31.0434 15.4157 32.0489 17.918 31.9982 20.5ZM23.1992 13C22.987 13 22.7836 13.079 22.6336 13.2197C22.4835 13.3603 22.3993 13.5511 22.3993 13.75V18.25C22.3972 18.7137 22.2424 19.1655 21.956 19.5435C21.6697 19.9216 21.2657 20.2074 20.7995 20.362V13.75C20.7995 13.5511 20.7152 13.3603 20.5652 13.2197C20.4152 13.079 20.2117 13 19.9995 13C19.7874 13 19.5839 13.079 19.4339 13.2197C19.2839 13.3603 19.1996 13.5511 19.1996 13.75V20.362C18.7334 20.2074 18.3294 19.9216 18.043 19.5435C17.7567 19.1655 17.6019 18.7137 17.5998 18.25V13.75C17.5998 13.5511 17.5155 13.3603 17.3655 13.2197C17.2155 13.079 17.0121 13 16.7999 13C16.5878 13 16.3843 13.079 16.2343 13.2197C16.0843 13.3603 16 13.5511 16 13.75V18.25C16.0012 19.1142 16.32 19.9515 16.9029 20.6209C17.4857 21.2904 18.2969 21.7509 19.1996 21.925V30.25C19.1996 30.4489 19.2839 30.6397 19.4339 30.7803C19.5839 30.921 19.7874 31 19.9995 31C20.2117 31 20.4152 30.921 20.5652 30.7803C20.7152 30.6397 20.7995 30.4489 20.7995 30.25V21.925C21.7022 21.7509 22.5134 21.2904 23.0962 20.6209C23.679 19.9515 23.9979 19.1142 23.9991 18.25V13.75C23.9991 13.5511 23.9148 13.3603 23.7648 13.2197C23.6148 13.079 23.4113 13 23.1992 13Z"
        fill="url(#paint0_linear_812_121920)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_812_121920"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_812_121920"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_812_121920"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_812_121920"
        x1="19.3939"
        y1="9.58"
        x2="35.9128"
        y2="13.7037"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#066961" />
        <stop offset="1" stopColor="#15ECCD" />
      </linearGradient>
    </defs>
  </svg>
);
export const EmptyNotificationIcon = () => (
  <svg
    width="600"
    height="400"
    viewBox="0 0 600 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4280_127237)">
      <path
        d="M413.091 304.107C407.077 338.29 325.127 391.59 249.687 347.278C214.601 326.668 222.364 307.799 199.604 275.263C186.037 255.872 164.107 238.495 162.46 214.508C161.033 193.802 169.492 173.87 182.896 162.741C213.041 137.714 264.473 127.732 299.255 186.288C334.037 244.844 428.592 215.946 413.091 304.107Z"
        fill="url(#paint0_linear_4280_127237)"
      />
      <path
        d="M474.144 135.32C467.458 147.25 452.817 148.89 429.361 146.622C411.72 144.914 395.633 143.592 377.991 133.974C365.643 127.246 355.868 118.144 348.734 109.303C341.004 99.7256 330.213 88.7956 335.502 77.9369C342.772 63.0202 384.811 50.4569 425.632 71.0016C470.474 93.5769 480.683 123.659 474.144 135.32Z"
        fill="url(#paint1_linear_4280_127237)"
      />
      <path
        d="M529.497 188.444C513.417 196.421 492.075 186.165 492.075 186.165C492.075 186.165 496.819 162.981 512.907 155.016C528.987 147.04 550.319 157.284 550.319 157.284C550.319 157.284 545.577 180.468 529.497 188.444Z"
        fill="url(#paint2_linear_4280_127237)"
      />
      <path
        d="M71.7793 245.647C88.9233 255.921 113.519 246.423 113.519 246.423C113.519 246.423 110.303 220.27 93.1486 210.007C76.0053 199.732 51.4199 209.219 51.4199 209.219C51.4199 209.219 54.6359 235.372 71.7793 245.647Z"
        fill="url(#paint3_linear_4280_127237)"
      />
      <path
        d="M497.858 126.213C493.009 126.213 489.078 130.144 489.078 134.993C489.078 139.842 493.009 143.773 497.858 143.773C502.707 143.773 506.638 139.842 506.638 134.993C506.638 130.144 502.707 126.213 497.858 126.213Z"
        fill="#CC0A58"
      />
      <path
        d="M383.601 275.792C375.99 275.792 369.821 281.961 369.821 289.572C369.821 297.182 375.99 303.352 383.601 303.352C391.211 303.352 397.381 297.182 397.381 289.572C397.381 281.961 391.211 275.792 383.601 275.792Z"
        fill="#CC0A58"
      />
      <path
        d="M216.765 132.194C212.773 132.194 209.538 135.43 209.538 139.421C209.538 143.413 212.773 146.648 216.765 146.648C220.756 146.648 223.992 143.413 223.992 139.421C223.992 135.43 220.756 132.194 216.765 132.194Z"
        fill="#CC0A58"
      />
      <path
        d="M135.409 231.885C130.863 231.885 127.178 228.199 127.178 223.653C127.178 219.107 130.863 215.422 135.409 215.422C139.955 215.422 143.641 219.107 143.641 223.653C143.641 228.199 139.955 231.885 135.409 231.885Z"
        fill="#CC0A58"
      />
      <path
        d="M431.841 171.683C429.719 171.683 427.999 169.963 427.999 167.841C427.999 165.72 429.719 164 431.841 164C433.962 164 435.682 165.72 435.682 167.841C435.682 169.963 433.962 171.683 431.841 171.683Z"
        fill="#CC0A58"
      />
      <path
        d="M424.937 329.877C422.21 329.877 419.999 327.666 419.999 324.939C419.999 322.211 422.21 320 424.937 320C427.665 320 429.876 322.211 429.876 324.939C429.876 327.666 427.665 329.877 424.937 329.877Z"
        fill="#E1E4E5"
      />
      <path
        d="M99.5293 296.72C95.5896 296.72 92.3959 293.526 92.3959 289.586C92.3959 285.647 95.5896 282.453 99.5293 282.453C103.469 282.453 106.663 285.647 106.663 289.586C106.663 293.526 103.469 296.72 99.5293 296.72Z"
        fill="#E1E4E5"
      />
      <path
        d="M503.232 281.593C500.233 281.593 497.802 279.162 497.802 276.164C497.802 273.165 500.233 270.734 503.232 270.734C506.23 270.734 508.661 273.165 508.661 276.164C508.661 279.162 506.23 281.593 503.232 281.593Z"
        fill="#E1E4E5"
      />
      <path
        d="M445.617 114.361C442.283 114.361 439.581 111.659 439.581 108.325C439.581 104.991 442.283 102.289 445.617 102.289C448.95 102.289 451.653 104.991 451.653 108.325C451.653 111.659 448.95 114.361 445.617 114.361Z"
        fill="#E1E4E5"
      />
      <path
        d="M121.111 175.422C121.886 178.314 120.17 181.286 117.278 182.061C114.386 182.836 111.414 181.12 110.639 178.228C109.864 175.336 111.581 172.364 114.472 171.589C117.364 170.814 120.336 172.53 121.111 175.422Z"
        fill="#E1E4E5"
      />
      <path
        d="M255.95 77.9448C251.968 77.9448 248.739 74.7164 248.739 70.7341C248.739 66.7518 251.968 63.5234 255.95 63.5234C259.932 63.5234 263.161 66.7518 263.161 70.7341C263.161 74.7164 259.932 77.9448 255.95 77.9448Z"
        fill="#E1E4E5"
      />
      <path
        d="M483.471 250.531C480.408 250.531 477.925 248.544 477.925 246.094C477.925 243.643 480.408 241.656 483.471 241.656C486.535 241.656 489.018 243.643 489.018 246.094C489.018 248.544 486.535 250.531 483.471 250.531Z"
        fill="#E1E4E5"
      />
      <path
        d="M389.747 58.5179C391.359 64.5358 387.788 70.7214 381.77 72.3339C375.752 73.9463 369.567 70.3751 367.954 64.3572C366.342 58.3394 369.913 52.1538 375.931 50.5413C381.949 48.9288 388.134 52.5001 389.747 58.5179Z"
        fill="#E1E4E5"
      />
      <path
        d="M140.567 139.461H140.645C141.113 146.09 146.044 146.192 146.044 146.192C146.044 146.192 140.607 146.298 140.607 153.959C140.607 146.298 135.17 146.192 135.17 146.192C135.17 146.192 140.099 146.09 140.567 139.461ZM132.307 313H132.383C132.832 319.61 137.565 319.712 137.565 319.712C137.565 319.712 132.345 319.818 132.345 327.456C132.345 319.818 127.125 319.712 127.125 319.712C127.125 319.712 131.857 319.61 132.307 313Z"
        fill="#E1E4E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M370.406 84.8643L317.637 61.7943C314.173 60.2795 310.445 59.4619 306.665 59.3883C302.885 59.3146 299.127 59.9863 295.607 61.365L199.501 99.0503C184.695 104.857 177.408 121.563 183.219 136.384L256.848 324.152C262.659 338.972 279.359 346.273 294.166 340.467L428.168 287.922C442.975 282.116 450.261 265.409 444.45 250.588L385.694 100.75C382.904 93.6382 377.405 87.9248 370.406 84.8643Z"
        fill="white"
        stroke="#E1E4E5"
        strokeWidth="3.498"
      />
      <path
        d="M382.735 218.488L281.037 258.365C277.293 259.833 275.448 264.059 276.916 267.803C278.384 271.548 282.61 273.393 286.354 271.924L388.051 232.047C391.796 230.579 393.641 226.353 392.173 222.609C390.705 218.864 386.479 217.019 382.735 218.488Z"
        fill="#E1E4E5"
      />
      <path
        d="M372.178 191.566L270.481 231.443C266.737 232.911 264.891 237.137 266.36 240.881C267.828 244.626 272.054 246.471 275.798 245.002L377.495 205.125C381.24 203.657 383.085 199.431 381.617 195.687C380.149 191.943 375.923 190.097 372.178 191.566Z"
        fill="#E1E4E5"
      />
      <path
        d="M361.596 164.581L259.899 204.459C256.154 205.927 254.309 210.153 255.777 213.897C257.245 217.641 261.471 219.486 265.216 218.018L366.913 178.141C370.657 176.673 372.503 172.447 371.034 168.703C369.566 164.958 365.34 163.113 361.596 164.581Z"
        fill="#E1E4E5"
      />
      <path
        d="M351.014 137.597L249.317 177.474C245.572 178.943 243.727 183.168 245.195 186.913C246.663 190.657 250.889 192.502 254.634 191.034L356.331 151.157C360.075 149.688 361.921 145.463 360.452 141.718C358.984 137.974 354.758 136.129 351.014 137.597Z"
        fill="#E1E4E5"
      />
      <path
        d="M340.433 110.605L238.736 150.482C234.991 151.95 233.146 156.176 234.614 159.92C236.082 163.665 240.308 165.51 244.053 164.042L345.75 124.164C349.494 122.696 351.34 118.47 349.871 114.726C348.403 110.982 344.177 109.137 340.433 110.605Z"
        fill="#E1E4E5"
      />
      <path
        d="M393.368 245.605L291.671 285.482C287.927 286.95 286.081 291.176 287.55 294.92C289.018 298.665 293.244 300.51 296.988 299.042L398.685 259.164C402.43 257.696 404.275 253.47 402.807 249.726C401.339 245.982 397.113 244.137 393.368 245.605Z"
        fill="#E1E4E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.234 257.03L138.478 260.638C142.786 273.154 156.421 279.804 168.937 275.496C181.453 271.188 188.103 257.553 183.795 245.037L182.55 241.43"
        fill="#31446C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.039 112.669L148.912 103.593C145.462 93.5874 134.556 88.2648 124.539 91.7154C114.533 95.1661 109.211 106.071 112.651 116.078L115.777 125.154"
        fill="#CC0A58"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.2535 181.288C86.7315 156.549 99.8855 129.583 124.624 121.061L142.545 114.892C167.285 106.371 194.251 119.524 202.772 144.263L212.287 171.909C214.093 177.151 217.906 181.461 222.89 183.891L231.411 188.042C233.879 189.245 236.086 190.922 237.905 192.978C239.724 195.034 241.12 197.429 242.013 200.025C245.569 210.345 240.079 221.595 229.76 225.151L123.787 261.642C113.467 265.197 102.217 259.708 98.6622 249.388C96.8556 244.144 97.2052 238.397 99.6342 233.411L103.785 224.889C104.991 222.421 105.697 219.739 105.864 216.998C106.03 214.256 105.655 211.509 104.757 208.913L95.2535 181.288Z"
        fill="#CC0A58"
      />
      <path
        d="M498.636 339.261L483.333 311.336"
        stroke="#CC0A58"
        strokeWidth="19.032"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M432.337 312.15C466.446 323.22 503.071 304.542 514.14 270.433C525.21 236.324 506.533 199.699 472.423 188.629C438.314 177.56 401.689 196.237 390.62 230.346C379.55 264.456 398.228 301.08 432.337 312.15Z"
        fill="white"
        stroke="#CC0A58"
        strokeWidth="17.457"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4280_127237"
        x1="287.183"
        y1="488.116"
        x2="293.184"
        y2="-97.5415"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4280_127237"
        x1="429.108"
        y1="212.644"
        x2="369.521"
        y2="-55.1124"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4280_127237"
        x1="467.749"
        y1="213.325"
        x2="600.375"
        y2="108.418"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4280_127237"
        x1="138.12"
        y1="278.721"
        x2="0.177265"
        y2="150.554"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <clipPath id="clip0_4280_127237">
        <rect width="600" height="400" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
