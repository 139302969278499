import React from 'react'
import { RootState, useAppSelector } from '../../../redux/store';
import NotificationCard from './card';


export default function NotificationList() {
    const { notificationData } = useAppSelector((state: RootState) => state.Notification)
    return (
        <main className='w-full'>
            <div className='mb-10'>
                <p className='font-inter font-semibold text-[32px] text-[#222831] capitalize'>
                    notifikasi
                </p>
                <p className='font-inter font-normal text-sm text-[#222831]'>
                    Atur notifikasi yang ingin kamu terima disini
                </p>
            </div>
            <section className='space-y-4 mb-10'>
                {notificationData?.notifikasi && notificationData?.notifikasi.length > 0 && (
                    notificationData?.notifikasi.map((item, index) => (
                        <NotificationCard key={index} data={item} />
                    ))
                )}
            </section>
        </main>
    )
}
