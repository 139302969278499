import React, { useState } from 'react'

export default function Contoh() {
    const [step, setStep] = useState(1)
    return (
        <section className='w-full h-screen'>
            <section className='w-full [h-80vh] flex'>
                <div className={`bg-primaryColor h-[70vh] duration-500 w-${step === 1 ? '1/2' : '1/3'}`}></div>
                <div className={`bg-black h-[70vh] duration-500 w-${step === 1 ? '1/2' : '2/3'}`}></div>
            </section>
            <button onClick={()=>setStep(2)}>next</button>
        </section>
    )
}
