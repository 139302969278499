import React from 'react'
import { DebitCardIcon } from '../../../../../asset/icon/profile/payment'

export default function CreditDebit() {
  return (
    <div className="grid col-span-1  border-l-[1px] border-[#DDE5E9] px-[24px] py-[16px] text-center justify-center">
      <h4 className="font-bold text-[#222831] text-[24px] mb-[20px]">Kartu Kredit / Debit</h4>
      <div className="mx-auto">
        <DebitCardIcon/>
        </div>
      <h4 className="font-bold text-[#222831] text-[24px] mt-[10px]">Belum ada kartu yang terdaftar</h4>
      <span className="text-[#6D7588] text-[14px] font-normal my-[20px]">
        Tambahkan Kartu Kredit / Debit Untuk Pembayaranmu
      </span>
      <button className="bg-[#E1E1E1] rounded-full px-[20px] py-[12px] text-[#BCBCBC] text-[16px] font-medium">
        Pilih Metode
      </button>
    </div>
  )
}
