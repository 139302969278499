import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FoodGiziInformationState {
  showTypeFood: boolean;
  showProcessedFoods: boolean;
  showRawMaterials: boolean;
}

const initialState: FoodGiziInformationState = {
    showTypeFood: false,
    showProcessedFoods: false,
    showRawMaterials: false,
};

const modalFoodTypeInformation = createSlice({
  name: "modalFoodGiziInformationStatus",
  initialState,
  reducers: {
    setModalTypeFood: (state, action: PayloadAction<boolean>) => {
        state.showTypeFood = action.payload;
      },
    setModalProcessedFoods: (state, action: PayloadAction<boolean>) => {
        state.showProcessedFoods = action.payload;
      },
    setModalRawMaterials: (state, action: PayloadAction<boolean>) => {
        state.showRawMaterials = action.payload;
      },
  },
});

export const { setModalTypeFood, setModalProcessedFoods, setModalRawMaterials } = modalFoodTypeInformation.actions;
export default modalFoodTypeInformation.reducer;
