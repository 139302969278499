import React from 'react'
import { EmptyChatIcon } from '../../../../asset/icon/chat'

export default function Empty() {
    return (
        <div className='min-h-[65vh] w-full flex flex-col items-center justify-center'>
            <EmptyChatIcon />
            <p className='font-normal text-[14] text-[#A6A7A8] mt-[10px]'>Tidak ada pesan</p>
        </div>
    )
}
