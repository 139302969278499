import React from "react";

const MessageSkeleton = () => {
  return (
    <div className="w-full h-[80vh] flex flex-col justify-between border-l-[1px] pl-[8px]">
      {/* Header Skeleton */}
      <section className="flex-1 overflow-hidden">
        <div className="p-[16px] flex justify-between items-center w-full relative">
          {/* Profil Distributor Skeleton */}
          <section className="flex gap-[24px] items-center">
            <div className="w-[56px] h-[56px] rounded-full bg-gray-200 animate-pulse"></div>{" "}
            {/* Gambar Profil */}
            <div className="flex flex-col gap-[8px]">
              <div className="w-[120px] h-[16px] bg-gray-200 animate-pulse rounded"></div>{" "}
              {/* Nama Distributor */}
              <div className="w-[80px] h-[14px] bg-gray-200 animate-pulse rounded"></div>{" "}
              {/* Status Online */}
            </div>
          </section>
          {/* Dot Icon Skeleton */}
          <div className="w-[24px] h-[24px] bg-gray-200 animate-pulse rounded"></div>{" "}
          {/* Icon Dot */}
        </div>

        {/* Chat Area Skeleton */}
        <div className="h-[60vh] overflow-y-scroll no-scrollbar w-full">
          {/* Pesan Sistem Skeleton */}
          <div className="mt-[24px] mx-auto max-w-[360px]">
            <div className="w-full h-[60px] bg-gray-200 animate-pulse rounded"></div>{" "}
            {/* Pesan Sistem */}
          </div>

          {/* Item Product Skeleton */}
          <div className="mt-[32px] ml-auto w-[328px]">
            <div className="w-full h-[100px] bg-gray-200 animate-pulse rounded"></div>{" "}
            {/* Item Product */}
          </div>

          {/* Chat Bubbles Skeleton */}
          <div className="w-full flex flex-col gap-[16px] mt-[8px]">
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className={`flex ${
                  index % 2 === 0 ? "justify-end" : "justify-start"
                } mb-4`}
              >
                <div className="w-[200px] h-[60px] bg-gray-200 animate-pulse rounded-lg"></div>{" "}
                {/* Chat Bubble */}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Chat Input Skeleton */}
      <section className="w-full">
        <div className="flex gap-[32px] items-center w-full mt-[16px]">
          <div className="bg-[#F3F6FB] rounded-full py-[16px] px-[24px] flex justify-between items-center flex-1">
            <div className="flex gap-[16px] items-center flex-1">
              <div className="w-[24px] h-[24px] bg-gray-200 animate-pulse rounded"></div>{" "}
              {/* Emote Icon */}
              <div className="w-full h-[20px] bg-gray-200 animate-pulse rounded"></div>{" "}
              {/* Input Placeholder */}
            </div>
            <div className="px-[8px]">
              <div className="w-[24px] h-[24px] bg-gray-200 animate-pulse rounded"></div>{" "}
              {/* Add File Icon */}
            </div>
          </div>
          <div className="w-[24px] h-[24px] bg-gray-200 animate-pulse rounded"></div>{" "}
          {/* Send Message Icon */}
        </div>
      </section>
    </div>
  );
};

export default MessageSkeleton;
