// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../api/axiosInstance";
import { ageListProps } from "../../../../../model/catalog/ageList";

interface deleteProductState {
    modalConsfirmDellAccout: boolean
    singleIdProduct: string
}

const initialState: deleteProductState = {
    modalConsfirmDellAccout: false,
    singleIdProduct: ''
};

const deleteProduct = createSlice({
    name: "data for deleteProduct",
    initialState,
    reducers: {
        setModalConfirmDellProduct: (state, action: PayloadAction<boolean>) => {
            state.modalConsfirmDellAccout = action.payload;
        },
        setSingleIdProduct: (state, action: PayloadAction<string>) => {
            state.singleIdProduct = action.payload;
        },
    },
});

export const {
    setModalConfirmDellProduct,
    setSingleIdProduct
} = deleteProduct.actions
export default deleteProduct.reducer;
