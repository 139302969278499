export const ArrowLeftIcon = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.5 19.5625L8.5 12.5625L15.5 5.5625"
            stroke="#545F71"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export const InfoIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.0005 1C9.85715 1 11.6378 1.73755 12.9506 3.0504C14.2634 4.36325 15.001 6.14385 15.001 8.0005C15.001 9.85715 14.2634 11.6378 12.9506 12.9506C11.6378 14.2634 9.85715 15.001 8.0005 15.001C6.14385 15.001 4.36325 14.2634 3.0504 12.9506C1.73755 11.6378 1 9.85715 1 8.0005C1 6.14385 1.73755 4.36325 3.0504 3.0504C4.36325 1.73755 6.14385 1 8.0005 1ZM9.0505 5.298C9.5705 5.298 9.9925 4.937 9.9925 4.402C9.9925 3.867 9.5695 3.506 9.0505 3.506C8.5305 3.506 8.1105 3.867 8.1105 4.402C8.1105 4.937 8.5305 5.298 9.0505 5.298ZM9.2335 10.925C9.2335 10.818 9.2705 10.54 9.2495 10.382L8.4275 11.328C8.2575 11.507 8.0445 11.631 7.9445 11.598C7.89913 11.5813 7.86121 11.549 7.83756 11.5068C7.81391 11.4646 7.80609 11.4154 7.8155 11.368L9.1855 7.04C9.2975 6.491 8.9895 5.99 8.3365 5.926C7.6475 5.926 6.6335 6.625 6.0165 7.512C6.0165 7.618 5.9965 7.882 6.0175 8.04L6.8385 7.093C7.0085 6.916 7.2065 6.791 7.3065 6.825C7.35577 6.84268 7.39614 6.87898 7.41895 6.92609C7.44176 6.97321 7.44519 7.02739 7.4285 7.077L6.0705 11.384C5.9135 11.888 6.2105 12.382 6.9305 12.494C7.9905 12.494 8.6165 11.812 9.2345 10.925H9.2335Z"
            fill="#CC0A58"
        />
    </svg>
);