import React from "react";
import { detailShippmentProps } from "../../../../../model/shipping";
import { FormattedPrice } from "../../../../../lib";
import { IconUser } from "../../../../../asset/icon/home";
import {
  getColorHandler,
  getColorTextHandler,
} from "../../../../../utils/order";

interface shippmentCardProps {
  data: detailShippmentProps;
}
export default function Card({ data }: shippmentCardProps) {
  const fullAddress = `${data.buyerId.address.address_description}, 
    ${data.buyerId.address.village}, 
    ${data.buyerId.address.district}, 
    ${data.buyerId.address.regency}, 
    ${data.buyerId.address.province}`;
  const bgColor = getColorHandler(data.status_distributor);
  const textColor = getColorTextHandler(data.status_distributor);
  return (
    <section
      // onClick={onOpenDetail}
      className="border-[.5px] border-[#DDE5E9] shadow-md rounded-lg p-4 mb-[16px] cursor-pointer relative"
    >
      <div className="flex gap-[32px]">
        <div>
          <div className="w-[152px] h-[152px]">
            <img
              src={data.distributorId.imageProfile}
              alt={data.distributorId.nama_distributor}
              className="w-full h-full object-cover rounded-md"
            />
          </div>
        </div>
        <div className="w-full relative">
          <div className="flex w-full justify-between">
            <p className="text-[#007F73] font-inter text-[14px]">{`ID : ${
              data.distributorId._id || "-"
            }`}</p>
            <div
              className={`flex items-center h-[25px] rounded-xl`}
              style={{ backgroundColor: bgColor || "-" }}
            >
              <p
                className={`font-inter font-semibold text-[14px] rounded-full px-[16px] py-[4px]`}
                style={{ color: textColor || "-" }}
              >
                {data.status_distributor || "-"}
              </p>
            </div>
          </div>
          <div className="flex gap-[16px] space-x-8">
            <div className="space-y-2 w-[206px]">
              <p className="text-[#4A4D4F] font-inter font-semibold max-w-[288px]">
                {data.produk_pengiriman[0].productId.name_product || "-"}
              </p>
              <div className="flex justify-between items-center">
                <p className="text-[#4A4D4F] font-inter font-semibold">{`${
                  FormattedPrice(data.tarif_pengiriman) || "-"
                }`}</p>
                <p className="text-[#4A4D4F] font-inter font-semibold">{`X ${
                  data.produk_pengiriman[0].quantity || 0
                }`}</p>
              </div>
              <div className="flex w-full text-[#828282] font-inter justify-end">
                <div className="w-[50px] p-1">{IconUser()}</div>
                <p className="ml-2 w-full">{data.buyerId.nama || "-"}</p>
                <p className="text-right w-full">{`+${
                  data.produk_pengiriman.length || 0
                } Lainnya`}</p>
              </div>
            </div>
            <div className="ml">
              <p className="text-[14px] font-inter font-semibold pb-[16px]">
                Lokasi Pengiriman
              </p>
              <p className="text-[#4A4D4F] text-[16px] max-w-[288px]">
                {fullAddress || "-"}
              </p>
            </div>
          </div>
          <div className="absolute bottom-4 right-4 flex space-x-4">
            {data.status_distributor === "sedang dalam perjalanan" && (
              <button
                // onClick={handleToRiwayat}
                className="text-[#007F73] rounded-full border border-[#007F73] h-[40px] py-[8px] px-[24px]"
              >
                <p className="font-inter font-medium text-xs text-[#007F73] capitalize">
                  lacak
                </p>
              </button>
            )}
            {data.status_distributor === "telah diterima konsumen" && (
              <button
                // onClick={handleToRiwayat}
                className="text-[#007F73] rounded-full border border-[#007F73] h-[40px] py-[8px] px-[24px]"
              >
                <p className="font-inter font-medium text-xs text-[#007F73] capitalize">
                  riwayat pengiriman
                </p>
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
