import React, { useEffect, useState } from "react";
import MessageSection from "./messageSection";
import ListChat from "./listChat";
import { useAppDispatch } from "../../redux/store";
import { GetListChat } from "../../redux/slices/pages/chat/list";

export default function ChatSection() {
  const dispatch = useAppDispatch();
  const [chatActiveId, setChatActiveId] = useState("");

  useEffect(() => {
    dispatch(GetListChat());
  }, []);
  return (
    <div className="flex justify-start h-[80vh] overflow-hidden ">
      <ListChat
        setChatActiveState={setChatActiveId}
        idChatActive={chatActiveId}
      />
      <MessageSection idChatActive={chatActiveId} />
    </div>
  );
}
