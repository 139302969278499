import React from 'react';

interface InputSecificationProductProps {
  longValue: number | undefined;
  setLongValue: (longValue: number) => void;
  weightValue: number | undefined;
  setWeightValue: (weightValue: number) => void;
  heightValue: number | undefined;
  setHeightValue: (heightValue: number) => void;
  widthValue: number | undefined;
  setWidthValue: (heightValue: number) => void;
}

export default function InputSecificationProduct({
  longValue,
  setLongValue,
  weightValue,
  setWeightValue,
  heightValue,
  setHeightValue,
  widthValue,
  setWidthValue
}: InputSecificationProductProps) {
  return (
    <section>
      <p className="text-[18px] font-inter font-bold mb-5">Spesifikasi Produk</p>
      <div className="grid grid-cols-4 gap-4">
        {/* Panjang Produk */}
        <div>
          <p className="text-[16px] mb-[8px] font-inter font-semibold">Panjang Produk (cm)</p>
          <input
            type="tel"
            name="panjangProduct"
            autoComplete="off"
            value={longValue}
            placeholder="Panjang Product"
            className="border-slate-300 py-[16px] border outline-none h-[50px] w-full rounded-lg pl-[20px]"
            onChange={(e) => setLongValue(Number(e.target.value))}
          />
        </div>

        {/* Lebar Produk */}
        <div>
          <p className="text-[16px] mb-[8px] font-inter font-semibold">Lebar Produk (cm)</p>
          <input
            type="tel"
            name="lebarProduct"
            autoComplete="off"
            value={widthValue}
            placeholder="Lebar Product"
            className="border-slate-300 py-[16px] border outline-none h-[50px] w-full rounded-lg pl-[20px]"
            onChange={(e) => setWidthValue(Number(e.target.value))}
            />
        </div>

        {/* Tinggi Produk */}
        <div>
          <p className="text-[16px] mb-[8px] font-inter font-semibold">Tinggi Produk (cm)</p>
          <input
            type="tel"
            name="tinggiProduct"
            autoComplete="off"
            value={heightValue}
            placeholder="Tinggi Product"
            className="border-slate-300 py-[16px] border outline-none h-[50px] w-full rounded-lg pl-[20px]"
            onChange={(e) => setHeightValue(Number(e.target.value))}
            />
        </div>

        {/* Berat Produk */}
        <div>
          <p className="text-[16px] mb-[8px] font-inter font-semibold">Berat Produk (gram)</p>
          <input
            type="tel"
            name="beratProduct"
            autoComplete="off"
            placeholder="Berat Product"
            value={weightValue}
            className="border-slate-300 py-[16px] border outline-none h-[50px] w-full rounded-lg pl-[20px]"
            onChange={(e) => setWeightValue(Number(e.target.value))}
          />
        </div>
      </div>
    </section>
  );
}
