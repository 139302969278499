import React from 'react'
import { EmptyHistoryIcon } from '../../../../asset/icon/profile/termAndPrivacy'
import { useNavigate } from 'react-router-dom'

export default function SearchHistory() {
    const navigate = useNavigate()
    return (
        <section className="w-full px-4 py-6 border-l border-zinc-200 flex-col justify-start items-center ">
            <h3 className="text-center text-[#25282B] text-[24px] font-bold mb-[48px]">Riwayat Pencarian</h3>
            <p className="font-inter font-normal text-sm text-center">
                Di halaman ini, Anda dapat melihat daftar lengkap pencarian yang telah Anda lakukan.{' '}
            </p>
            <div className="flex flex-col justify-center items-center mt-12">
                <EmptyHistoryIcon />
                <div className="space-y-4">
                    <p className="font-inter font-medium text-base text-custom-third text-center">
                        Belum ada riwayat pencarian
                    </p>
                    <p className="font-inter font-normal text-sm text-custom-second text-center">
                        belum ada riwayat pencarian nih. ayo mulai pesan makanan bergizi sekarang
                    </p>
                </div>
                <button
                    onClick={() => navigate('/')}
                    type="button" className="mt-6 w-[320px] h-[40px] font-inter font-medium text-xs text-white text-center flex justify-center items-center bg-primaryColor rounded-[32px] border border-primaryColor">
                    Kembali ke Beranda
                </button>
            </div>
        </section>
    )
}
