import React from "react";
import { orderDetailProps } from "../../../../../../../model/orderList";
import { FormattedDateDaysHoursOnly } from "../../../../../../../lib";
import ProductListSkeleton from "../../skeleton";

interface CardOrderListState {
  data: orderDetailProps;
  loading: boolean;
}

function Card({ data, loading }: CardOrderListState) {
  if (loading) {
    return <ProductListSkeleton />;
  }
  return (
    <div className="mt-[7px]">
      <div className="w-[16vw] bg-[#FFFFFF] rounded-lg py-2 px-4">
        <div className="flex justify-between items-center">
          <p className="text-[#4A4D4F] text-xs ">
            {data.createdAt
              ? FormattedDateDaysHoursOnly(data.createdAt)
              : "Loading..."}
          </p>
          <div className="bg-secondaryColor rounded-xl p-2 text-xs font-semibold">
            {/* {data.status === "Dikemas" ? (

            ) : ()} */}
          </div>
        </div>
        <div className="flex gap-[7px] mt-[7px] items-center">
          <div>
            <img
              src={data.product[0].product.image_product[0]}
              alt="image product"
              className="w-[46px] object-cover h-[46px] rounded-md"
            />
          </div>
          <div className="text-[#25282B]">
            <p className="text-sm font-semibold">
              {data?.product.flatMap((i) => i.product.name_product)[0] || "-"}
            </p>
            <p className="text-xs font-normal">
              Total Harga:{" "}
              <span className="text-sm font-bold">
                {`Rp. ${
                  data?.product
                    .flatMap((i) => i.product.price)[0]
                    ?.toLocaleString("id-ID") || "-"
                }`}
              </span>
            </p>
          </div>
        </div>
        <button className="border-primaryColor w-full rounded-[20px] border-[1px] text-sm text-primaryColor py-2 px-5 flex justify-center mt-[15px] items-center">
          Pilih Produk
        </button>
      </div>
    </div>
  );
}

export default Card;
