import React from 'react'

interface NameProductInputProps {
  value?: string
  setvalue: (value: string) => void // Perbaiki tipe setvalue jadi void karena tidak mengembalikan nilai apapun
}

export default function NameProductInput({ value, setvalue }: NameProductInputProps) {
  // Fungsi untuk menangani perubahan pada input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setvalue(e.target.value) // Memanggil setvalue yang diterima dari props
  }

  return (
    <div>
      <p className="text-[16px] mb-[8px] font-inter font-semibold">Judul Produk</p>
      <input
        type="text"
        autoComplete="off"
        placeholder="Nama Product"
        name="nameProduct"
        value={value || ''} // Menggunakan value yang diterima dari parent, jika undefined, gunakan string kosong
        onChange={handleInputChange} // Menangani perubahan input
        maxLength={80}
        className="border-slate-300 border py-[16px] border-slate-300 outline-none w-full h-[50px] rounded-lg pl-[20px]"
      />
    </div>
  )
}
