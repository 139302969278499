// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../../api/axiosInstance";
import { ThirdCategoryProps } from "../../../../../../model/catalog/add/category/thirdCategory";

interface ThirdCategoryState {
  loading: boolean; 
  error: string | null;
  thirdCategory: ThirdCategoryProps | null; 
}

const initialState: ThirdCategoryState = {
  loading: false,
  error: null,
  thirdCategory: null,
};

// AsyncThunk untuk mengambil detail produsen
export const getThirdCategory = createAsyncThunk(
  "catalog/getthirdcategory",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/category/detail-sub-category/${id}`);
      return response.data; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const catalogThirdCategory = createSlice({
  name: "third category list catalog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getThirdCategory.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(getThirdCategory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.thirdCategory = action.payload; // Simpan data yang berhasil di-fetch
      })
      .addCase(getThirdCategory.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default catalogThirdCategory.reducer;
