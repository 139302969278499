import React from 'react'
import { detailProductState } from '../../../../model/catalog/productList'
import ImageDummy from '../../../../asset/images/product/image.png'
interface ProductComponentProps{
    productsData: detailProductState
}
export default function ProductComponent({productsData}:ProductComponentProps) {
    const [fallbackImages, setFallbackImages] = React.useState<Record<number, boolean>>({});

    const handleImageError = (index: number) => {
        setFallbackImages((prev) => ({ ...prev, [index]: true }));
    };
  return (
    <div>
    {/* Bagian Gambar Produk */}
    <section className="flex items-center space-x-4 mb-4">
        {productsData?.image_product?.map((image, index) => (
                  <img
                      key={index}
                      src={fallbackImages[index] ? ImageDummy : image}
                      alt={`product-image-${index}`}
                      onError={() => handleImageError(index)}
                      className="w-[104px] h-[104px] rounded-md object-cover"
                  />
              ))}
    </section>
    {/* Bagian Detail Produk */}
    <section>
        <p className="font-inter font-medium text-base text-[#4A4D4F] capitalize mb-4">
            {productsData?.name_product || '-'}
        </p>
        <div className="grid grid-cols-2 gap-4">
            <div>
                <p className="font-inter font-normal text-sm text-[#969899]">Harga</p>
                <p className="font-inter font-normal text-sm text-[#969899]">Kategori</p>
            </div>
            <div className="flex flex-col items-end">
                <p className="font-inter font-normal text-sm text-[#969899]">
                    {productsData?.total_price}
                </p>
                <p className="font-inter font-normal text-sm text-[#969899]">
                    {`${productsData?.id_main_category?.name || '-'}, 
        ${productsData?.id_sub_category?.name || '-'}, 
        ${productsData?.categoryId?.name || '-'}`}
                </p>
            </div>
        </div>
    </section>
</div>
  )
}
