import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../api/axiosInstance';
import { useLocation, useNavigate } from 'react-router-dom';

export default function InputSection() {
    const navigate = useNavigate()
    const location = useLocation();
    
    const [pin, setPin] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 6) {
            setPin(value);
          
        }
    };
    useEffect(()=>{
        if (pin.length === 6) {
            const body = {
                userId: location.state.id,
                pin,
                token_reset:location.state.token
            }
            axiosInstance.post(`/user/reset-pin`, body)
                .then((res) => {
                        navigate('/profile/setting')
                   
                }).catch((err) => {
                    console.log(err);
                })
        }
    },[pin])
    useEffect(() => {
        if (!location.state || (location.state as any).source !== 'reset-pin') {
          navigate('/profile/setting');
        }
      }, [location.state, navigate]);
    return (
        <div className="flex flex-col items-center justify-center h-screen w-[507px]">
            <div className="bg-white shadow-lg rounded-[12px] p-[56px] w-full text-center">
                <h2 className="text-[32px] text-[#222831] font-bold mb-4">Buat PIN Baru MBG</h2>
                <p className="text-sm text-gray-600 mb-6">Masukan 6 Angka PIN Anda</p>
                <input
                    type="password"
                    maxLength={6}
                    value={pin}
                    onChange={handleChange}
                    className="text-center text-2xl w-32 p-2 border-none rounded-lg tracking-widest focus:outline-none"
                    placeholder="••••••"
                />
                <hr className='w-full'></hr>

            </div>
        </div>
    )
}
