import React from 'react'
import LoadingSpinner from '../../../../../loadSpinner'
import { confirmDeleteDeviceProp } from '../../../../../../model/modals/profile/setting/deleteAccount/interface'

export default function DeleteDevices({ showModal, CloseModals, deviceId }: confirmDeleteDeviceProp) {
    const isLoading = false
  return (
    <div className={ showModal ? 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' : 'hidden'}>
            <div className="flex flex-col justify-center items-center bg-white text-center w-[568px] p-[50px] rounded-[12px] shadow-lg">
                <h6 className="fonnt-bold text-[18px] text-[#25282B] capitalize">Yakin menghapus perangkat ini?</h6>
                <p className="w-[318px] text-[14px] text-[#818284] font-normal mt-[24px]">
                    Perangkat yang terhubung akan keluar dan kamu harus memasukan kata sandi kembali jika ingin login
                </p>
                <div className="flex gap-[24px] mt-[32px]">
                    <button onClick={() => CloseModals(false)}
                        className="rounded-full w-[160px] text-custom-primaryvendor bg-[#fff] border-[1px] border-custom-primaryvendor">
                        Batalkan
                    </button>
                    <button
                        // onClick={handleDeleteDevices}
                        className="rounded-full w-[320px] text-[#fff] bg-custom-primaryvendor py-[8px] font-medium text-[18px]">
                        {isLoading ? <LoadingSpinner width="18px" height="18px" /> : 'Hapus'}
                    </button>
                </div>
            </div>
        </div>
  )
}
