import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface modalIsComingState {
  isShowModal: boolean;
}

const initialState: modalIsComingState = {
  isShowModal: false,
};

const modalUpcomingStatusSlice = createSlice({
  name: "modalUpcomingStatus",
  initialState,
  reducers: {
    setIsShowModal: (state, action: PayloadAction<boolean>) => {
        state.isShowModal = action.payload;
      },
  },
});

export const { setIsShowModal } = modalUpcomingStatusSlice.actions;
export default modalUpcomingStatusSlice.reducer;
