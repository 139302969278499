export const defaultDataInput = {
  productName: "",
  productCover: [],
  productPrice: 0,
  pricingDetail: {
    diskon: 0,
    minOrder: 0,
    stok: 0,
  },
  shortDesc: "",
  longDesc: "",
  rangeAge: {
    _id: '',
    ageRange: '',
    protein: [],
    lemak: 0,
    karbohidrat: [],
    serat: [],
    kalsium: 0,
    fosfor: 0,
    besi: 0,
    natrium: 0,
    kalium: 0,
    tembaga: [],
    thiamin: 0,
    riboflavin: 0,
    vitaminC: 0,
    kalori: 0,
    __v: 0,
  },
  category: {
    mainCategory: '',
    secondCategory: '',
    thirdCategory: '',
    categoryId: '',
  },
  sepecification: {
    longProduct: 0,
    widthProduct: 0,
    heightProduct: 0,
    weightProduct: 0,
  }

};