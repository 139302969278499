import { format } from "date-fns";
import { id } from "date-fns/locale";

export const FormattedDate = (dateString: string): string => {
  const date = new Date(dateString);
  return format(date, "EEEE, dd MMMM yyyy, HH:mm:ss 'WIB'", { locale: id });
};
export const FormattedDateDaysHoursOnly = (dateString: string): string => {
  const date = new Date(dateString);
  return format(date, "eeee, HH:mm", { locale: id }); // Format: "Harini, 11:29"
};
export const FormattedPrice = (value: number): string => {
  try {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(value);
  } catch (error) {
    console.error("Error formatting price:", error);
    return value.toString(); // Fallback jika ada error
  }
};
export const FormattedNumber = (value: number): string => {
  if (value >= 1_000_000) {
    // Lebih dari atau sama dengan 1 juta
    return `${(value / 1_000_000).toFixed(2).replace(/\.00$/, "")}jt`;
  } else if (value >= 1_000) {
    // Lebih dari atau sama dengan 1 ribu
    return `${(value / 1_000).toFixed(1).replace(/\.0$/, "")}k`;
  }
  // Jika kurang dari 1 ribu, kembalikan angka asli
  return value.toString();
};
export const convertLinksToFiles = async (links: string[]): Promise<File[]> => {
  // Mengonversi setiap URL ke File menggunakan Promise.all
  return Promise.all(
    links.map(async (url, index) => {
      const response = await fetch(url);
      const blob = await response.blob();
      // Memberikan nama default berdasarkan index
      return new File([blob], `image-${index}.jpg`, { type: blob.type });
    })
  );
};
export const getDeviceType = () => {
  const userAgent = navigator.userAgent;

  if (/Macintosh/i.test(userAgent)) {
    return "Mac";
  } else if (/iPad/i.test(userAgent)) {
    return "iPad";
  } else {
    return "Unknown";
  }
};
export const handleLogOut = () => {};
