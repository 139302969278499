import React from "react";

export default function NotificationListSkeleton() {
  return (
    <main className="w-full">
      <div className="mb-10">
        <div className="h-8 w-48 bg-gray-200 rounded animate-pulse mb-2"></div>{" "}
        {/* Judul */}
        <div className="h-4 w-64 bg-gray-200 rounded animate-pulse"></div>{" "}
        {/* Subjudul */}
      </div>
      <section className="space-y-4 mb-10">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            className="bg-[#F3F6FB] rounded-[16px] py-[8px] px-[24px] w-full flex gap-[24px] items-center"
          >
            <div className="w-[56px] h-[56px] bg-gray-200 rounded-[7px] animate-pulse"></div>{" "}
            {/* Gambar */}
            <div className="flex-1 space-y-2">
              <div className="h-5 w-3/4 bg-gray-200 rounded animate-pulse"></div>{" "}
              {/* Status */}
              <div className="h-4 w-full bg-gray-200 rounded animate-pulse"></div>{" "}
              {/* Pesan */}
              <div className="h-3 w-1/2 bg-gray-200 rounded animate-pulse"></div>{" "}
              {/* Waktu */}
            </div>
          </div>
        ))}
      </section>
    </main>
  );
}
