import React, { useEffect } from "react";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { getAllHelpCenterList } from "../../../../../redux/slices/pages/help-center/main";
import LoadingSpinner from "../../../../loadSpinner";
import { AshArrowRightIcon } from "../../../../../asset/icon/helpCenterDashboard";

function List() {
  const dispatch = useAppDispatch();
  const helpCenterState = useAppSelector(
    (state: RootState) => state.HelpCenterList.helpCenterList?.data
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  // Filter untuk hanya menampilkan data dengan type 'penjual'
  const filteredPenjual = helpCenterState?.filter(
    (item: any) => item.type === "penjual"
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Mulai loading
      await dispatch(getAllHelpCenterList());
      setLoading(false); // Selesai loading
    };

    fetchData();
  }, [dispatch]);

  const list = [
    {
      message: "Bagaimana cara mengubah info toko?",
    },
    {
      message: "Apakah saya bisa membatalkan pesanan dari konsumen?",
    },
    {
      message: "Bagaimana cara memantau dan meningkatkan performa toko?",
    },
  ];

  return (
    <div className="mb-14">
      <div className="text-[#343637] text-base font-bold">
        Kendala yang sering dihadapi
      </div>
      {list.map((item) => (
        <div className="flex justify-between mt-6 items-center">
          <div className="py-4 text-[#4A4D4F] text-base font-normal">
            {item.message}
          </div>
          <AshArrowRightIcon />
        </div>
      ))}
    </div>
  );
}

export default List;
