import React, { useEffect } from "react";
import { CategorySectionProps } from "../../../../../../../../model/catalog/add/category";
import { SearchBar } from "./searchBar";
import CategoryItem from "./categoryItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import { getSecondCategory } from "../../../../../../../../redux/slices/pages/catalog/categoryProduct/secondCategory";
import { setStep } from "../../../../../../../../redux/slices/pages/catalog/categoryProduct";
import { getThirdCategory } from "../../../../../../../../redux/slices/pages/catalog/categoryProduct/thirdCategory";
import { arrMainCategory } from "../../../../../../../../model/catalog/add/category/mainCategory";
import {
  setCategoryId,
  setMainCategory,
  setSecondCategory,
  setThirdCategory,
} from "../../../../../../../../redux/slices/pages/catalog";
import { arrSecondCategory } from "../../../../../../../../model/catalog/add/category/secondCategory";
import { arrThirdCategory } from "../../../../../../../../model/catalog/add/category/thirdCategory";
import LoadingSpinner from "../../../../../../../loadSpinner";

export const CategorySection = ({
  mainCategories,
  secondCategory,
  thirdCategory,
  setCategoryId,
}: CategorySectionProps) => {
  const dispatch = useAppDispatch();
  const stepFindCategory = useAppSelector((state) => state.FindCategory.step);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleGetSecondCategory = async (mainCategory: arrMainCategory) => {
    setIsLoading(true); // Set loading state ke true
    try {
      await dispatch(getSecondCategory(mainCategory._id)); // Ambil data kategori kedua
      dispatch(setMainCategory(mainCategory.name)); // Set nama kategori utama
      dispatch(setStep(2)); // Ubah step menjadi 2
      setCategoryId(mainCategory._id); // Set ID kategori utama
    } finally {
      setIsLoading(false); // Set loading state ke false
    }
  };

  const handleGetThirdCategory = (scdCategory: arrSecondCategory) => {
    dispatch(getThirdCategory(scdCategory._id));
    dispatch(setSecondCategory(scdCategory.name));
    dispatch(setStep(3));
  };

  const handleSubmitThirdCategory = (thirdCategory: arrThirdCategory) => {
    dispatch(setThirdCategory(thirdCategory.name));
    setCategoryId(thirdCategory._id);
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-sm border">
      <SearchBar
        placeholder="Cari Kategori"
        onChange={(value) => console.log(value)}
      />

      {mainCategories && (stepFindCategory === 1 || 2 || 3) && (
        <div className="mt-4 space-y-1 max-h-[70vh] overflow-y-auto">
          {mainCategories.map((category) => (
            <CategoryItem
              key={category._id}
              name={category.name}
              onClick={() => handleGetSecondCategory(category)}
            />
          ))}
        </div>
      )}

      {secondCategory && (stepFindCategory === 2 || 3) && (
        <div className="mt-4 space-y-1 max-h-[70vh] overflow-y-auto">
          {isLoading ? (
            <LoadingSpinner width="36px" height="36px" />
          ) : (
            secondCategory.map((category) => (
              <CategoryItem
                key={category._id}
                name={category.name}
                onClick={() => handleGetThirdCategory(category)}
              />
            ))
          )}
        </div>
      )}

      {thirdCategory && stepFindCategory === 3 && (
        <div className="mt-4 space-y-1 max-h-[70vh] overflow-y-auto">
          {thirdCategory.map((category) => (
            <CategoryItem
              key={category._id}
              name={category.name}
              onClick={() => handleSubmitThirdCategory(category)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
