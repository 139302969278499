import React, { useEffect, useState } from "react";
import Empty from "./empty";
import Message from "./chatView";
import MessageSkeleton from "./skeleton";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { ChatDetail } from "../../../redux/slices/pages/chat/detail";

interface MessageSectionState {
  idChatActive: string;
}
export default function MessageSection({ idChatActive }: MessageSectionState) {
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector(
    (state: RootState) => state.MessageData
  );

  useEffect(() => {
    if (idChatActive !== "") {
      dispatch(ChatDetail({ chatId: idChatActive }));
    }
  }, [idChatActive]);
  return (
    <div className="w-2/3 h-full flex items-center">
      {loading ? (
        <MessageSkeleton />
      ) : idChatActive === "" || !data ? (
        <Empty />
      ) : (
        <Message idChatActive={idChatActive} />
      )}
    </div>
  );
}
