import React from "react";
import { RootState, useAppDispatch } from "../../../redux/store";
import { setShowModalChat } from "../../../redux/slices/modal/chat";
import { useSelector } from "react-redux";
import ChatInput from "./content/messageSection/chatInput";
import ProductList from "./content/productSection/list";

export default function ChatCS() {
  const dispatch = useAppDispatch();
  const showModal = useSelector(
    (state: RootState) => state.ChatModal.isShowModal
  );
  const handleClose = () => {
    dispatch(setShowModalChat(false));
  };
  return (
    <div
      className={
        showModal
          ? "fixed inset-0 bg-black bg-opacity-50 flex items-end justify-end z-50"
          : "hidden"
      }
    >
      <section className="h-[80vh] w-[40%]  overflow-y-auto bg-white rounded-tl-xl">
        <section className="h-[10%] flex justify-between items-center px-4">
          <img
            alt="profile"
            src="https://i.pinimg.com/474x/57/00/c0/5700c04197ee9a4372a35ef16eb78f4e.jpg"
            className="w-[30px] h-[30px] rounded-full object-cover"
          />
          <p onClick={handleClose}>X</p>
        </section>
        <section className="h-[80%] bg-[#e2e2e2] px-4">
          <ProductList />
        </section>
        <section className="h-[10%] p-2">
          <ChatInput />
          {/* ini di ganti aja pake component yang baru biar ukurannya pas */}
        </section>
      </section>
    </div>
  );
}
