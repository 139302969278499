import React from "react";
import { useAppSelector } from "../../../../redux/store";
import ChatInput from "./chatInput";
import ChatBubble from "./chatBubble";
import ItemProduct from "./itemProduct";
import { DotIcon } from "../../../../asset/icon/chat";
import { useChatModals, useScrollToEnd } from "../../../../hooks/Chat";

interface MessageProps {
  idChatActive: string;
}

export default function Message({ idChatActive }: MessageProps) {
  const { data } = useAppSelector((state) => state.MessageData);
  const { dotModals, setDotModals, modalRef } = useChatModals();
  const chatEndRef = useScrollToEnd(data);

  return (
    <div className="w-full h-[80vh] flex flex-col justify-between border-l-[1px] pl-[8px]">
      {/* Header Section */}
      <section className="flex-1 overflow-hidden">
        <div className="p-[16px] flex justify-between items-center w-full relative">
          {/* Profil Distributor */}
          <section className="flex gap-[24px] items-center">
            <img
              alt="profile"
              src={data?.data.lawanBicara.profile_pict}
              className="w-[56px] h-[56px] rounded-full object-cover"
            />
            <div>
              <h6 className="text-[#25282B] font-bold text-[16px] capitalize">
                {data?.data.lawanBicara.nama_distributor}
              </h6>
              <p className="text-[#46BB2F] font-normal text-[14px]">Online</p>
            </div>
          </section>

          {/* Dot Icon dan Modal */}
          <div
            onClick={() => setDotModals(!dotModals)}
            className="cursor-pointer"
          >
            <DotIcon />
          </div>
          {dotModals && (
            <div
              ref={modalRef}
              className="absolute shadow-xl p-[8px] rounded-[8px] right-0 bottom-0 flex flex-col text-start mb-[-140px] bg-[#fff] mr-[20px] z-50"
            >
              <button
                onClick={() => setDotModals(false)}
                className="rounded-[4px] p-[16px] text-[14px] text-[#25282B] hover:bg-[#eee] duration-300 min-w-[300px] text-start"
              >
                Blokir Pengguna
              </button>
              <button
                onClick={() => setDotModals(false)}
                className="rounded-[4px] p-[16px] text-[14px] text-[#25282B] hover:bg-[#eee] duration-300 min-w-[300px] text-start"
              >
                Laporkan Pengguna
              </button>
              <button
                onClick={() => setDotModals(false)}
                className="rounded-[4px] p-[16px] text-[14px] text-[#25282B] hover:bg-[#eee] duration-300 min-w-[300px] text-start"
              >
                Hapus Percakapan
              </button>
            </div>
          )}
        </div>

        {/* Chat Area */}
        <div
          className="h-[60vh] overflow-y-scroll no-scrollbar w-full"
          ref={chatEndRef}
        >
          {/* Pesan Sistem */}
          <p className="mt-[24px] text-[#818284] text-[14px] mx-auto font-normal max-w-[360px] text-center">
            Mohon untuk tidak bertransaksi di luar supperapp dan tidak
            memberikan data pribadi ke konumen seperti nomor hp dll. tetap
            bertransaksi di supper app.
          </p>

          {/* Item Product */}
          <div className="mt-[32px] ml-auto w-[328px]">
            <ItemProduct />
          </div>
          {data && <ChatBubble data={data.data} />}
        </div>
      </section>
      {data && (
        <section className="w-full">
          <ChatInput
            idLawanBicara={data?.data.lawanBicara.userId}
            idChatActive={idChatActive}
          />
        </section>
      )}
    </div>
  );
}
