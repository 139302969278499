import React from 'react'
import { detailProductState } from '../../../../model/catalog/productList'
interface productDetailProps{
    productData: detailProductState
}
export default function ProductDetail({productData}: productDetailProps) {
    const productDetails = [
        { label: 'jumlah stok', value: `${productData?.total_stok || 0} pcs` },
        { label: 'kategori', value: productData?.categoryId?.name || '-' },
        { label: 'minimal order', value: `${productData?.minimalOrder || 0} pcs` },
        { label: 'berat', value: `${productData?.berat || 0} gr` },
        {
            label: 'dimensi ukuran',
            value: `${productData?.panjang || 0} x ${productData?.lebar || 0} x ${productData?.tinggi || 0} cm`,
        },
    ];
  return (
    <section>
            <div className="flex justify-between items-center">
                <p className="font-inter font-semibold text-lg text-[#4A4D4F]">Detail Produk</p>
            </div>
            <div>
                {productDetails.map((item, index) => (
                    <div key={index} className="grid grid-cols-2 gap-4">
                        <div className="flex justify-between">
                            <p className="font-inter font-normal text-sm text-[#696B6D] capitalize text-left">
                                {item.label}
                            </p>
                            <span>:</span>
                        </div>
                        <div className="flex justify-end">
                            <p className="font-inter font-normal text-sm text-[#696B6D] capitalize">
                                {item.value}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
  )
}
