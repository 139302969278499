import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../loadSpinner";
import { InputElementReadOnly, InputTextarea } from "./from/InputElements";
import {
  updatedDetailToko,
  updatedProfilePict,
} from "../../../redux/slices/pages/merchant/update";
import { getMyStoreDetail } from "../../../redux/slices/pages/merchant/detail";
import UploadMediaComponent from "./from/pictureUpload";
import {
  RootState,
  useAppSelector,
  useAppDispatch,
} from "../../../redux/store";
import { useFileUpload } from "../../../hooks/useFileUpload";
// import { ChevronBackIcon } from "../../../assets/icons";
import { nameTimeDays } from "../../../model/merchant";
import { BackIcon } from "../../../asset/icon/merchant";
// import TimePickerForm from "../timePicker";
import TimePickerForm from "./from/TimePickerForm";

const EditMyTokoPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { myStore, loading } = useAppSelector(
    (state: RootState) => state.MyStoreDetail
  );
  const [values, setValues] = useState({
    descStore: "",
    nameStore: "",
    address: "",
    detailAddress: "",
    postalCode: 0,
  });
  const alamatValues = [
    myStore?.data.address?.address_description || "-",
    myStore?.data.address?.regency || "-",
    myStore?.data.address?.province || "-",
  ].join(",");
  // HANDLE UPLOAD IMAGE PROFILE
  const {
    dataFiles,
    dataPreviews,
    imageCount,
    isShowErr,
    isShowing,
    errorImage,
    errorVideos,
    handleFileChange,
    handleRemoveFile,
  } = useFileUpload();

  useEffect(() => {
    dispatch(getMyStoreDetail());
  }, [dispatch]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (myStore) {
      setValues({
        address: alamatValues,
        nameStore: myStore.data?.namaToko || "-",
        detailAddress: myStore.data?.address?.address_description || "-",
        postalCode: myStore.data?.address?.code_pos || 0,
        descStore: myStore.data?.store_description || "-",
      });
    }
  }, [myStore]);

  useEffect(() => {
    if (myStore?.data.waktu_operasional) {
      const newActiveDays = nameTimeDays.reduce((acc, day) => {
        const hariOperasional = myStore?.data.waktu_operasional.find(
          (operasi) => operasi.hari.toLowerCase() === day.toLowerCase()
        );
        acc[day] = hariOperasional?.buka || false;
        return acc;
      }, {} as { [key: string]: boolean });
      const newTimePickerValues = nameTimeDays.reduce((acc, day) => {
        const hariOperasional = myStore?.data.waktu_operasional.find(
          (operasi) => operasi.hari.toLowerCase() === day.toLowerCase()
        );
        acc[day] = {
          startTime: hariOperasional?.jam_operasional.mulai || "",
          endTime: hariOperasional?.jam_operasional.tutup || "",
        };
        return acc;
      }, {} as { [key: string]: { startTime: string; endTime: string } });
      const newCheckboxStates = nameTimeDays.reduce((acc, day) => {
        const hariOperasional = myStore?.data.waktu_operasional.find(
          (operasi) => operasi.hari.toLowerCase() === day.toLowerCase()
        );
        const startTime = hariOperasional?.jam_operasional.mulai || "";
        const endTime = hariOperasional?.jam_operasional.tutup || "";
        // Jika waktu mulai dan tutup sama, berarti beroperasi 24 jam.
        if (startTime === endTime && startTime !== "") {
          acc[day] = true;
        } else {
          acc[day] = false;
        }
        return acc;
      }, {} as { [key: string]: boolean });
      setActiveDays(newActiveDays);
      setTimePickerValues(newTimePickerValues);
      setCheckboxStates(newCheckboxStates);
    }
  }, [myStore]);
  // HANDLE SET TIME PICKER
  const [timePickerValues, setTimePickerValues] = useState<{
    [key: string]: { startTime: string; endTime: string };
  }>(
    nameTimeDays.reduce((acc, day) => {
      acc[day] = { startTime: "", endTime: "" };
      return acc;
    }, {} as { [key: string]: { startTime: string; endTime: string } })
  );
  // HANDLE SET TOGGLE ISOPEN STORE
  const [activeDays, setActiveDays] = useState<{ [key: string]: boolean }>(
    nameTimeDays.reduce((acc, day) => {
      acc[day] = false;
      return acc;
    }, {} as { [key: string]: boolean })
  );
  // HANDLE SET CHECKBOXES
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: string]: boolean;
  }>(
    nameTimeDays.reduce((acc, day) => {
      acc[day] = false;
      return acc;
    }, {} as { [key: string]: boolean })
  );
  // HANDLE SET ISOPEN ALERT
  const [isOpenAlert, setIsOpenAlert] = useState<{ [key: string]: boolean }>(
    nameTimeDays.reduce((acc, day) => {
      acc[day] = false;
      return acc;
    }, {} as { [key: string]: boolean })
  );
  //-------------------------------->
  const handleToggle = (day: string) => {
    const { startTime, endTime } = timePickerValues[day];
    if (startTime !== "" && endTime !== "") {
      setActiveDays((prev) => ({ ...prev, [day]: !prev[day] }));
      setIsOpenAlert((prev) => ({ ...prev, [day]: false }));
    } else {
      setIsOpenAlert((prev) => ({ ...prev, [day]: true }));
    }
  };
  const onHandleTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setValues((prev) => ({ ...prev, descStore: value }));
  };
  const handleCheckboxChange = (day: string) => {
    setCheckboxStates((prev) => {
      // Jika checkbox diaktifkan, set waktu menjadi 24 jam
      const isChecked = !prev[day];
      setTimePickerValues((prevValues) => ({
        ...prevValues,
        [day]: isChecked
          ? {
              startTime: prevValues[day].startTime,
              endTime: prevValues[day].startTime,
            }
          : prevValues[day],
      }));
      return { ...prev, [day]: isChecked };
    });
  };
  const handleTimeChange = (
    day: string,
    startTime: string,
    endTime: string
  ) => {
    setTimePickerValues((prev) => ({
      ...prev,
      [day]: { startTime, endTime },
    }));
  };
  const generateDataValues = () => {
    return nameTimeDays.map((day) => {
      const isBuka = activeDays[day];
      const isTwentyFourHours = checkboxStates[day];
      const jam_operasional = isTwentyFourHours
        ? {
            // jika 24 jam, waktu tutup disamakan dengan waktu buka
            mulai: timePickerValues[day].startTime,
            tutup: timePickerValues[day].startTime,
          }
        : {
            mulai: timePickerValues[day].startTime,
            tutup: timePickerValues[day].endTime,
          };
      return {
        hari: day,
        buka: isBuka,
        jam_operasional: jam_operasional,
      };
    });
  };
  // HANDLE EDIT/UPDATED DATA
  const handleEditPhotoProfile = async () => {
    if (dataFiles.length > 0) {
      const updatedDatas = new FormData();
      dataFiles.forEach((file) => {
        if (file instanceof File) {
          updatedDatas.append("profilePict", file);
        } else {
          console.error("Invalid file type in dataFiles:", file);
        }
      });
      try {
        await dispatch(updatedProfilePict({ formData: updatedDatas }));
        console.log("Profile picture updated successfully.");
      } catch (error) {
        console.error("Error while updating profile picture:", error);
      }
    }
  };
  const handleEditDetailToko = async () => {
    try {
      const dataOperasional = generateDataValues();
      if (!dataOperasional.length) {
        throw new Error("Operational hours data is empty");
      }
      const payload = {
        waktu_operasional: dataOperasional,
        store_description: values.descStore,
      };
      await dispatch(updatedDetailToko(payload));
      console.log("Store details updated successfully.");
    } catch (error: any) {
      console.error("Error while updating store details:", error.message);
    }
  };
  const handleDataUpdate = async () => {
    setIsLoading(true); // Mulai loading
    try {
      const updateImagePromise =
        dataFiles.length > 0 ? handleEditPhotoProfile() : Promise.resolve();
      const updateDetailPromise =
        myStore?.data.store_description ||
        generateDataValues().some((item) => item.buka)
          ? handleEditDetailToko()
          : Promise.resolve();
      await Promise.all([updateImagePromise, updateDetailPromise]);
      navigate("/merchant");
    } catch (error) {
      console.error("Error during update:", error);
    } finally {
      setIsLoading(false); // Selesai loading
    }
  };

  // console.log('testing generateDataValues ---->', generateDataValues());
  // console.log('testing dataDetailToko ---->', dataDetailToko);
  // console.log('timePickerValues ---->', timePickerValues);
  return (
    <main className="w-full border-[.5px] border-[#DDE5E9] p-6 space-y-10 rounded-2xl shadow-custom">
      <div className="flex gap-4 items-center">
        <BackIcon />
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="font-inter font-medium text-base text-[#4A4D4F] capitalize outline-none"
        >
          kembali
        </button>
      </div>
      <div className="grid grid-cols-2 grid-rows-2 gap-10">
        <InputElementReadOnly title={"Alamat"} value={alamatValues} />
        <InputElementReadOnly
          title={"Nama Toko/Gudang"}
          value={myStore?.data.namaToko}
        />
        <InputElementReadOnly
          title={"Detail Alamat"}
          value={myStore?.data.address?.address_description}
        />
        <InputElementReadOnly
          title={"Kode Pos"}
          value={myStore?.data.address?.code_pos}
          type="number"
        />
      </div>
      <UploadMediaComponent
        handleFileChange={handleFileChange}
        handleRemoveFile={handleRemoveFile}
        isShowErr={isShowErr}
        isShowing={isShowing}
        errorImage={errorImage}
        errorVideos={errorVideos}
        dataFiles={dataFiles}
        dataPreviews={dataPreviews}
        imageCount={imageCount}
        MAX_IMAGES={1}
      />
      <InputTextarea
        title={"Deskripsi Toko"}
        value={values.descStore}
        handleChange={onHandleTextarea}
      />
      {/* OPERATIONAL HOURS */}
      <div className="grid grid-cols-2 gap-6">
        {nameTimeDays.map((day, index) => (
          <div
            key={index}
            className="rounded-xl py-[15px] px-5 border border-[#E2E2E2]"
          >
            <div className="w-[170px] flex justify-between items-center">
              <p className="font-inter font-normal text-sm text-[#25282B] capitalize">
                {day}
              </p>
              <div className="px-4 sm:px-6 py-2 sm:py-4 text-center">
                <div
                  onClick={() => handleToggle(day)}
                  className={`${
                    activeDays[day] ? "bg-primaryColor" : "bg-gray-300"
                  } w-[41px] h-[24px] sm:w-10 sm:h-6 rounded-full relative cursor-pointer`}
                >
                  <div
                    className={`bg-white w-[18px] h-[18px] sm:w-5 sm:h-5 rounded-full absolute top-0.5 transform transition-transform duration-500 
                                    ${
                                      activeDays[day]
                                        ? "translate-x-4 bg-primaryColor"
                                        : "translate-x-0 bg-gray-300"
                                    }`}
                  ></div>
                </div>
              </div>
              <p className="font-inter font-normal text-sm text-[#25282B] capitalize">
                {activeDays[day] ? "Buka" : "Tutup"}
              </p>
            </div>
            {isOpenAlert[day] && (
              <p className="font-inter font-normal text-xs text-[red]">
                Silakan atur waktu buka dan tutup yang valid sebelum
                mengaktifkan toggle.
              </p>
            )}
            <div className="flex justify-between items-center mt-5 gap-2">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id={`checkbox-${day}`}
                  checked={checkboxStates[day]}
                  disabled={!timePickerValues[day].startTime}
                  className={`${
                    !timePickerValues[day].startTime && "cursor-not-allowed"
                  } w-5 h-5`}
                  onChange={() => handleCheckboxChange(day)}
                />
                <p className="font-inter font-normal text-sm text-[#25282B] capitalize">
                  24 Jam
                </p>
              </div>
              <TimePickerForm
                startTime={timePickerValues[day].startTime}
                endTime={timePickerValues[day].endTime}
                setStartTime={(startTime) =>
                  handleTimeChange(
                    day,
                    startTime,
                    timePickerValues[day].endTime
                  )
                }
                setEndTime={(endTime) =>
                  handleTimeChange(
                    day,
                    timePickerValues[day].startTime,
                    endTime
                  )
                }
                isTwentyFourHours={checkboxStates[day]}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={handleDataUpdate}
          className="w-[240px] h-[56px] bg-primaryColor rounded-[32px] font-inter font-medium text-lg text-white capitalize flex items-center justify-center"
        >
          {isLoading ? <LoadingSpinner width="18px" height="18px" /> : "Simpan"}
        </button>
      </div>
    </main>
  );
};

export default EditMyTokoPage;
