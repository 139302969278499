import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { SpecificHelpCenterListProps } from "../../../../../model/help-center/specific";
import axiosInstance from "../../../../../api/axiosInstance";

interface specificHelpCenterListState {
  loading: boolean;
  error: string | null;
  specificHelpCenterList: SpecificHelpCenterListProps | null;
}

const initialState: specificHelpCenterListState = {
  loading: false,
  error: null,
  specificHelpCenterList: null,
};

export const getAllSpecHelpCenterList = createAsyncThunk(
  "get all sub help center",
  async (id: string) => {
    try {
      const response = await axiosInstance.get(
        `/sub-category-informasi-bantuan/detail-sub-judul/${id}`
      );
      return response.data;
    } catch (error: any) {
      return error.response?.data || "Terjadi kesalahan";
    }
  }
);

const specHelpCenter = createSlice({
  name: "sub help center list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSpecHelpCenterList.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(
        getAllSpecHelpCenterList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.specificHelpCenterList = action.payload; // Simpan data yang berhasil di-fetch
        }
      )
      .addCase(
        getAllSpecHelpCenterList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload; // Simpan pesan error jika request gagal
        }
      );
  },
});

export default specHelpCenter.reducer;
