import React, { useEffect } from "react";
import LoadingSpinner from "../../../loadSpinner";
import Category from "./category";
import CSChat from "./customerChat";
import List from "./list";

interface TypeProps {
  type: string;
}

function HelpCenter({ type, searchTerm }: TypeProps & { searchTerm: string }) {
  return (
    <>
      <Category typeFilter={type} searchTerm={searchTerm} />
      <CSChat />
      <List />
    </>
  );
}

export default HelpCenter;
