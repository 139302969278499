import React from 'react'
import { useNavigate } from 'react-router-dom'
import { EmptyNotificationIcon } from '../../../asset/icon/notification'

export default function Emptynotification() {
  const navigate = useNavigate()
  return (
    <div className='w-full flex flex-col justify-center items-center mx-auto'>
      <EmptyNotificationIcon/>
      <h4 className='text-[#222831] text-[24px] font-semibold text-center w-[373px] mt-[56px]'>Kamu belum pernah melakukan bertransaksi</h4>
      <p className='text-[#6D7588]text-[14px] font-normal mt-[24px]'>Yuk, mulai belanja dan penuhi berbagai kebutuhanmu!</p>
      <button onClick={()=>navigate('/')} className='bg-primaryColor text-[#fff] py-[12px] px-[20px] rounded-full text-[16px] font-medium w-[414px] mt-[32px]'>Ayo Lakukan Transaksi</button>
    </div>
  )
}
