import { CategoryItemProps } from "../../../../../../../../../model/catalog/add/category";

export const CategoryItem = ({ name, isActive, onClick }: CategoryItemProps) => {
    return (
      <button
        onClick={onClick}
        className={`flex w-full items-center capitalize justify-between rounded-lg px-4 py-2.5 text-sm font-medium transition-all hover:bg-gray-50 ${
          isActive ? 'text-blue-600' : 'text-gray-700'
        }`}
      >
        <span>{name}</span>
        <svg
          className="h-5 w-5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    );
  };
  export default CategoryItem