import React, { useState } from 'react'
import LoadingSpinner from '../../../loadSpinner'
import axiosInstance from '../../../../api/axiosInstance'
import { useNavigate } from 'react-router-dom'
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux/store'
import { setConfirmUpdateProductModal } from '../../../../redux/slices/pages/catalog/updateProduct'
import { useSelector } from 'react-redux'

export default function UpdateProductConfirm() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const payloadData = useAppSelector((state: RootState) => state.DataEditProduct.dataUpdateProduct)
    const showModal = useAppSelector((state: RootState) => state.DataEditProduct.confirmUpdateProduct)
    const idProduct = useSelector((state: RootState) => state.DataEditProduct.idProduct)
    const [loading, setLoading] = useState(false)
    const handleClose = () => {
        dispatch(setConfirmUpdateProductModal(false))
    }
    const handleUpdateProduct = () => {
        const formData = new FormData();

        // Tambahkan data ke FormData
        formData.append("bervarian", "false");
        formData.append("productId", idProduct || '');
        formData.append("name_product", payloadData?.productName || "");
        formData.append("categoryId", payloadData?.category?.categoryId || "");
        formData.append("description", payloadData?.shortDesc || "");
        formData.append("long_description", payloadData?.longDesc || "");
        formData.append("price", payloadData?.productPrice?.toString() || "0");
        formData.append("diskon", payloadData?.pricingDetail?.diskon?.toString() || "0");
        formData.append("minimalOrder", payloadData?.pricingDetail?.minOrder?.toString() || "0");
        formData.append("total_stok", payloadData?.pricingDetail?.stok?.toString() || "0");

        // Tambahkan gambar ke FormData
        if (payloadData?.productCover && payloadData?.productCover?.length > 0) {
            payloadData.productCover.forEach((file, index) => {
                formData.append(`ImageProduct`, file); // Tambahkan setiap file ke FormData
            });
        }

        // Tambahkan spesifikasi produk ke FormData
        formData.append("panjang", payloadData?.sepecification?.longProduct?.toString() || "0");
        formData.append("lebar", payloadData?.sepecification?.widthProduct?.toString() || "0");
        formData.append("tinggi", payloadData?.sepecification?.heightProduct?.toString() || "0");
        formData.append("berat", payloadData?.sepecification?.weightProduct?.toString() || "0");
        setLoading(true)
        axiosInstance.put(`/product/edit`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
                dispatch(setConfirmUpdateProductModal(false))
                navigate('/catalog')
            })
    }
    return (
        <div className={
            showModal ? 'no-scrollbar fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' : 'hidden'
        }>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white w-[472px] px-[50px] font-inter py-[40px] text-primaryColor  mt-[30px] max-h-[90vh] overflow-y-auto p-[40px] rounded-lg shadow-lg">
                    <p className="font-inter font-medium text-lg text-[#6D7588] text-center mb-8">
                        Apakah data yang anda ubah sudah benar?
                    </p>
                    <div className="flex justify-center gap-[24px]">
                        <button
                            onClick={handleClose}
                            className="text-primaryColor border rounded-full border-primaryColor w-[165px] h-[48px] px-[24px] py-[8px] text-[14px]">
                            Batal
                        </button>
                        <button
                            onClick={handleUpdateProduct}
                            className={`border rounded-full border-primaryColor bg-primaryColor w-[165px] h-[48px] px-[24px] py-[8px] text-[14px] text-white`}>
                            {loading ? <LoadingSpinner width="18px" height="18px" /> : 'Ya, Simpan'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
