import React from 'react'
interface longDescProps {
  value: string | undefined
  setValue: (value: string) => void
}
export default function LongDescriptionInput({
  value,
  setValue
}: longDescProps) {
  const handleSetValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };
  return (
    <div>
      <p className="text-[16px] mb-[8px] font-inter font-semibold">Deskripsi Panjang</p>
      <textarea
        name="longDescription"
        value={value}
        onChange={handleSetValue}
        placeholder="Deskripsi detail Product"
        maxLength={3000}
        className="py-[16px] border outline-none  w-full h-[120px] rounded-lg pl-[20px] resize-none"
      />
    </div>
  )
}
