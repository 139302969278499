// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../api/axiosInstance";
import { ageListProps } from "../../../../../model/catalog/ageList";

interface AgeListState {
  loading: boolean; 
  error: string | null;
  ageList: ageListProps | null; 
}

const initialState: AgeListState = {
  loading: false,
  error: null,
  ageList: null,
};

// AsyncThunk untuk mengambil detail produsen
export const getAgeList = createAsyncThunk(
  "catalog/getAgeList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/pangan/kebutuhan_gizi`);
      return response.data; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const catalog = createSlice({
  name: "age list catalog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgeList.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(getAgeList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.ageList = action.payload; // Simpan data yang berhasil di-fetch
      })
      .addCase(getAgeList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default catalog.reducer;
