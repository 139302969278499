// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../../api/axiosInstance";
import { processedFoodDetailProps } from "../../../../model/catalog/add/type/processedFoodList/processedFoodDetail/interface";

interface rawMaterialDetailState {
  loading: boolean;
  error: string | null;
  rawMaterialDetail: processedFoodDetailProps | null;
}

const initialState: rawMaterialDetailState = {
  loading: false,
  error: null,
  rawMaterialDetail: null,
};

// AsyncThunk untuk mengambil detail produsen
export const getRawMaterialDetail = createAsyncThunk(
  "rawMaterialDetail",
  async (typeName: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/pangan?kelompok_pangan=${typeName}&jenis_pangan=bahan Mentah`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const rawMaterialSlice = createSlice({
  name: "food category type rawMaterialSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRawMaterialDetail.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(getRawMaterialDetail.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.rawMaterialDetail = action.payload; // Simpan data yang berhasil di-fetch
      })
      .addCase(getRawMaterialDetail.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default rawMaterialSlice.reducer;
