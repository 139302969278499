import React, { useState, useEffect } from "react";
import Card from "./card";
import { useAppSelector, useAppDispatch } from "../../../../../../redux/store";
import { getOrderlist } from "../../../../../../redux/slices/pages/order/list";
import { MoreIcon } from "../../../../../../asset/icon/chat";

function ProductList() {
  const { orderList, loading } = useAppSelector((state) => state.OrderList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOrderlist());
  }, [dispatch]);

  return (
    <div className="mt-2">
      <div className="flex justify-between items-center">
        <p className="text-[#1E1E1E] text-sm font-normal">
          Lihat Semua Product{" "}
        </p>
        <MoreIcon />
      </div>
      <div className="flex gap-4">
        {orderList.map((order) => (
          <Card loading={loading} data={order} key={order._id} />
        ))}
      </div>
    </div>
  );
}

export default ProductList;
