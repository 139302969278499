import React from 'react'

interface valueState {
    productPrice: number | undefined
    diskon: number | undefined
    minDP: number | undefined
    minQtyOrder: number | undefined
    qtyStok: number | undefined
}
interface pricingInputProps {
    value: valueState | undefined;
    setProductPrice: (productPrice: number) => void;
    setDiskon: (diskon: number) => void
    setMinDP: (minDP: number) => void
    setMinQtyOrder: (minQtyOrder: number) => void
    setQtyStok: (qtyStok: number) => void
}

export default function PricingInput({
    value,
    setProductPrice,
    setDiskon,
    setMinDP,
    setMinQtyOrder,
    setQtyStok
}: pricingInputProps) {
    return (
        <section>
        <p className="text-[18px] font-inter font-bold mb-5">Harga dan Diskon</p>
        <div className="grid grid-cols-3 gap-4">
          {/* Harga Produk */}
          <div>
            <p className="text-[16px] mb-[8px] font-inter font-semibold">Harga Produk</p>
            <input
              type="tel"
              name="priceProduct"
              value={value?.productPrice || ''}
              onChange={(e) => setProductPrice(Number(e.target.value) || 0)}
              placeholder="Harga Produk"
              className="py-[16px] border outline-none h-[50px] w-full rounded-lg pl-[20px]"
            />
          </div>
  
          {/* Harga Diskon */}
          <div>
            <p className="text-[16px] mb-[8px] font-inter font-semibold">Harga Diskon(Opsional)</p>
            <input
              type="tel"
              name="priceDiscount"
              value={value?.diskon || ''}
              onChange={(e) => setDiskon(Number(e.target.value) || 0)}
              placeholder="Diskon"
              className="py-[16px] border outline-none h-[50px] w-full rounded-lg pl-[20px]"
            />
          </div>
  
          {/* Minimal Pembayaran */}
          <div>
            <p className="text-[16px] mb-[8px] font-inter font-semibold">Minimal Pembayaran (%)</p>
            <input
              type="tel"
              name="minBuying"
              value={value?.minDP || ''}
              onChange={(e) => setMinDP(Number(e.target.value) || 0)}
              placeholder="Minimal Pembayaran"
              className="py-[16px] border outline-none h-[50px] w-full rounded-lg pl-[20px]"
            />
          </div>
  
          {/* Minimal Pesanan */}
          <div>
            <p className="text-[16px] mb-[8px] font-inter font-semibold">Minimal Pesanan</p>
            <input
              type="tel"
              name="minOrderan"
              value={value?.minQtyOrder || ''}
              onChange={(e) => setMinQtyOrder(Number(e.target.value) || 0)}
              placeholder="Minimal Order (Qty)"
              className="py-[16px] border outline-none h-[50px] w-full rounded-lg pl-[20px]"
            />
          </div>
  
          {/* Stok Produk */}
          <div>
            <p className="text-[16px] mb-[8px] font-inter font-semibold">Stok Produk</p>
            <input
              type="tel"
              name="productStock"
              value={value?.qtyStok || ''}
              onChange={(e) => setQtyStok(Number(e.target.value) || 0)}
              placeholder="Total Stok"
              className="py-[16px] border outline-none h-[50px] w-full rounded-lg pl-[20px]"
            />
          </div>
        </div>
      </section>
    )
}
