import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const LanguageIcon = ({ lang }: { lang: string }) => {
    const icons: { [key: string]: string } = {
        "id": "/path/to/indonesia-flag-icon.svg",
        "en": "/path/to/english-flag-icon.svg",
        "es": "/path/to/spain-flag-icon.svg",
    };
    return <img src={icons[lang]} alt={`${lang}-flag`} className="w-6 h-6 rounded-full" />;
};

export default function Language() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLang, setSelectedLang] = useState("id");
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLanguageChange = (lang: string) => {
        setSelectedLang(lang);
        // Aksi untuk mengubah bahasa aplikasi, misalnya ganti path atau store
        // navigate(`/language/${lang}`); // Ganti dengan navigasi sesuai kebutuhan
        setIsOpen(false);
    };
    return (
        <div className="relative">
            <button
                onClick={toggleDropdown}
                className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm"
            >
                <LanguageIcon lang={selectedLang} />
                <span className="text-gray-800 font-medium">{selectedLang === "id" ? "Bahasa Indonesia" : selectedLang === "en" ? "English" : "Español"}</span>
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-lg border border-gray-200 w-48 z-10">
                    <ul className="py-2">
                        <li
                            onClick={() => handleLanguageChange("id")}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100 flex items-center gap-2"
                        >
                            <LanguageIcon lang="id" />
                            <span>Bahasa Indonesia</span>
                        </li>
                        <li
                            onClick={() => handleLanguageChange("en")}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100 flex items-center gap-2"
                        >
                            <LanguageIcon lang="en" />
                            <span>English</span>
                        </li>
                        <li
                            onClick={() => handleLanguageChange("es")}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100 flex items-center gap-2"
                        >
                            <LanguageIcon lang="es" />
                            <span>Español</span>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    )
}
