import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultDataInput } from "../data";

export interface spesificationState {
  longProduct: number
  widthProduct: number
  heightProduct: number
  weightProduct: number
}
export interface categoryState{
  mainCategory: string;
  secondCategory: string;
  thirdCategory: string;
  categoryId: string;
}
export interface pricingDetailState {
  diskon: number;
  minOrder: number;
  stok: number
}
export interface dataUpdateProductProps {
  productCover: File[]
  productName: string;
  productPrice: number;
  pricingDetail: pricingDetailState
  shortDesc: string;
  longDesc: string;
  category: categoryState
  sepecification: spesificationState
}

interface catalogAddStatus {
  confirmUpdateProduct: boolean
  dataUpdateProduct: null | dataUpdateProductProps;
  idProduct: string
}

// Default value untuk kedua state


const initialState: catalogAddStatus = {
  confirmUpdateProduct: false,
  dataUpdateProduct: null,
  idProduct: ''
};

const statusTypeCatalogAdd = createSlice({
  name: "DataEditProduct",
  initialState,
  reducers: {
    setConfirmUpdateProductModal: (state, action: PayloadAction<boolean>) => {
      state.confirmUpdateProduct = action.payload;
    },
    setIdProduct: (state, action: PayloadAction<string>) => {
      state.idProduct = action.payload;
    },
    setDataUpdate: (state, action: PayloadAction<dataUpdateProductProps>) => {
      state.dataUpdateProduct = action.payload;
    },
    resetDataInputCatalog: (state) => {
      state.dataUpdateProduct = null;
    },
    setUpdateNameProduct: (state, action: PayloadAction<string>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.productName = action.payload;
    },
    setShortDescUnVarian: (state, action: PayloadAction<string>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.shortDesc = action.payload;
    },
    setLongDescUnVarian: (state, action: PayloadAction<string>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.longDesc = action.payload;
    },
    updateProductCover: (state, action: PayloadAction<File[]>) => {
      if (!state.dataUpdateProduct) {   
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.productCover = action.payload;
    },
    setMainCategory: (state, action: PayloadAction<string>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.category.mainCategory = action.payload;
    },
    setSecondCategory: (state, action: PayloadAction<string>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.category.secondCategory = action.payload;
    },
    setThirdCategory: (state, action: PayloadAction<string>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.category.thirdCategory = action.payload;
    },
    setCategoryId: (state, action: PayloadAction<string>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.category.categoryId = action.payload;
    },
    setUpdatePrice: (state, action: PayloadAction<number>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.productPrice = action.payload;
    },
    setDiskon: (state, action: PayloadAction<number>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.pricingDetail.diskon = action.payload;
    },
    setMinOrder: (state, action: PayloadAction<number>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.pricingDetail.minOrder = action.payload;
    },
    setStock: (state, action: PayloadAction<number>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.pricingDetail.stok = action.payload;
    },
    setLongProduct: (state, action: PayloadAction<number>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.sepecification.longProduct = action.payload;
    },
    setWidthProduct: (state, action: PayloadAction<number>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.sepecification.widthProduct = action.payload;
    },
    setHeightProduct: (state, action: PayloadAction<number>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.sepecification.heightProduct = action.payload;
    },
    setWeightProduct: (state, action: PayloadAction<number>) => {
      if (!state.dataUpdateProduct) {
        state.dataUpdateProduct = { ...defaultDataInput };
      }
      state.dataUpdateProduct.sepecification.weightProduct = action.payload;
    },
  },
});

export const {
  setConfirmUpdateProductModal,
  setIdProduct,
  setDataUpdate,
  resetDataInputCatalog,
  setUpdateNameProduct,
  setShortDescUnVarian,
  setLongDescUnVarian,
  updateProductCover,
  setMainCategory,
  setSecondCategory,
  setThirdCategory,
  setCategoryId,
  setUpdatePrice,
  setDiskon,
  setMinOrder,
  setStock,
  setLongProduct,
  setWidthProduct,
  setHeightProduct,
  setWeightProduct
} = statusTypeCatalogAdd.actions;
export default statusTypeCatalogAdd.reducer;
