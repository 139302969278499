import React, { useState } from 'react'
import { ToggleCard } from './toggleCard';

export default function PrivacyNotice() {
  const [accesListContact, setAccesListContact] = useState<boolean>(false);
  const [accesMicrophone, setAccesMicrophone] = useState<boolean>(false);
  const [accesPhotos, setAccesPhotos] = useState<boolean>(false);
  const [accesCamera, setAccesCamera] = useState<boolean>(false);
  const handleToggleWithModal = (toggleAction: () => void, title: string, desc: string) => {
    toggleAction();
  };
  // const handleCloseModal = () => setShowModal(false);
  const toggleContact = () =>
    handleToggleWithModal(
      () => setAccesListContact(!accesListContact),
      'Berhenti mengizinkan?',
      'Akses kontak memudahkan kamu saat bertransaksi',
    );
  const toggleMicro = () =>
    handleToggleWithModal(
      () => setAccesMicrophone(!accesMicrophone),
      'Berhenti mengizinkan?',
      'Akses Microphone memudahkan kamu saat bertransaksi',
    );
  const togglePhotos = () =>
    handleToggleWithModal(
      () => setAccesPhotos(!accesPhotos),
      'Berhenti mengizinkan?',
      'Akses foto memudahkan kamu saat bertransaksi',
    );
  const toggleCamera = () =>
    handleToggleWithModal(
      () => setAccesCamera(!accesCamera),
      'Berhenti mengizinkan?',
      'Akses kamera memudahkan kamu saat bertransaksi',
    );
  return (
    <section className="w-full px-4 py-6 border-l border-zinc-200 flex-col justify-start items-center ">
      <h3 className="text-center text-[#25282B] text-[24px] font-bold mb-[48px]">Pengaturan Sistem</h3>
      <div className="mt-[48px] flex flex-col gap-[24px]">
        <ToggleCard
          head={'Izinkan SuperApp untuk mengakses daftar kontak'}
          text={'Berikan akses agar dapat menemukan kontak di MBG'}
          action={toggleContact}
          consition={accesListContact}
        />
        <ToggleCard
          head={'Izinkan MBG untuk mengakses microphone'}
          text={'Berikan akses agar dapat memberikan penilaian review & ulasan'}
          action={toggleMicro}
          consition={accesMicrophone}
        />
        <ToggleCard
          head={'Izinkan MBG untuk mengakses album foto'}
          text={'Berikan akses agar dapat mengunggah foto untuk memberikan review & ulasan'}
          action={togglePhotos}
          consition={accesPhotos}
        />
        <ToggleCard
          head={'Izinkan MBG untuk mengakses kamera'}
          text={'Berikan akses agar dapat mengambil foto dan video untuk memberikan review dan ulasan'}
          action={toggleCamera}
          consition={accesCamera}
        />
      </div>
    </section>
  )
}
