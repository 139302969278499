import React, { useEffect } from 'react'
import NameProductInput from '../add/form/inputComponent/nameProductInput'
import Categoryinput from '../add/form/inputComponent/categoryInput'
import PictureUpload from '../add/form/inputComponent/PictureUpload'
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store'
import ShortDescriptionInput from '../add/form/inputComponent/shortDescriptionInput'
import LongDescriptionInput from '../add/form/inputComponent/longDescriptionInput'
import PricingInput from '../add/form/inputComponent/pricingInput'
import InputSpecificationProduct from '../add/form/inputComponent/inputSpecificationProduct'
import { useNavigate } from 'react-router-dom'
import { setCategoryId, setConfirmUpdateProductModal, setDiskon, setHeightProduct, setLongDescUnVarian, setLongProduct, setMinOrder, setShortDescUnVarian, setStock, setUpdateNameProduct, setUpdatePrice, setWeightProduct, setWidthProduct, updateProductCover } from '../../../redux/slices/pages/catalog/updateProduct'
import UpdateProductConfirm from '../../modals/catalog/confirmUpdateProduct'

export default function UpdateProduct() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const data = useAppSelector((state: RootState) => state.DataEditProduct.dataUpdateProduct)
    const valueCategory = data?.category
    const PriceValue = {
        productPrice: data?.productPrice,
        diskon: data?.pricingDetail.diskon,
        minDP: 40,
        minQtyOrder: data?.pricingDetail.minOrder,
        qtyStok: data?.pricingDetail.stok,
    }
    const handleSubmit = () => {
        {
            dispatch(setConfirmUpdateProductModal(true))
        }
    }
    const handleUpdateNameProductUnVarian = (newName: string) => {
        dispatch(setUpdateNameProduct(newName))
    }
    const handleCategoryId = (id: string) => {
        dispatch(setCategoryId(id))
    }
    const handleUpdateProductCover = (coverImage: File[]) => {
        dispatch(updateProductCover(coverImage))
    }
    const handleUpdateShortDesc = (shortDesc: string) => {
        dispatch(setShortDescUnVarian(shortDesc))
    }
    const handleUpdateLongDesc = (longDesc: string) => {
        dispatch(setLongDescUnVarian(longDesc))
    }
    const handleUpdateProductPrice = (price: number) => {
        dispatch(setUpdatePrice(price))
    }
    const handleUpdateDiskon = (newDiskon: number) => {
        dispatch(setDiskon(newDiskon))
    }
    const handleUpdateMinDP = () => {

    }
    const handleUpdateMinQtyOrder = (newMinQty: number) => {
        dispatch(setMinOrder(newMinQty))
    }
    const handleUpdateQtyStok = (newStock: number) => {
        dispatch(setStock(newStock))
    }
    const handelUpdateLongProduct = (newLong: number) => {
        dispatch(setLongProduct(newLong))
    }
    const handelUpdateWeightProduct = (newWeight: number) => {
        dispatch(setWeightProduct(newWeight))
    }
    const handelUpdateheightProduct = (newHeight: number) => {
        dispatch(setHeightProduct(newHeight))
    }
    const handelUpdateWidthProduct = (width: number) => {
        dispatch(setWidthProduct(width))
    }
    useEffect(() => {
        if (data === null) {
            navigate('/catalog')
        }
    }, [data])
    return (
        <>
            {/* modal */}
            <UpdateProductConfirm />
            {/* modal */}
            <NameProductInput
                value={data?.productName}
                setvalue={handleUpdateNameProductUnVarian} />
            <Categoryinput
                setIdCategory={handleCategoryId}
                value={
                    valueCategory?.mainCategory && valueCategory?.secondCategory && valueCategory?.thirdCategory
                        ? `${valueCategory.mainCategory} / ${valueCategory.secondCategory} / ${valueCategory.thirdCategory}`
                        : valueCategory?.mainCategory && valueCategory?.secondCategory
                            ? `${valueCategory.mainCategory} / ${valueCategory.secondCategory}`
                            : valueCategory?.mainCategory
                                ? `${valueCategory.mainCategory}`
                                : 'Menu Utama/Makanan dan Minuman/Makanan Utama'
                } />
            <PictureUpload
                value={data?.productCover}
                handleAddProductCover={handleUpdateProductCover} />
            <ShortDescriptionInput
                value={data?.shortDesc}
                setValue={handleUpdateShortDesc} />
            <LongDescriptionInput
                value={data?.longDesc}
                setValue={handleUpdateLongDesc} />
            <PricingInput
                value={PriceValue}
                setProductPrice={handleUpdateProductPrice}
                setDiskon={handleUpdateDiskon}
                setMinDP={handleUpdateMinDP}
                setMinQtyOrder={handleUpdateMinQtyOrder}
                setQtyStok={handleUpdateQtyStok} />
            <InputSpecificationProduct
                longValue={data?.sepecification?.longProduct}
                setLongValue={handelUpdateLongProduct}
                weightValue={data?.sepecification?.weightProduct}
                setWeightValue={handelUpdateWeightProduct}
                heightValue={data?.sepecification?.heightProduct}
                setHeightValue={handelUpdateheightProduct}
                widthValue={data?.sepecification?.widthProduct}
                setWidthValue={handelUpdateWidthProduct} />
            <section className='flex justify-end mt-4'>
                <button
                    onClick={handleSubmit}
                    className='px-6 py-2 rounded-full text-white bg-primaryColor hover:bg-secondaryColor duration-300'>
                    Simpan
                </button>
            </section>
        </>
    )
}
