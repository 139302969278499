import React from 'react'
interface shortDescProps {
  value: string | undefined
  setValue: (value: string) => void
}
export default function ShortDescriptionInput({
  value,
  setValue
}: shortDescProps) {
   const handleSetValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
    };
  return (
    <div>
        <p className="text-[16px] mb-[8px] font-inter font-semibold">Deskripsi Pendek</p>
        <textarea
          name="shortDescription"
          value={value}
          onChange={handleSetValue}
          placeholder="Deskripsi Product"
          maxLength={100}
          className="py-[16px] border outline-none  w-full h-[120px] rounded-lg pl-[20px] resize-none"
        />
      </div>
  )
}
