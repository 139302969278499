import React from 'react'
import { UdinIcon } from '../../../../../asset/icon/profile/payment'

export default function Udin() {
  return (
    <div className="text-center flex flex-col justify-center py-[20px] px-[30px] gap-[10px]">
      <h3 className="text-[#25282B] text-[24px] font-bold">Udin</h3>
      <div className="mx-auto flex justify-center items-center w-[320px] h-[320px] rounded-[16px]">
        <UdinIcon />
      </div>
      <h6 className="text-[#25282B] text-[32px] font-normal">
        Saldo : Rp.<span className="text-custom-primaryvendor">0</span>
      </h6>
      <button
        disabled
        className="bg-[#E1E1E1] rounded-full text-[#BCBCBC] py-[12px] mt-[54px]">
        Pilih Metode
      </button>
    </div>
  )
}
