import React from "react";

export default function ItemProduct() {
  return (
    <div className="border-[#DDE5E9] border-[1px] px-[16px] py-[8px] rounded-[8px] flex gap-[16px] items-center">
      <img
        className="w-[69px] h-[69px] rounded-[4px] object-cover"
        alt="product"
        src="https://i.pinimg.com/236x/eb/cb/c6/ebcbc6aaa9deca9d6efc1efc93b66945.jpg"
      ></img>
      <div>
        <div className="flex gap-[8px] text-[#818284] text-[14px]">
          <div className="flex justify-between w-[128px]">
            <p>Id Pengiriman</p>
            <p>:</p>
          </div>
          <p>B48927163</p>
        </div>
        <div className="flex gap-[8px] text-[#818284] text-[14px]">
          <div className="flex justify-between w-[128px]">
            <p>Total ongkir</p>
            <p>:</p>
          </div>
          <p>Rp 45.000</p>
        </div>
        <p className="text-[#46BB2F] text-[14px] font-normal">Sedang dikirim</p>
      </div>
    </div>
  );
}
