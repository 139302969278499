import {
  combineReducers,
  configureStore,
  UnknownAction,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import AuthInfo from "./slices/auth/authSlices";
import DetailProdusen from "./slices/auth/detailProdusenSlice";
import UpcomingStatus from "./slices/modal/upComing";
import ModalFoodGizi from "./slices/modal/foodGiziInformation";
import CatalogAddStatus from "./slices/pages/catalog";
import Foodclasification from "./slices/pages/catalog/categoryProduct";
import AgeList from "./slices/pages/catalog/ageList";
import MainCategory from "./slices/pages/catalog/categoryProduct/mainCategory";
import SecondCategory from "./slices/pages/catalog/categoryProduct/secondCategory";
import ThirdCategory from "./slices/pages/catalog/categoryProduct/thirdCategory";
import FindCategory from "./slices/pages/catalog/categoryProduct";
import ProcessedFoodList from "./slices/modal/catalogAdd/processedFoodList";
import ProcessedFoodDetail from "./slices/modal/catalogAdd/processedFoodDetail";
import RawMaterialsDetail from "./slices/modal/catalogAdd/rawMaterialDetail";
import ProductList from "./slices/pages/catalog/productList/all";
import AddressList from "./slices/profile/address/list";
import LoginActivity from "./slices/profile/security/loginActivity";
import DataDeleteProduct from "./slices/pages/catalog/deleteProduct";
import DataEditProduct from "./slices/pages/catalog/updateProduct";
import ProductPopular from "./slices/pages/analysis/popularProduct";
import SummaryPerfomance from "./slices/pages/analysis/summaryPerfomance";
import RevenueChart from "./slices/pages/analysis/revenueChart";
import DataChangeBio from "./slices/profile/changeBio";
import DellAccountReason from "./slices/deleteAccountReason";
import OrderList from "./slices/pages/order/list";
import HelpCenterList from "./slices/pages/help-center/main";
import SubHelpCenterList from "./slices/pages/help-center/sub";
import SpecificHelpCenterList from "./slices/pages/help-center/specific";
import Notification from "./slices/pages/notification";
import MyStoreDetail from "./slices/pages/merchant/detail";
import ChatList from "./slices/pages/chat/list";
import MessageData from "./slices/pages/chat/detail";
import ErrorData from "./slices/ErrorHandling";
import ShippingData from "./slices/pages/shipping";
import ChatModal from "./slices/modal/chat";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["DetailProdusen", "DataEditProduct", "DataChangeBio"],
};
const rootReducer = combineReducers({
  AuthInfo,
  DetailProdusen,
  //profile
  AddressList,
  LoginActivity,
  //change bio
  DataChangeBio,
  Notification,
  // catalog
  AgeList,
  CatalogAddStatus,
  Foodclasification,
  // product list
  ProductList,
  //update product
  DataEditProduct,
  //delete product
  DataDeleteProduct,
  //giziInformation
  ProcessedFoodList,
  ProcessedFoodDetail,
  RawMaterialsDetail,
  //category
  MainCategory,
  SecondCategory,
  ThirdCategory,
  // findCategoryStep
  FindCategory,
  //modal food gizi information
  ModalFoodGizi,
  //analysis
  ProductPopular,
  SummaryPerfomance,
  RevenueChart,
  // modalUpcomingStatus
  UpcomingStatus,
  DellAccountReason,
  OrderList,
  HelpCenterList,
  SubHelpCenterList,
  SpecificHelpCenterList,
  MyStoreDetail,
  ChatList,
  MessageData,
  ErrorData,
  ShippingData,
  ChatModal,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Buat store dan gabungkan semua reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

// Definisikan RootState dan AppDispatch untuk kemudahan penggunaan TypeScript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Hook kustom untuk dispatch dan selector yang mendukung TypeScript
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
