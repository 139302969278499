import { useEffect, useRef, useState } from "react";
import axiosInstance from "../../api/axiosInstance";

export function useScrollToEnd(data: any) {
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollTop = chatEndRef.current.scrollHeight;
    }
  }, [data]);

  return chatEndRef;
}
export function useChatModals() {
  const [dotModals, setDotModals] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setDotModals(false);
      }
    };

    if (dotModals) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dotModals]);

  return {
    dotModals,
    setDotModals,
    modalRef,
  };
}
