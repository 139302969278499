import React from "react";

const ChatListSkeleton = () => {
  return (
    <div className="flex flex-col gap-[10px] mt-[12px] max-h-[65vh] overflow-y-auto no-scrollbar">
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className="cursor-pointer border-[#DDE5E9] border-[1px] py-[15px] px-[10px] rounded-[12px] flex w-full"
        >
          {/* Gambar Profil Skeleton */}
          <div className="rounded-full w-[67px] h-[67px] bg-gray-200 animate-pulse"></div>

          {/* Konten Skeleton */}
          <div className="ml-[10px] flex-1">
            {/* Nama Toko Skeleton */}
            <div className="w-[120px] h-[20px] bg-gray-200 animate-pulse rounded"></div>

            {/* Pesan Skeleton */}
            <div className="w-[200px] h-[16px] bg-gray-200 animate-pulse rounded mt-[8px]"></div>
          </div>

          {/* Tanggal Skeleton */}
          <div className="w-[60px] h-[12px] bg-gray-200 animate-pulse rounded ml-auto mt-[8px]"></div>
        </div>
      ))}
    </div>
  );
};

export default ChatListSkeleton;
