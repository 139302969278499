import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../api/axiosInstance';
import { RootState, useAppSelector } from '../../../redux/store';
import axios from 'axios';

export default function InputSection() {
    const location = useLocation();
    const navigate = useNavigate();
    const dataDetailProdusen = useAppSelector((state: RootState) => state.DetailProdusen.produsenDetail);
    const [pin, setPin] = useState('');
    const [isErr, setIsErr] = useState(false);
    const [paymentErr, setPaymentErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 6) {
            setPin(value);
        }
    };
    const handleForgotPIN = () => {
        setLoading(true);
        const body = {
            email: dataDetailProdusen?.datas.userId.email.content,
        };
        axiosInstance
            .post(`${process.env.REACT_APP_BASE_URL}/forget/pin`, body)
            .then((res) => {
                console.log(res);
                navigate('/otp', { state: { source: 'forgot-pin', resetToken: res.data.token, id: res.data.id } });
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleSubmitChangeEmail = () => {
        const body = {
            email: dataDetailProdusen?.datas.userId.email.content,
        };
        axiosInstance
            .post('/user/otp/edit-auth/old', body)
            .then((res) => {
                console.log(res);
                navigate('/otp', { state: { source: 'change-email', id: res.data.id } });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handleSubmitChangePhone = () => {
        const body = {
            phone: dataDetailProdusen?.datas.userId.phone.content,
        }
        axiosInstance
            .post('/user/otp/edit-auth/old', body)
            .then((res) => {
                navigate('/otp', { state: { source: 'change-phone', id: res.data.id } });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        if (pin.length === 6) {
            const body = {
                pin,
            };
            axiosInstance
                .post(`/user/check-pin`, body)
                .then((res) => {
                    console.log(location.state.source);
                    if (location.state.source === 'change-email') {
                        handleSubmitChangeEmail();
                    } else if (location.state.source === 'change-phone') {
                        handleSubmitChangePhone();
                    } else {
                        console.log(location.state.source);
                        console.log(`handling by ${location.state.source} belum ditambahkan harap hubungi developer`);
                    }
                })
                .catch((err) => {
                    setIsErr(true);
                });
        }
    }, [pin])
    useEffect(() => {
        const stateSource = (location.state as any)?.source;
        if (
            !location.state ||
            (stateSource !== 'payment'
                && stateSource !== 'change-email'
                && stateSource !== 'change-phone')
        ) {
            navigate('/');
        } else {
            console.log(stateSource);
        }
    }, [location.state, navigate]);
    useEffect(() => {
        setIsErr(false);
        setPaymentErr(false);
    }, [pin]);
    return (
        <div className="flex flex-col items-center justify-center h-screen w-[507px]">
            <div className="bg-white shadow-lg rounded-[12px] p-[56px] w-full text-center">
                <h2 className="text-[32px] text-[#222831] font-bold mb-4">Masukan PIN MBG</h2>
                <p className="text-sm text-gray-600 mb-6">Masukan 6 Angka PIN Anda</p>
                <input
                    type="password"
                    maxLength={6}
                    value={pin}
                    onChange={handleChange}
                    className="text-center text-2xl w-32 p-2 border-none rounded-lg tracking-widest focus:outline-none"
                    placeholder="••••••"
                />
                <hr className="w-full"></hr>
                {isErr && <p className="mt-[12px] text-[12px] text-center font-normal text-red-300">Pin Salah</p>}
                {paymentErr && <p className="mt-[12px] text-[12px] text-center font-normal text-red-300">Sedang ada transaki dengan virtual account ini</p>}
                <p className="mt-4 text-sm text-blue-500 cursor-pointer">
                    <button onClick={() => handleForgotPIN()}>{loading ? 'Loading...' : 'Lupa Pin'}</button>
                </p>
            </div>
        </div>
    )
}
