// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AddressListProps } from "../../../../../model/profile/setting/addressList";
import axiosInstance from "../../../../../api/axiosInstance";

interface addressListState {
  loading: boolean;
  error: string | null;
  addressList: AddressListProps | null;
}

const initialState: addressListState = {
  loading: false,
  error: null,
  addressList: null,
};

// AsyncThunk untuk mengambil addressList
export const getAddressList = createAsyncThunk(
  "profile/addressList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/address/list`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const profileAddressListSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddressList.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(
        getAddressList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.addressList = action.payload;
        }
      )
      .addCase(getAddressList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default profileAddressListSlice.reducer;
