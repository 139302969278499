// dataDummy.ts

// Data Dummy untuk Chat
export const dataChat = {
    data: [
      { id: 1, message: "Hi, how are you?", sender: "User A" },
      { id: 2, message: "Can I get a refund?", sender: "User B" }
    ]
  };
  
  // Data Dummy untuk Notifikasi
  export const dataNotifikasi = {
    total: 5,
  };
  
  // Data Dummy untuk Keranjang (Cart)
  export const dataCart = {
    data: {
      datas: [
        { id: 1, name: "Product A", quantity: 2, price: 100 },
        { id: 2, name: "Product B", quantity: 1, price: 200 }
      ]
    }
  };
  
  // Data Dummy untuk Detail Konsumen
  export const dataDetailKonsumen = {
    datas: {
      nama: "John Doe",
      profile_pict: "https://via.placeholder.com/150"
    }
  };
  
  // Data Dummy untuk Kategori
  export const categoryList = {
    data: [
      { name: "Makanan" },
      { name: "Minuman" },
      { name: "Elektronik" },
      { name: "Pakaian" }
    ]
  };
  