import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { WhiteArrowLeftIcon } from "../../asset/icon/helpCenterDashboard";
import Copyright from "../../component/helpCenterDashboard/copyright";
import Footer from "../../component/helpCenterDashboard/footer";
import Navbar from "../../component/helpCenterDashboard/navbar";
import ChatCS from "../../component/modals/chat";

export default function HelpCenterDashboard() {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col items-start justify-start">
      <ChatCS />
      <Navbar />
      <div className="h-[450px] rounded-[32px] bg-primaryColor w-full"></div>
      <div className="max-w-[1140px] mx-auto flex justify-center mt-[-274px]">
        <div className="flex flex-col w-full pl-6">
          <div onClick={() => navigate(-1)} className="flex gap-[8px]">
            <WhiteArrowLeftIcon />
            <button className="text-[#fff] mb-4">Kembali</button>
          </div>
          <Outlet />
          <Footer />
          <Copyright />
        </div>
      </div>
    </div>
  );
}
