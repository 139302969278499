import React from 'react'
import MainHeader from '../mainDashboard/header'
import InputSection from './inputSection'
import BgOtp from '../../asset/images/otp/BgOtp.png'

export default function PinSection() {
  return (
     <section className="w-full h-[100vh] flex flex-col justify-center items-center">
         <MainHeader/>
         <div className='h-full flex items-center'>
                <img src={BgOtp} alt="" className="h-auto border-black" />
            </div>
            <div className="flex justify-center items-center absolute">
                <InputSection />
            </div>
     </section>
  )
}
