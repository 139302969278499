import React, { useState } from 'react'
import TogleCard from '../mbg/togleCard';

export default function Sms() {
  const [transaksi, setTransaksi] = useState(false);
  const [keamanan, setKeamanan] = useState(false);
  const [Keuangan, setKeuangan] = useState(false);
  const [promo, setPromo] = useState(false);

  const toggleTransaksi = () => setTransaksi(!transaksi);
  const toggleKeamanan = () => setKeamanan(!keamanan);
  const toggleKeuangan = () => setKeuangan(!Keuangan);
  const togglePromo = () => setPromo(!promo);
  return (
    <section className="w-full px-4 py-6 border-l border-zinc-200 flex-col justify-start items-center ">
        <h3 className="text-center text-[#25282B] text-[24px] font-bold mb-[48px]">SMS</h3>
        {/* <Alert/>
        <Info /> */}
        <h6 className='mt-[16px] text-[#25282B] text-[16px] font-normal tracking-[1px]'>Pemberitahuan penting dan keamanan akun tidak bisa dinonaktifkan</h6>
        <div className='mt-[48px] flex flex-col gap-[24px]'>
            <TogleCard
                head={'Transaksi'}
                text={'Informasi pembayaran, pembelian, atau transfer uang.'}
                action={toggleTransaksi}
                consition={transaksi} />
            <TogleCard
                head={'Peringatan keamanan'}
                text={'Informasi aktivitas mencurigakan atau upaya login dari perangkat yang tidak dikenali.'}
                action={toggleKeamanan}
                consition={keamanan} />
            <TogleCard
                head={'Keuangan'}
                text={'Informasi ketika Anda menarik atau mengisi saldo ke dalam akun e-wallet'}
                action={toggleKeuangan}
                consition={Keuangan} />
            <TogleCard
                head={'Promo'}
                text={'Informasi promo, diskon, atau penawaran khusus'}
                action={togglePromo}
                consition={promo} />
        </div>
    </section>
  )
}
