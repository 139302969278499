import React, { useEffect, useState } from "react";
import {
  AddFileIcon,
  EmoteIcon,
  SendMessage,
} from "../../../../../asset/icon/chat";
import { WebSocketService } from "../../../../../api/WebSocketService";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { GetListChat } from "../../../../../redux/slices/pages/chat/list";
import {
  addMessage,
  ChatDetail,
  selectMessagesByChatId,
} from "../../../../../redux/slices/pages/chat/detail";
import { parseStringToJson } from "../../../../../utils/chat";

interface ChatInputProps {
  idLawanBicara?: string;
  idChatActive?: string;
}

export default function ChatInput({
  idLawanBicara,
  idChatActive,
}: ChatInputProps) {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState("");
  const messages = useAppSelector((state: RootState) => state.MessageData.data);

  const submitMsg = () => {
    if (message !== "") {
      // Kirim pesan
      WebSocketService.emit(
        "send msg",
        { userId: idLawanBicara, msg: message },
        (response: any) => {
          if (response.error) {
            console.error("Error dari server:", response.error);
            alert(`Error: ${response.error}`);
          } else if (!response) {
            console.log("Server tidak memberikan respons");
          } else {
            console.log("Pesan berhasil diproses:", response);
          }
        }
      );

      setMessage(""); // Bersihkan input setelah pesan dikirim
    } else {
      console.log("Pesan tidak boleh kosong");
      alert("Pesan tidak boleh kosong");
    }
  };

  useEffect(() => {
    const handleConnect = () => {
      console.log("Terhubung ke server");
    };

    const handleConnectError = (error: any) => {
      console.error("Koneksi gagal:", error);
    };

    const handleDisconnect = () => {
      console.log("Koneksi terputus");
    };

    WebSocketService.on("msg", (data: any) => {
      const parsedData = parseStringToJson(data);
      const newMessage = {
        sender: parsedData.sender,
        content: parsedData.content,
        timestamp: parsedData.timestamp,
        _id: parsedData.chatId,
      };

      // Pastikan pesan hanya ditambahkan sekali
      if (parsedData.chatId === idChatActive) {
        const isMessageExist = messages?.data.messages.some(
          (msg: any) => msg === newMessage._id
        );
        if (!isMessageExist) {
          dispatch(addMessage({ newMessage }));
        } else {
          console.log("Pesan sudah ada, tidak ditambahkan lagi.");
        }
      } else {
        console.error("Gagal memasukkan pesan baru ke dalam list");
      }

      // Memperbarui list chat
      dispatch(GetListChat());
      // dispatch(ChatDetail({ chatId: idChatActive }));
    });

    // Tambahkan listener
    WebSocketService.on("connect", handleConnect);
    WebSocketService.on("connect_error", handleConnectError);
    WebSocketService.on("disconnect", handleDisconnect);

    // Cleanup listener
    return () => {
      WebSocketService.off("msg");
      WebSocketService.off("connect", handleConnect);
      WebSocketService.off("connect_error", handleConnectError);
      WebSocketService.off("disconnect", handleDisconnect);
    };
  }, [dispatch, idChatActive, messages]);

  return (
    <div className="flex gap-[32px] items-center w-full mt-[16px]">
      <div className="bg-[#F3F6FB] rounded-full py-[16px] px-[24px] flex justify-between items-center flex-1">
        <div className="flex gap-[16px] items-center flex-1">
          <EmoteIcon />
          <input
            placeholder="Tulis Pesan ..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && message.trim() !== "") {
                submitMsg();
              }
            }}
            className="w-full appearance-none border-none outline-none shadow-none bg-transparent p-0 m-0 pl-[4px]"
          />
        </div>
        <div className="px-[8px]">
          <AddFileIcon />
        </div>
      </div>
      <div onClick={submitMsg} className="cursor-pointer">
        <SendMessage />
      </div>
    </div>
  );
}
