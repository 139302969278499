// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { MyStoreListProps } from "../../../../../model/merchant";
import axiosInstance from "../../../../../api/axiosInstance";

interface MyStoreDetailState {
  loading: boolean;
  error: string | null;
  myStore: MyStoreListProps | null;
}

const initialState: MyStoreDetailState = {
  loading: false,
  error: null,
  myStore: null,
};

// AsyncThunk untuk mengambil addressList
export const getMyStoreDetail = createAsyncThunk(
  "merchant/MyStore",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/supplier/detail/my-store`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const myStoreDetailSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyStoreDetail.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(
        getMyStoreDetail.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.myStore = action.payload;
        }
      )
      .addCase(
        getMyStoreDetail.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload; // Simpan pesan error jika request gagal
        }
      );
  },
});

export default myStoreDetailSlice.reducer;
