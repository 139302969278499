import React from 'react'
import { ArrowLeftIcon } from '../../../../asset/icon/modals/catalog/foodGiziInformation'

export default function PrivacyNotice() {
  const names: string[] = [
    'Pengantar',
    'Data Pribadii yang Dikumpulkan',
    'Melindungi Data Pribadi Anda',
    'Penggunaan Data',
    'Hak Anda sebagai pengguna',
    'Perubahan Pada Kebijakan Privasi',
    'Hubungi Kami',
  ];
  const handleGetData = (section: string) => {
    console.log('get data')
  }

  return (
    <section className="w-full px-4 py-6 border-l border-zinc-200 flex-col justify-start items-center">
      <h3 className="text-center text-[#25282B] text-[24px] font-bold mb-[48px]">
        Pemberitahuan Privasi Tentang MBG
      </h3>
      <p className="font-inter font-bold text-base text-[#25282B]">Kebijakan Privasi</p>
      {/* ----> */}
      <div className="mt-[48px] flex flex-col gap-[24px]">
        {names.map((name, index) => (
          <div onClick={() => handleGetData(name)} key={index} className="flex justify-between items-center cursor-pointer">
            <p className="font-inter font-normal text-base capitalize text-neutral-600">{name}</p>
            <div className="rotate-180">
              <ArrowLeftIcon />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
