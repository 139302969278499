import React, { useEffect } from "react";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { getAllHelpCenterList } from "../../../../../redux/slices/pages/help-center/main";
import LoadingSpinner from "../../../../loadSpinner";
import { useNavigate } from "react-router-dom";

interface CategoryProps {
  typeFilter: string;
}

function Category({
  typeFilter,
  searchTerm,
}: CategoryProps & { searchTerm: string }) {
  const dispatch = useAppDispatch();
  const helpCenterState = useAppSelector(
    (state: RootState) => state.HelpCenterList.helpCenterList?.data
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const filteredPenjual = helpCenterState?.filter(
    (item: any) =>
      item.type === typeFilter &&
      item.nama.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getAllHelpCenterList());
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const navigate = useNavigate();

  const handleSub = (id: string, nama: string) => {
    navigate(`/help-center-supplier/sub/${id}`, { state: { nama, id } });
  };

  return (
    <div className="mb-14">
      {loading ? (
        <div className="justify-center flex items-center">
          <LoadingSpinner width="36px" height="36px" />
        </div>
      ) : filteredPenjual && filteredPenjual.length > 0 ? (
        <div className="flex flex-wrap gap-16">
          {filteredPenjual.map((item: any, index: number) => (
            <div key={index} className="flex flex-col items-center gap-2">
              <div
                className="bg-[#E8EEF8] cursor-pointer w-16 h-16 rounded-full p-4"
                onClick={() => handleSub(item._id, item.nama)}
              >
                <img src={item.icon} className="w-8 h-8 object-contain" />
              </div>
              <div className="text-[#25282B] text-sm font-medium">
                {item.nama}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-neutral-400 py-4">
          Tidak ada hasil yang ditemukan untuk "{searchTerm}".
        </div>
      )}
    </div>
  );
}

export default Category;
