import React from "react";
import { ImageIcon } from "../../../../../asset/icon/merchant";

interface UploadMediaProps {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveFile: (index: number) => void;
  imageCount: number;
  isShowErr: boolean;
  isShowing: boolean;
  errorImage: string;
  errorVideos: string;
  dataFiles: { type: string }[];
  dataPreviews: string[];
  MAX_IMAGES: number;
}

const UploadMediaComponent: React.FC<UploadMediaProps> = ({
  handleFileChange,
  handleRemoveFile,
  MAX_IMAGES,
  imageCount,
  isShowErr,
  isShowing,
  errorImage,
  errorVideos,
  dataFiles,
  dataPreviews,
}) => {
  return (
    <div>
      <p className="text-[16px] mb-[8px] font-inter font-semibold">
        Unggah Foto Profile <span className="text-[red]">*</span>
      </p>
      <div className="w-full py-4 border border-dashed border-primaryColor rounded-lg h-[160px]">
        <div className="flex items-center justify-center">
          <label
            className={`${
              imageCount === 1 ? "cursor-not-allowed" : "cursor-pointer"
            } flex flex-col items-center justify-center rounded-lg w-[409px] h-[118px]`}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <ImageIcon />
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                Unggah Media
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                Unggah Foto (PNG,JPG)
              </p>
            </div>
            <input
              type="file"
              accept="image/png,image/jpeg,video/mp4"
              multiple
              className="hidden"
              disabled={imageCount === MAX_IMAGES}
              onChange={handleFileChange}
            />
          </label>
        </div>
      </div>
      {isShowErr && (
        <p style={{ color: "red", fontSize: "12px" }}>{errorImage}</p>
      )}
      {isShowing && (
        <p style={{ color: "red", fontSize: "12px" }}>{errorVideos}</p>
      )}
      {dataFiles.length > 0 ? (
        <div className="flex space-x-5 mt-2">
          {dataPreviews.map((preview: string, index: number) => (
            <div key={index} className="relative">
              {dataFiles[index].type.startsWith("image") ? (
                <div className="w-[112.58px] h-[106.61px]">
                  <img
                    className="w-full h-full object-cover rounded-lg"
                    src={preview}
                    alt="Preview"
                  />
                </div>
              ) : (
                <video
                  className="w-[112.58px] h-[106.61px]"
                  controls
                  width="100"
                >
                  <source src={preview} />
                </video>
              )}
              <button
                className="bg-[#D9D9D9] p-[3px] ml-[100px] mt-[-14px] text-[14px] rounded-full h-[25px] w-[25px] absolute top-0 -right-[10px] flex justify-center items-center"
                onClick={() => handleRemoveFile(index)}
              >
                x
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="font-inter font-medium text-sm text-[#A6A7A8] text-left">
          *Catatan : silakan lakukan unggah dokumen, size file (PNG/JPG) Max 1
          MB.
        </p>
      )}
    </div>
  );
};

export default UploadMediaComponent;
