export const WhiteArrowLeftIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 19.5625L8.5 12.5625L15.5 5.5625"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AshArrowRightIcon = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.70637 6.28146C7.89384 6.46899 7.99915 6.7233 7.99915 6.98846C7.99915 7.25363 7.89384 7.50793 7.70637 7.69546L2.04937 13.3525C1.95712 13.448 1.84678 13.5242 1.72477 13.5766C1.60277 13.629 1.47155 13.6566 1.33877 13.6577C1.20599 13.6589 1.07431 13.6336 0.951413 13.5833C0.828516 13.533 0.716864 13.4587 0.622971 13.3649C0.529078 13.271 0.454825 13.1593 0.404544 13.0364C0.354264 12.9135 0.328962 12.7818 0.330116 12.6491C0.33127 12.5163 0.358856 12.3851 0.411265 12.2631C0.463674 12.1411 0.539856 12.0307 0.635366 11.9385L5.58537 6.98846L0.635366 2.03846C0.453208 1.84986 0.352414 1.59726 0.354692 1.33506C0.35697 1.07286 0.462139 0.822051 0.647547 0.636643C0.832955 0.451235 1.08377 0.346066 1.34597 0.343788C1.60816 0.341509 1.86076 0.442304 2.04937 0.624462L7.70637 6.28146Z"
      fill="#818284"
    />
  </svg>
);
