// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../api/axiosInstance";
import { revenueChartProps } from "../../../../../model/analysis/revenueChart";

interface revenueChartState {
  loading: boolean; 
  error: string | null;
  revenueData: revenueChartProps | null; 
}

const initialState: revenueChartState = {
  loading: false,
  error: null,
  revenueData: null,
};

// AsyncThunk untuk mengambil revenue data yearly
export const getRevenueChart = createAsyncThunk(
  "analysis/revenue chart",
  async (year: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/penghasilan/grafik-keuangan?tahun=${year}`);
      return response.data; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const analysis = createSlice({
  name: "revenue chart analysis",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRevenueChart.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(getRevenueChart.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.revenueData = action.payload; // Simpan data yang berhasil di-fetch
      })
      .addCase(getRevenueChart.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default analysis.reducer;
