import React from "react";

export default function ShippingSkeleton() {
  return (
    <div className="animate-pulse">
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          className="flex flex-col mb-4 bg-white rounded-lg shadow-md p-4"
        >
          <div className="flex gap-4">
            <div className="w-[152px] h-[152px] bg-gray-300 rounded-md"></div>
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-gray-300 rounded"></div>
                <div className="h-4 bg-gray-300 rounded w-5/6"></div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-4">
            <div className="w-1/4 h-4 bg-gray-300 rounded"></div>
            <div className="flex gap-4">
              <div className="h-8 w-24 bg-gray-300 rounded-md"></div>
              <div className="h-8 w-32 bg-gray-300 rounded-md"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
