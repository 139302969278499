import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import CSS untuk Skeleton

function ProductListSkeleton() {
  return (
    <div className="mt-[7px]">
      <div className="w-[16vw] bg-[#FFFFFF] rounded-lg py-2 px-4">
        {/* Bagian Header */}
        <div className="flex justify-between items-center">
          <p className="text-[#4A4D4F] text-xs">
            <Skeleton width={50} height={20} /> {/* Skeleton untuk tanggal */}
          </p>
          <div className="bg-secondaryColor rounded-xl p-2 text-xs font-semibold">
            <Skeleton width={40} height={20} /> {/* Skeleton untuk status */}
          </div>
        </div>

        {/* Bagian Produk */}
        <div className="flex gap-[7px] mt-[7px] items-center">
          {/* Skeleton untuk gambar produk */}
          <div>
            <Skeleton width={46} height={46} circle={false} />
          </div>

          {/* Skeleton untuk nama produk dan harga */}
          <div className="text-[#25282B]">
            <p className="text-sm font-semibold">
              <Skeleton width={100} height={16} />{" "}
              {/* Skeleton untuk nama produk */}
            </p>
            <p className="text-xs font-normal">
              <Skeleton width={80} height={14} />{" "}
              {/* Skeleton untuk label "Total Harga" */}
              <span className="text-sm font-bold">
                <Skeleton width={60} height={14} /> {/* Skeleton untuk harga */}
              </span>
            </p>
          </div>
        </div>

        {/* Skeleton untuk tombol "Pilih Produk" */}
        <div className="mt-[15px]">
          <Skeleton height={36} borderRadius={20} />{" "}
          {/* Skeleton untuk tombol */}
        </div>
      </div>
    </div>
  );
}

export default ProductListSkeleton;
