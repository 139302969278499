import React from 'react'
import { BlockIcon } from '../../../../../asset/icon/catalog/detail'

export default function BlockAlert() {
  return (
    <div className="bg-[#FCE9E9] flex justify-between items-center h-[40px] py-3 px-3">
    <div className="space-x-3 flex">
        <BlockIcon />
        <p className="font-inter font-normal text-sm text-center text-[#E52427]">
            Maaf, produk kamu diblokir karena pelanggaran kebijakan, Untuk informasi lebih lanjut, silakan lihat
            panduan kebijakan kami atau hubungi layanan.
        </p>
    </div>
</div>
  )
}
