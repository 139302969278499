import { RootState, useAppSelector } from "../../../../redux/store";
import Card from "./card";

export default function ListData() {
  const { shippingData = { datas: [] } } = useAppSelector(
    (state: RootState) => state.ShippingData
  );

  return (
    <>
      {shippingData && shippingData.datas.length > 0 ? (
        shippingData.datas.map((item, index) => (
          <Card data={item} key={index} />
        ))
      ) : (
        <p>Tidak ada data yang tersedia.</p>
      )}
    </>
  );
}
