export const EmptyDataShippingIcon = () => (
    <svg width="308" height="205" viewBox="0 0 308 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4260_89702)">
<path d="M218.143 119.942C219.146 138.403 182.995 175.941 138.436 162.047C117.712 155.585 119.467 144.859 103.709 130.722C94.3159 122.296 80.82 115.925 77.0665 103.678C73.824 93.1066 75.8126 81.7442 81.4343 74.3536C94.0774 57.7329 119.601 46.3513 144.751 72.539C169.9 98.7267 215.549 72.3302 218.143 119.942Z" fill="url(#paint0_linear_4260_89702)"/>
<path d="M203.77 74.3891C200.406 80.3847 193.036 81.2084 181.23 80.069C172.351 79.2107 164.254 78.5465 155.374 73.713C149.159 70.3322 144.24 65.7583 140.649 61.3156C136.757 56.5032 131.326 51.0106 133.989 45.5538C137.648 38.058 158.807 31.745 179.354 42.0688C201.924 53.4132 207.062 68.5295 203.77 74.3891Z" fill="url(#paint1_linear_4260_89702)"/>
<path d="M226.059 88.8731C219.002 90.5421 211.539 84.5674 211.539 84.5674C211.539 84.5674 215.522 75.8897 222.581 74.2261C229.637 72.5571 237.098 78.5267 237.098 78.5267C237.098 78.5267 233.116 87.204 226.059 88.8731Z" fill="url(#paint2_linear_4260_89702)"/>
<path d="M241.19 141.113C243.2 139.738 243.399 136.535 243.399 136.535C243.399 136.535 240.337 135.562 238.327 136.939C236.316 138.314 236.117 141.515 236.117 141.515C236.117 141.515 239.179 142.488 241.19 141.113Z" fill="url(#paint3_linear_4260_89702)"/>
<path d="M59.5422 109.833C62.7629 113.106 68.7853 112.416 68.7853 112.416C68.7853 112.416 69.5824 106.42 66.3586 103.149C63.138 99.8764 57.1186 100.564 57.1186 100.564C57.1186 100.564 56.3216 106.56 59.5422 109.833Z" fill="url(#paint4_linear_4260_89702)"/>
<path d="M238.701 117.534C236.528 117.534 234.766 119.293 234.766 121.463C234.766 123.633 236.528 125.393 238.701 125.393C240.875 125.393 242.637 123.633 242.637 121.463C242.637 119.293 240.875 117.534 238.701 117.534Z" fill="#CC0A58"/>
<path d="M106.601 77.2174C103.293 77.2174 100.612 79.8944 100.612 83.1966C100.612 86.4988 103.293 89.1758 106.601 89.1758C109.909 89.1758 112.59 86.4988 112.59 83.1966C112.59 79.8944 109.909 77.2174 106.601 77.2174Z" fill="#CC0A58"/>
<path d="M68.2612 79.6896C65.4672 79.6896 63.2022 77.4283 63.2022 74.6387C63.2022 71.8492 65.4672 69.5879 68.2612 69.5879C71.0553 69.5879 73.3203 71.8492 73.3203 74.6387C73.3203 77.4283 71.0553 79.6896 68.2612 79.6896Z" fill="#CC0A58"/>
<path d="M184.117 62.1836C181.282 62.1836 178.983 59.8891 178.983 57.0586C178.983 54.2281 181.282 51.9336 184.117 51.9336C186.952 51.9336 189.25 54.2281 189.25 57.0586C189.25 59.8891 186.952 62.1836 184.117 62.1836Z" fill="#CC0A58"/>
<path d="M228.433 101.133C227.016 101.133 225.867 99.9855 225.867 98.5703C225.867 97.1551 227.016 96.0078 228.433 96.0078C229.851 96.0078 231 97.1551 231 98.5703C231 99.9855 229.851 101.133 228.433 101.133Z" fill="#E1E4E5"/>
<path d="M48.5934 151.359C47.0831 151.359 45.8587 150.137 45.8587 148.629C45.8587 147.121 47.0831 145.898 48.5934 145.898C50.1038 145.898 51.3281 147.121 51.3281 148.629C51.3281 150.137 50.1038 151.359 48.5934 151.359Z" fill="white"/>
<path d="M81.5876 89.1126C80.3761 89.1126 79.394 88.1321 79.394 86.9225C79.394 85.713 80.3761 84.7324 81.5876 84.7324C82.7991 84.7324 83.7812 85.713 83.7812 86.9225C83.7812 88.1321 82.7991 89.1126 81.5876 89.1126Z" fill="#E1E4E5"/>
<path d="M213.351 61.7944C211.502 61.7944 210.003 60.2979 210.003 58.4519C210.003 56.6059 211.502 55.1094 213.351 55.1094C215.2 55.1094 216.699 56.6059 216.699 58.4519C216.699 60.2979 215.2 61.7944 213.351 61.7944Z" fill="#E1E4E5"/>
<path d="M70.3286 113.002C70.6416 114.168 69.9483 115.367 68.7801 115.679C67.6119 115.992 66.4111 115.3 66.098 114.133C65.785 112.967 66.4783 111.768 67.6465 111.456C68.8147 111.143 70.0155 111.835 70.3286 113.002Z" fill="white"/>
<path d="M113.45 63.9012C111.841 63.9012 110.537 62.599 110.537 60.9926C110.537 59.3862 111.841 58.084 113.45 58.084C115.059 58.084 116.363 59.3862 116.363 60.9926C116.363 62.599 115.059 63.9012 113.45 63.9012Z" fill="#E1E4E5"/>
<path d="M210.22 120.986C208.982 120.986 207.979 120.185 207.979 119.196C207.979 118.208 208.982 117.406 210.22 117.406C211.458 117.406 212.461 118.208 212.461 119.196C212.461 120.185 211.458 120.986 210.22 120.986Z" fill="white"/>
<path d="M246.415 151.77C244.605 152.652 242.572 152.21 241.874 150.783C241.177 149.356 242.079 147.484 243.89 146.603C245.7 145.721 247.733 146.163 248.431 147.59C249.128 149.017 248.225 150.888 246.415 151.77Z" fill="white"/>
<path d="M168.424 36.8904C169.03 39.1492 167.687 41.471 165.425 42.0763C163.162 42.6815 160.837 41.341 160.231 39.0822C159.624 36.8233 160.967 34.5015 163.229 33.8963C165.492 33.291 167.818 34.6315 168.424 36.8904Z" fill="#E1E4E5"/>
<path d="M251.087 100.108H251.146C251.493 105.019 255.151 105.094 255.151 105.094C255.151 105.094 251.117 105.173 251.117 110.847C251.117 105.173 247.083 105.094 247.083 105.094C247.083 105.094 250.74 105.019 251.087 100.108ZM123.348 44.7578H123.39C123.641 48.3511 126.279 48.4065 126.279 48.4065C126.279 48.4065 123.37 48.4639 123.37 52.6161C123.37 48.4639 120.461 48.4065 120.461 48.4065C120.461 48.4065 123.098 48.3511 123.348 44.7578Z" fill="#E1E4E5"/>
<path d="M188.292 96.043H120.48V118.988H188.292V96.043Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M146.868 118.988H119.062V95.3003L133.07 69.6992L146.868 95.2709V118.988Z" fill="white" stroke="#E1E4E5" stroke-width="2"/>
<path d="M156.593 100.266H150.16V113.077H156.593V100.266Z" fill="white"/>
<path d="M156.249 112.503V100.84H150.82V112.503H156.249Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M162.671 100.266H156.238V113.077H162.671V100.266Z" fill="white"/>
<path d="M162.015 112.503V100.84H156.586V112.503H162.015Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M133.761 100.266H127.328V113.077H133.761V100.266Z" fill="white"/>
<path d="M133.417 112.503V100.84H127.988V112.503H133.417Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M139.839 100.266H133.406V113.077H139.839V100.266Z" fill="white"/>
<path d="M139.183 112.503V100.84H133.754V112.503H139.183Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M133.703 93.4489H129.383V89.1661C129.383 86.7799 131.317 84.8457 133.703 84.8457V93.4489Z" fill="white"/>
<path d="M129.824 93.0648V88.8787C129.824 86.8649 131.457 85.2324 133.47 85.2324V93.0648H129.824Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M137.785 93.4489H133.465V84.8457C135.85 84.8457 137.785 86.7799 137.785 89.1661V93.4489Z" fill="white"/>
<path d="M133.699 93.0648V85.2324C135.713 85.2324 137.345 86.8649 137.345 88.8787V93.0648H133.699Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M179.39 100.266H172.957V113.077H179.39V100.266Z" fill="white"/>
<path d="M179.042 112.503V100.84H173.613V112.503H179.042Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M185.468 100.266H179.035V113.077H185.468V100.266Z" fill="white"/>
<path d="M184.808 112.502V100.838H179.379V112.502H184.808ZM190.41 96.2026H148.155L132.966 69.6992H175.22L190.41 96.2026ZM188.293 118.988H119.062V125.22H188.293V118.988ZM215.53 129.186H147.719V152.13H215.53V129.186Z" fill="white" stroke="#E1E4E5" stroke-width="2"/>
<path d="M174.106 152.131H146.301V128.443L160.309 102.842L174.106 128.414V152.131Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M183.831 133.408H177.398V146.22H183.831V133.408Z" fill="white"/>
<path d="M183.484 145.645V133.982H178.055V145.645H183.484Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M189.909 133.408H183.477V146.22H189.909V133.408Z" fill="white"/>
<path d="M189.253 145.646V133.982H183.824V145.646H189.253Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M161 133.408H154.566V146.22H161V133.408Z" fill="white"/>
<path d="M160.655 145.646V133.982H155.227V145.646H160.655Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M167.077 133.408H160.645V146.22H167.077V133.408Z" fill="white"/>
<path d="M166.421 145.646V133.982H160.992V145.646H166.421Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M160.941 126.592H156.621V122.309C156.621 119.922 158.555 117.988 160.941 117.988V126.592Z" fill="white"/>
<path d="M157.062 126.207V122.021C157.062 120.008 158.695 118.375 160.709 118.375V126.207H157.062Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M165.023 126.592H160.703V117.988C163.089 117.988 165.023 119.922 165.023 122.309V126.592Z" fill="white"/>
<path d="M160.938 126.207V118.375C162.951 118.375 164.584 120.008 164.584 122.021V126.207H160.938Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M206.628 133.408H200.195V146.22H206.628V133.408Z" fill="white"/>
<path d="M206.28 145.646V133.982H200.852V145.646H206.28Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M212.706 133.408H206.273V146.22H212.706V133.408Z" fill="white"/>
<path d="M212.048 145.644V133.981H206.619V145.644H212.048ZM217.651 129.346H175.396L160.206 102.842H202.461L217.651 129.346ZM215.533 152.131H146.303V158.363H215.533V152.131ZM150.843 123.804H83.0312V146.749H150.843V123.804Z" fill="white" stroke="#E1E4E5" stroke-width="2"/>
<path d="M109.419 146.75H81.6133V123.062L95.6208 97.4609L109.419 123.033V146.75Z" fill="white" stroke="#E1E4E5" stroke-width="2"/>
<path d="M119.14 128.025H112.707V140.837H119.14V128.025Z" fill="white"/>
<path d="M118.796 140.263V128.6H113.367V140.263H118.796Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M125.218 128.025H118.785V140.837H125.218V128.025Z" fill="white"/>
<path d="M124.561 140.263V128.6H119.133V140.263H124.561Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M96.3117 128.025H89.8789V140.837H96.3117V128.025Z" fill="white"/>
<path d="M95.9638 140.262V128.6H90.5352V140.262H95.9638Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M102.386 128.025H95.9531V140.837H102.386V128.025Z" fill="white"/>
<path d="M101.73 140.262V128.6H96.3008V140.262H101.73Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M96.2535 121.211H91.9336V116.928C91.9336 114.542 93.8678 112.607 96.2535 112.607V121.211Z" fill="white"/>
<path d="M92.375 120.825V116.638C92.375 114.625 94.0074 112.992 96.021 112.992V120.825H92.375Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M100.332 121.211H96.0117V112.607C98.3977 112.607 100.332 114.542 100.332 116.928V121.211Z" fill="white"/>
<path d="M96.2461 120.825V112.992C98.2597 112.992 99.8921 114.625 99.8921 116.638V120.825H96.2461Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M141.937 128.025H135.504V140.837H141.937V128.025Z" fill="white"/>
<path d="M141.589 140.263V128.6H136.16V140.263H141.589Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path d="M148.015 128.025H141.582V140.837H148.015V128.025Z" fill="white"/>
<path d="M147.355 140.263V128.6H141.926V140.263H147.355ZM152.958 123.964H110.702L95.5125 97.4609H137.767L152.958 123.964ZM150.84 146.75H81.6094V152.982H150.84V146.75Z" fill="white" stroke="#E1E4E5" stroke-width="2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M45.1719 165.499C45.1719 165.499 69.9946 141.056 104.873 140.713C139.909 140.368 167.783 164.662 170.35 165.917L45.1719 165.499Z" fill="white" stroke="#E1E4E5" stroke-width="2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M87.1367 165.499C87.1367 165.499 111.959 141.056 146.837 140.713C181.873 140.368 209.747 164.662 212.315 165.917L87.1367 165.499Z" fill="white" stroke="#E1E4E5" stroke-width="2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M137.99 165.499C137.99 165.499 162.813 141.056 197.69 140.712C232.727 140.368 260.601 164.662 263.169 165.917L137.99 165.499ZM63.4928 129.488H63.5055C63.8552 129.488 64.1389 129.772 64.1389 130.121V158.627H62.8594V130.121C62.8594 129.772 63.1431 129.488 63.4928 129.488Z" fill="white" stroke="#E1E4E5" stroke-width="2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M52.2852 142.835C52.2852 149.045 57.3278 154.08 63.548 154.08C69.7686 154.08 74.8109 149.045 74.8109 142.835C74.8109 136.625 69.7686 124.377 63.548 124.377C57.3278 124.377 52.2852 136.625 52.2852 142.835Z" fill="white"/>
<path d="M52.2852 142.835C52.2852 149.045 57.3278 154.08 63.548 154.08C69.7686 154.08 74.8109 149.045 74.8109 142.835C74.8109 136.625 69.7686 124.377 63.548 124.377C57.3278 124.377 52.2852 136.625 52.2852 142.835Z" stroke="#E1E4E5" stroke-width="2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M118.931 126.066H118.942C119.246 126.066 119.493 126.313 119.493 126.617V151.448H118.379V126.617C118.379 126.313 118.626 126.066 118.931 126.066Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M109.168 137.692C109.168 143.101 113.56 147.487 118.979 147.487C124.397 147.487 128.79 143.101 128.79 137.692C128.79 132.282 124.397 121.613 118.979 121.613C113.56 121.613 109.168 132.282 109.168 137.692Z" fill="white"/>
<path d="M109.168 137.692C109.168 143.101 113.56 147.487 118.979 147.487C124.397 147.487 128.79 143.101 128.79 137.692C128.79 132.282 124.397 121.613 118.979 121.613C113.56 121.613 109.168 132.282 109.168 137.692Z" stroke="#E1E4E5" stroke-width="4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M155.242 132.684H155.255C155.423 132.684 155.584 132.75 155.703 132.869C155.822 132.987 155.889 133.148 155.889 133.316V161.822H154.609V133.316C154.609 133.233 154.626 133.15 154.658 133.074C154.689 132.997 154.736 132.927 154.795 132.869C154.854 132.81 154.923 132.763 155 132.732C155.077 132.7 155.159 132.684 155.242 132.684Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M144.035 146.03C144.035 152.241 149.078 157.275 155.298 157.275C161.519 157.275 166.561 152.241 166.561 146.03C166.561 139.82 161.519 127.572 155.298 127.572C149.078 127.572 144.035 139.82 144.035 146.03Z" fill="white"/>
<path d="M144.035 146.03C144.035 152.241 149.078 157.275 155.298 157.275C161.519 157.275 166.561 152.241 166.561 146.03C166.561 139.82 161.519 127.572 155.298 127.572C149.078 127.572 144.035 139.82 144.035 146.03Z" stroke="#E1E4E5" stroke-width="4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M194.655 130.842H194.664C194.92 130.842 195.128 131.049 195.128 131.304V152.169H194.191V131.304C194.191 131.049 194.399 130.842 194.655 130.842Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M186.449 140.61C186.449 145.155 190.14 148.84 194.693 148.84C199.246 148.84 202.937 145.155 202.937 140.61C202.937 136.064 199.246 127.1 194.693 127.1C190.14 127.1 186.449 136.064 186.449 140.61Z" fill="white"/>
<path d="M186.449 140.61C186.449 145.155 190.14 148.84 194.693 148.84C199.246 148.84 202.937 145.155 202.937 140.61C202.937 136.064 199.246 127.1 194.693 127.1C190.14 127.1 186.449 136.064 186.449 140.61Z" stroke="#E1E4E5" stroke-width="4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M221.06 125.818H221.071C221.348 125.818 221.573 126.042 221.573 126.32V148.917H220.559V126.32C220.559 126.042 220.783 125.818 221.06 125.818Z" fill="white" stroke="#E1E4E5" stroke-width="4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M212.18 136.397C212.18 141.32 216.177 145.311 221.108 145.311C226.039 145.311 230.036 141.32 230.036 136.397C230.036 131.475 226.039 121.766 221.108 121.766C216.177 121.766 212.18 131.475 212.18 136.397Z" fill="white"/>
<path d="M212.18 136.397C212.18 141.32 216.177 145.311 221.108 145.311C226.039 145.311 230.036 141.32 230.036 136.397C230.036 131.475 226.039 121.766 221.108 121.766C216.177 121.766 212.18 131.475 212.18 136.397Z" stroke="#E1E4E5" stroke-width="4"/>
<g clip-path="url(#clip1_4260_89702)">
<path d="M127.848 145.032C128.535 142.246 135.03 138.178 138.501 140.631C139.566 135.324 140.563 132.015 144.044 129.4C147.525 126.786 153.332 130.203 154.265 137.36C155.198 134.418 159.94 130.453 163.75 133.796C167.275 136.89 167.629 142.075 167.26 145.027H127.848V145.032Z" fill="url(#paint5_linear_4260_89702)"/>
<path d="M137.518 148.697C136.97 146.479 131.794 143.24 129.029 145.193C128.18 140.967 127.385 138.331 124.612 136.249C121.838 134.168 117.21 136.889 116.467 142.588C115.723 140.245 111.944 137.088 108.908 139.75C106.099 142.214 105.818 146.343 106.111 148.694H137.518V148.697Z" fill="url(#paint6_linear_4260_89702)"/>
<path d="M201.898 145.032C201.34 142.771 196.063 139.468 193.243 141.459C192.378 137.15 191.568 134.464 188.74 132.341C185.912 130.219 181.194 132.993 180.436 138.804C179.678 136.415 175.825 133.196 172.73 135.91C169.867 138.422 169.579 142.632 169.879 145.028H201.898V145.032Z" fill="url(#paint7_linear_4260_89702)"/>
<path d="M135.228 150.307L133.657 185.168H105.066L108.023 171.019L117.551 168.013C117.798 167.926 118.023 167.786 118.21 167.603C118.397 167.42 118.541 167.198 118.633 166.953L124.33 152.329L135.228 150.307Z" fill="url(#paint8_linear_4260_89702)"/>
<path d="M132.081 153.977L131.367 166.553L121.049 167.52L125.494 154.436L132.081 153.977ZM106.767 171.056L109.093 170.689L107.738 175.475L106.54 175.347C106.412 175.334 106.295 175.271 106.212 175.172C106.13 175.073 106.089 174.947 106.099 174.818L106.352 171.505C106.361 171.394 106.407 171.29 106.482 171.209C106.557 171.127 106.658 171.074 106.767 171.056Z" fill="white"/>
<path d="M119.77 164.14C120.325 164.613 119.824 168.883 119.426 168.812C119.03 168.74 118.182 168.577 117.838 168.307C117.494 168.037 115.565 164.581 116.034 163.864C116.503 163.147 119.34 163.772 119.77 164.14Z" fill="url(#paint9_linear_4260_89702)"/>
<path opacity="0.1" d="M119.77 164.14C120.325 164.613 119.824 168.883 119.426 168.812C119.03 168.74 118.182 168.577 117.838 168.307C117.494 168.037 115.565 164.581 116.034 163.864C116.503 163.147 119.34 163.772 119.77 164.14Z" fill="black"/>
<path d="M134.449 185.167H193.229L189.735 142.336H136.595L134.449 185.167Z" fill="url(#paint10_linear_4260_89702)"/>
<path d="M193.525 187.051H103.754V184.381H193.525V187.051Z" fill="url(#paint11_linear_4260_89702)"/>
<path d="M110.747 187.363V183.738L101.965 183.199L101.328 187.363H110.747ZM195.232 183.248L189.753 183.814V187.363H195.896L195.232 183.248Z" fill="#DBDBDB" stroke="#E1E4E5" stroke-width="1.148"/>
<path d="M169.703 187.858C169.703 187.858 170.538 181.676 176.658 181.676C182.778 181.676 183.684 187.858 183.684 187.858" stroke="#E1E4E5" stroke-width="11.481" stroke-miterlimit="10"/>
<path d="M176.695 181.879C185.449 181.879 185.464 195.475 176.695 195.475C167.942 195.475 167.927 181.879 176.695 181.879Z" fill="#31446C"/>
<path d="M176.693 185.59C180.665 185.59 180.673 191.761 176.693 191.761C172.72 191.761 172.714 185.59 176.693 185.59Z" fill="#E1E4E5"/>
<path d="M117.27 187.858C117.27 187.858 118.105 181.676 124.224 181.676C130.344 181.676 131.25 187.858 131.25 187.858" stroke="#E1E4E5" stroke-width="11.481" stroke-miterlimit="10"/>
<path d="M124.227 181.879C132.98 181.879 132.995 195.475 124.227 195.475C115.474 195.475 115.459 181.879 124.227 181.879Z" fill="#31446C"/>
<path d="M124.229 185.59C128.201 185.59 128.208 191.761 124.229 191.761C120.255 191.761 120.249 185.59 124.229 185.59Z" fill="#E1E4E5"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_4260_89702" x1="174.934" y1="230.6" x2="107.52" y2="-74.0423" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#EEEEEE"/>
</linearGradient>
<linearGradient id="paint1_linear_4260_89702" x1="181.103" y1="113.245" x2="151.204" y2="-21.3251" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#EEEEEE"/>
</linearGradient>
<linearGradient id="paint2_linear_4260_89702" x1="199.486" y1="93.0226" x2="261.222" y2="63.8091" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#EEEEEE"/>
</linearGradient>
<linearGradient id="paint3_linear_4260_89702" x1="248.229" y1="135.453" x2="227.019" y2="144.229" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#EEEEEE"/>
</linearGradient>
<linearGradient id="paint4_linear_4260_89702" x1="72.392" y1="121.012" x2="49.1623" y2="84.5375" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#EEEEEE"/>
</linearGradient>
<linearGradient id="paint5_linear_4260_89702" x1="146.769" y1="154.286" x2="146.981" y2="110.564" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#EEEEEE"/>
</linearGradient>
<linearGradient id="paint6_linear_4260_89702" x1="122.44" y1="156.067" x2="122.271" y2="121.25" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#EEEEEE"/>
</linearGradient>
<linearGradient id="paint7_linear_4260_89702" x1="186.526" y1="152.546" x2="186.354" y2="117.049" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#EEEEEE"/>
</linearGradient>
<linearGradient id="paint8_linear_4260_89702" x1="120.147" y1="150.307" x2="136.915" y2="172.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#CC0A58"/>
<stop offset="1" stop-color="#F83893"/>
</linearGradient>
<linearGradient id="paint9_linear_4260_89702" x1="117.989" y1="163.535" x2="120.593" y2="166.597" gradientUnits="userSpaceOnUse">
<stop stop-color="#CC0A58"/>
<stop offset="1" stop-color="#F83893"/>
</linearGradient>
<linearGradient id="paint10_linear_4260_89702" x1="163.839" y1="142.336" x2="180.467" y2="177.247" gradientUnits="userSpaceOnUse">
<stop stop-color="#CC0A58"/>
<stop offset="1" stop-color="#F83893"/>
</linearGradient>
<linearGradient id="paint11_linear_4260_89702" x1="146.725" y1="188.548" x2="146.728" y2="181.475" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#EEEEEE"/>
</linearGradient>
<clipPath id="clip0_4260_89702">
<rect width="308" height="205" fill="white"/>
</clipPath>
<clipPath id="clip1_4260_89702">
<rect width="147.156" height="98.0583" fill="white" transform="translate(78 113)"/>
</clipPath>
</defs>
</svg>

)