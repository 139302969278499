import React, { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  RootState,
} from "../../../redux/store";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getAllSubHelpCenterList } from "../../../redux/slices/pages/help-center/sub";
import { AshArrowRightIcon } from "../../../asset/icon/helpCenterDashboard";
import LoadingSpinner from "../../loadSpinner";

function SubHelpCenterSection() {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>(""); // State untuk query pencarian
  const dispatch = useAppDispatch();
  const subHelpCenterState = useAppSelector(
    (state: RootState) => state.SubHelpCenterList.subHelpCenterList?.data || []
  );

  const location = useLocation();
  const { nama, id } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (typeof id === "string") {
        setLoading(true);
        await dispatch(getAllSubHelpCenterList(id));
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id]);

  const handleSpecific = (id: string, nama: string) => {
    navigate(`/help-center-supplier/specific-details/${id}`, {
      state: { nama, id },
    });
  };

  // Filter data berdasarkan query pencarian
  const filteredData = Array.isArray(subHelpCenterState)
    ? subHelpCenterState.filter((item) =>
        item.nama.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <div>
      <div className="text-[#FFFFFF] font-bold text-[32px]">
        <div>{nama}</div>
        <div className="text-lg font-normal">Temukan Kendalamu</div>
      </div>
      <div className="pt-8">
        <section
          className={`w-[1128px] bg-[#FFFFFF] rounded-[16px] mx-auto py-[24px] px-[16px] shadow-md`}
        >
          <div className="h-[50px] mb-14 mt-6 px-[26px] py-[13px] flex-1 mr-[20px] rounded-[100px] border border-zinc-200 items-center gap-2.5 inline-flex">
            <div className="relative w-full">
              <input
                placeholder={`Cari di ${nama}`}
                className="text-neutral-400 outline-none w-[754px] text-base font-normal font-Inter leading-normal p-2"
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
              />
            </div>
          </div>
          {loading ? ( 
            <div className="flex justify-center items-center">
              <LoadingSpinner width="36px" height="36px" />
            </div>
          ) : Array.isArray(filteredData) && filteredData.length > 0 ? ( 
            <div>
              {filteredData.map((item) => (
                <div
                  className="flex cursor-pointer justify-between items-center"
                  onClick={() => handleSpecific(item._id, item.nama)}
                  key={item._id} // Key untuk optimasi rendering React
                >
                  <div className="py-4 text-[#4A4D4F] text-base font-normal">
                    {item.nama}
                  </div>
                  <AshArrowRightIcon />
                </div>
              ))}
            </div>
          ) : (
            // Jika loading false dan filteredData bukan array atau kosong, tampilkan pesan
            <div className="text-center text-neutral-400 py-4">
              Tidak ada hasil yang ditemukan untuk "{searchQuery}".
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default SubHelpCenterSection;
