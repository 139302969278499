export const EmptyChatIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2166_149063)">
      <path
        d="M26.3333 0.5H5.66667C4.29638 0.5 2.98222 1.04434 2.01328 2.01328C1.04434 2.98222 0.5 4.29638 0.5 5.66667L0.5 21.1667C0.5 22.537 1.04434 23.8511 2.01328 24.8201C2.98222 25.789 4.29638 26.3333 5.66667 26.3333H9.4125L15.1617 31.1939C15.3949 31.3913 15.6906 31.4996 15.9961 31.4996C16.3017 31.4996 16.5973 31.3913 16.8305 31.1939L22.5875 26.3333H26.3333C27.7036 26.3333 29.0178 25.789 29.9867 24.8201C30.9557 23.8511 31.5 22.537 31.5 21.1667V5.66667C31.5 4.29638 30.9557 2.98222 29.9867 2.01328C29.0178 1.04434 27.7036 0.5 26.3333 0.5ZM28.9167 21.1667C28.9167 21.8518 28.6445 22.5089 28.16 22.9934C27.6756 23.4778 27.0185 23.75 26.3333 23.75H22.5875C21.9769 23.7502 21.3861 23.9666 20.92 24.361L16 28.5163L11.0826 24.361C10.6158 23.9661 10.024 23.7496 9.4125 23.75H5.66667C4.98152 23.75 4.32444 23.4778 3.83997 22.9934C3.3555 22.5089 3.08333 21.8518 3.08333 21.1667V5.66667C3.08333 4.98152 3.3555 4.32444 3.83997 3.83997C4.32444 3.35551 4.98152 3.08333 5.66667 3.08333H26.3333C27.0185 3.08333 27.6756 3.35551 28.16 3.83997C28.6445 4.32444 28.9167 4.98152 28.9167 5.66667V21.1667Z"
        fill="#D9D9D9"
      />
      <path
        d="M9.54167 9.54427H16C16.3426 9.54427 16.6711 9.40818 16.9133 9.16595C17.1556 8.92372 17.2917 8.59518 17.2917 8.2526C17.2917 7.91003 17.1556 7.58149 16.9133 7.33926C16.6711 7.09702 16.3426 6.96094 16 6.96094H9.54167C9.1991 6.96094 8.87056 7.09702 8.62832 7.33926C8.38609 7.58149 8.25 7.91003 8.25 8.2526C8.25 8.59518 8.38609 8.92372 8.62832 9.16595C8.87056 9.40818 9.1991 9.54427 9.54167 9.54427Z"
        fill="#D9D9D9"
      />
      <path
        d="M22.4583 12.125H9.54167C9.1991 12.125 8.87056 12.2611 8.62832 12.5033C8.38609 12.7456 8.25 13.0741 8.25 13.4167C8.25 13.7592 8.38609 14.0878 8.62832 14.33C8.87056 14.5722 9.1991 14.7083 9.54167 14.7083H22.4583C22.8009 14.7083 23.1294 14.5722 23.3717 14.33C23.6139 14.0878 23.75 13.7592 23.75 13.4167C23.75 13.0741 23.6139 12.7456 23.3717 12.5033C23.1294 12.2611 22.8009 12.125 22.4583 12.125Z"
        fill="#D9D9D9"
      />
      <path
        d="M22.4583 17.2891H9.54167C9.1991 17.2891 8.87056 17.4251 8.62832 17.6674C8.38609 17.9096 8.25 18.2382 8.25 18.5807C8.25 18.9233 8.38609 19.2518 8.62832 19.4941C8.87056 19.7363 9.1991 19.8724 9.54167 19.8724H22.4583C22.8009 19.8724 23.1294 19.7363 23.3717 19.4941C23.6139 19.2518 23.75 18.9233 23.75 18.5807C23.75 18.2382 23.6139 17.9096 23.3717 17.6674C23.1294 17.4251 22.8009 17.2891 22.4583 17.2891Z"
        fill="#D9D9D9"
      />
    </g>
    <defs>
      <clipPath id="clip0_2166_149063">
        <rect
          width="31"
          height="31"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const DotIcon = () => (
  <svg
    width="6"
    height="26"
    viewBox="0 0 6 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 0C1.344 0 0 1.344 0 3C0 4.656 1.344 6 3 6C4.656 6 6 4.656 6 3C6 1.344 4.656 0 3 0Z"
      fill="#818284"
    />
    <path
      d="M3 10C1.344 10 0 11.344 0 13C0 14.656 1.344 16 3 16C4.656 16 6 14.656 6 13C6 11.344 4.656 10 3 10Z"
      fill="#818284"
    />
    <path
      d="M3 20C1.344 20 0 21.344 0 23C0 24.656 1.344 26 3 26C4.656 26 6 24.656 6 23C6 21.344 4.656 20 3 20Z"
      fill="#818284"
    />
  </svg>
);
export const EmoteIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_894_142155)">
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.34591 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0ZM6.66667 6.66667C7.1087 6.66667 7.53262 6.84226 7.84518 7.15482C8.15774 7.46738 8.33334 7.89131 8.33334 8.33333C8.33334 9.16667 7.58751 9.16667 6.66667 9.16667C5.74584 9.16667 5 9.16667 5 8.33333C5 7.89131 5.1756 7.46738 5.48816 7.15482C5.80072 6.84226 6.22464 6.66667 6.66667 6.66667ZM14.7217 13.1217C13.3984 14.2521 11.7382 14.9126 10 15C8.26185 14.9126 6.60156 14.2521 5.27834 13.1217C5.11346 12.9745 5.01381 12.7678 5.00131 12.5471C4.99512 12.4379 5.01051 12.3285 5.0466 12.2252C5.0827 12.1218 5.13879 12.0266 5.21167 11.945C5.28455 11.8634 5.3728 11.7969 5.47138 11.7493C5.56996 11.7018 5.67693 11.6742 5.7862 11.668C6.00687 11.6555 6.22346 11.7311 6.38834 11.8783C7.40686 12.7351 8.67202 13.2448 10 13.3333C11.3288 13.2447 12.5946 12.7344 13.6133 11.8767C13.6951 11.8039 13.7904 11.7479 13.8937 11.712C13.9971 11.676 14.1066 11.6608 14.2158 11.6671C14.3251 11.6735 14.432 11.7013 14.5306 11.749C14.6291 11.7966 14.7172 11.8633 14.79 11.945C14.8628 12.0267 14.9187 12.122 14.9547 12.2254C14.9906 12.3288 15.0059 12.4382 14.9995 12.5475C14.9932 12.6568 14.9654 12.7637 14.9177 12.8622C14.87 12.9607 14.8034 13.0489 14.7217 13.1217ZM13.3333 9.16667C12.4125 9.16667 11.6667 9.16667 11.6667 8.33333C11.6667 7.89131 11.8423 7.46738 12.1548 7.15482C12.4674 6.84226 12.8913 6.66667 13.3333 6.66667C13.7754 6.66667 14.1993 6.84226 14.5118 7.15482C14.8244 7.46738 15 7.89131 15 8.33333C15 9.16667 14.2542 9.16667 13.3333 9.16667Z"
        fill="#B6CAE9"
      />
    </g>
    <defs>
      <clipPath id="clip0_894_142155">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const AddFileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99935 18.9596C5.05768 18.9596 1.04102 14.943 1.04102 10.0013C1.04102 5.05964 5.05768 1.04297 9.99935 1.04297C14.941 1.04297 18.9577 5.05964 18.9577 10.0013C18.9577 14.943 14.941 18.9596 9.99935 18.9596ZM9.99935 2.29297C5.74935 2.29297 2.29102 5.7513 2.29102 10.0013C2.29102 14.2513 5.74935 17.7096 9.99935 17.7096C14.2493 17.7096 17.7077 14.2513 17.7077 10.0013C17.7077 5.7513 14.2493 2.29297 9.99935 2.29297Z"
      fill="#B6CAE9"
    />
    <path
      d="M13.3327 10.625H6.66602C6.32435 10.625 6.04102 10.3417 6.04102 10C6.04102 9.65833 6.32435 9.375 6.66602 9.375H13.3327C13.6743 9.375 13.9577 9.65833 13.9577 10C13.9577 10.3417 13.6743 10.625 13.3327 10.625Z"
      fill="#B6CAE9"
    />
    <path
      d="M10 13.9596C9.65833 13.9596 9.375 13.6763 9.375 13.3346V6.66797C9.375 6.3263 9.65833 6.04297 10 6.04297C10.3417 6.04297 10.625 6.3263 10.625 6.66797V13.3346C10.625 13.6763 10.3417 13.9596 10 13.9596Z"
      fill="#B6CAE9"
    />
  </svg>
);
export const SendMessage = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#E94D20" />
    <path
      d="M14.6692 17.7059C14.2812 16.6437 14.5732 15.4674 15.4142 14.7103C16.2533 13.9561 17.4483 13.7861 18.4623 14.2792L36.5529 22.7249C37.523 23.179 38.217 24.0082 38.498 24.9984H18.5453L14.7262 17.8329C14.7052 17.7919 14.6862 17.7489 14.6692 17.7059ZM18.5593 26.9998L14.8062 34.1762C14.7852 34.2162 14.7672 34.2562 14.7522 34.2983C14.3652 35.3615 14.6602 36.5357 15.5012 37.2919C16.0223 37.759 16.6803 38 17.3423 38C17.7513 38 18.1613 37.908 18.5433 37.7209L36.5549 29.2812C37.528 28.8251 38.221 27.993 38.5 27.0008H18.5593V26.9998Z"
      fill="white"
    />
  </svg>
);
export const MoreIcon = () => (
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.531506 11.5856C0.20806 11.3269 0.155619 10.8549 0.414376 10.5315L4.03956 5.99997L0.414376 1.46849C0.155618 1.14505 0.208059 0.673077 0.531506 0.41432C0.854952 0.155562 1.32692 0.208004 1.58568 0.53145L5.58568 5.53145C5.80481 5.80536 5.80481 6.19458 5.58568 6.46849L1.58568 11.4685C1.32692 11.7919 0.854953 11.8444 0.531506 11.5856Z"
      fill="#9E9E9E"
    />
  </svg>
);
