import { PhoneIcon } from "../../../../../../asset/icon/profile/setting/security/deleteAccount";

interface phoneCardDevices {
    devicesName: string;
    loginAt: string;
    deviceId: string;
    isActive: boolean;
    openModal: (value: boolean) => void;
}

export default function PhoneCard ({ devicesName, loginAt, deviceId, isActive, openModal }: phoneCardDevices) {
    return (
        <div className="flex border-b-[0.5px] border-[#DDE5E9] w-full text-start gap-[10px] py-[10px]">
            <PhoneIcon />
            <div className="flex flex-col justify-center">
                <p className="font-semibold text-[14px] capitalize">{devicesName}</p>
                <span className="text-[#828282] text-[10px]">Login pada: waktu login</span>
                {isActive && (
                    <span className="bg-green-100 text-primaryColor rounded-full py-[2px] px-[8px] text-[12px]">
                        Perangkat saat ini
                    </span>
                )}
            </div>
            {!isActive && (
                <button onClick={() => openModal(true)} className="text-[#E52427] ml-auto">
                    Keluar
                </button>
            )}
        </div>
    );
};

