// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

const defaultData = {
    showModal: false,
    newEmail: '',
    newPhone: ''
}
interface dataChangeBioProps {
    showModal: boolean
    newEmail: string
    newPhone: string
}

interface changebioState {
    loading: boolean;
    error: string | null;
    dataChangeBio: dataChangeBioProps | null;
}

const initialState: changebioState = {
    loading: false,
    error: null,
    dataChangeBio: null,
};

const Changebio = createSlice({
    name: "change email or phone",
    initialState,
    reducers: {
      setShowModal: (state, action: PayloadAction<boolean>) => {
        if (!state.dataChangeBio) {
          state.dataChangeBio = { ...defaultData };
        }
        state.dataChangeBio.showModal = action.payload;
      },
      setNewPhoneUpdate: (state, action: PayloadAction<string>) => {
        if (!state.dataChangeBio) {
          state.dataChangeBio = { ...defaultData };
        }
        state.dataChangeBio.newPhone = action.payload;
      },
      setNewEmailUpdate: (state, action: PayloadAction<string>) => {
        if (!state.dataChangeBio) {
          state.dataChangeBio = { ...defaultData };
        }
        state.dataChangeBio.newEmail = action.payload;
      },
    },
  });

  export const {
    setShowModal,
    setNewPhoneUpdate,
    setNewEmailUpdate
  } = Changebio.actions

export default Changebio.reducer;
