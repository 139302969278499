export const ArrowBottomIcon = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.43113 3.51167L8.62006 0.322698C8.93574 0.0070133 9.44756 0.00702098 9.76324 0.322698C10.0789 0.638376 10.0789 1.15019 9.76324 1.46584L5.53889 5.69019C5.24128 5.98787 4.75871 5.98787 4.4611 5.69019L0.236752 1.46584C-0.0789176 1.1502 -0.0789176 0.638375 0.236752 0.322698C0.552423 0.0070202 1.06426 0.00702024 1.37993 0.322698L4.56887 3.51167C4.80695 3.74976 5.19304 3.74976 5.43113 3.51167Z"
      fill="#555770"
    />
  </svg>
);
export const ImageIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 28C16 28.7373 15.4027 29.3333 14.6667 29.3333H6.66667C2.99067 29.3333 0 26.3427 0 22.6667V6.66667C0 2.99067 2.99067 0 6.66667 0H22.6667C26.3427 0 29.3333 2.99067 29.3333 6.66667V14.6667C29.3333 15.404 28.736 16 28 16C27.264 16 26.6667 15.404 26.6667 14.6667V6.66667C26.6667 4.46133 24.872 2.66667 22.6667 2.66667H6.66667C4.46133 2.66667 2.66667 4.46133 2.66667 6.66667V15.9453L6.41067 12.2013C8.45333 10.1573 11.7773 10.1573 13.8213 12.2013L20.9427 19.3227C21.464 19.844 21.464 20.6867 20.9427 21.208C20.6827 21.468 20.3413 21.5987 20 21.5987C19.6587 21.5987 19.3173 21.468 19.0573 21.208L11.936 14.0867C10.9333 13.0853 9.30133 13.084 8.296 14.0867L2.66667 19.716V22.6667C2.66667 24.872 4.46133 26.6667 6.66667 26.6667H14.6667C15.4027 26.6667 16 27.2627 16 28ZM20 4.66667C22.2053 4.66667 24 6.46133 24 8.66667C24 10.872 22.2053 12.6667 20 12.6667C17.7947 12.6667 16 10.872 16 8.66667C16 6.46133 17.7947 4.66667 20 4.66667ZM20 7.33333C19.2653 7.33333 18.6667 7.93067 18.6667 8.66667C18.6667 9.40267 19.2653 10 20 10C20.7347 10 21.3333 9.40267 21.3333 8.66667C21.3333 7.93067 20.7347 7.33333 20 7.33333ZM30.6667 24H26.6667V20C26.6667 19.2627 26.0693 18.6667 25.3333 18.6667C24.5973 18.6667 24 19.2627 24 20V24H20C19.264 24 18.6667 24.596 18.6667 25.3333C18.6667 26.0707 19.264 26.6667 20 26.6667H24V30.6667C24 31.404 24.5973 32 25.3333 32C26.0693 32 26.6667 31.404 26.6667 30.6667V26.6667H30.6667C31.4027 26.6667 32 26.0707 32 25.3333C32 24.596 31.4027 24 30.6667 24Z"
      fill="#E94D20"
    />
  </svg>
);
export const ArrowRightIcon = () => (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.09654 0.928711L5.66797 5.50014L1.09654 10.0716"
      stroke="#696B6D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const SearchIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_170_22205)">
      <path
        d="M17.7803 16.7198L13.3036 12.2431C14.5235 10.751 15.1234 8.84715 14.979 6.92525C14.8346 5.00335 13.957 3.21048 12.5278 1.91747C11.0985 0.624463 9.22703 -0.0697502 7.30031 -0.0215763C5.3736 0.0265975 3.53911 0.813473 2.17629 2.17629C0.813473 3.53911 0.0265975 5.3736 -0.0215763 7.30031C-0.0697502 9.22703 0.624463 11.0985 1.91747 12.5278C3.21048 13.957 5.00335 14.8346 6.92525 14.979C8.84715 15.1234 10.751 14.5235 12.2431 13.3036L16.7198 17.7803C16.8613 17.917 17.0507 17.9925 17.2474 17.9908C17.444 17.9891 17.6321 17.9103 17.7712 17.7712C17.9103 17.6321 17.9891 17.444 17.9908 17.2474C17.9925 17.0507 17.917 16.8613 17.7803 16.7198ZM7.50008 13.5001C6.31339 13.5001 5.15335 13.1482 4.16666 12.4889C3.17996 11.8296 2.41093 10.8925 1.9568 9.79618C1.50268 8.69982 1.38386 7.49343 1.61537 6.32954C1.84688 5.16565 2.41832 4.09656 3.25744 3.25744C4.09656 2.41832 5.16565 1.84688 6.32954 1.61537C7.49343 1.38386 8.69982 1.50268 9.79618 1.9568C10.8925 2.41093 11.8296 3.17996 12.4889 4.16666C13.1482 5.15335 13.5001 6.31339 13.5001 7.50008C13.4983 9.09083 12.8656 10.6159 11.7407 11.7407C10.6159 12.8656 9.09083 13.4983 7.50008 13.5001Z"
        fill="#818284"
      />
    </g>
    <defs>
      <clipPath id="clip0_170_22205">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const StarIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0L17.1432 9.67376H27.3148L19.0858 15.6525L22.229 25.3262L14 19.3475L5.77101 25.3262L8.9142 15.6525L0.685208 9.67376H10.8568L14 0Z"
      fill="#FDD570"
    />
  </svg>
);
export const ChevronPratinjauIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#EEF9FF" />
    <path
      d="M18.0791 34.5C18.0795 34.1023 18.2377 33.7211 18.5191 33.44L26.1911 25.768C26.4233 25.5359 26.6075 25.2602 26.7332 24.9569C26.8589 24.6535 26.9236 24.3284 26.9236 24C26.9236 23.6717 26.8589 23.3465 26.7332 23.0432C26.6075 22.7398 26.4233 22.4642 26.1911 22.232L18.5291 14.565C18.2559 14.2821 18.1047 13.9032 18.1081 13.5099C18.1115 13.1166 18.2693 12.7404 18.5474 12.4623C18.8255 12.1842 19.2017 12.0264 19.595 12.023C19.9883 12.0196 20.3672 12.1708 20.6501 12.444L28.3121 20.105C29.3423 21.1373 29.9209 22.5361 29.9209 23.9945C29.9209 25.4529 29.3423 26.8518 28.3121 27.884L20.6401 35.556C20.4306 35.7656 20.1637 35.9085 19.8731 35.9666C19.5826 36.0247 19.2813 35.9954 19.0073 35.8824C18.7333 35.7695 18.499 35.5779 18.3338 35.3318C18.1686 35.0858 18.08 34.7964 18.0791 34.5Z"
      fill="#4A4D4F"
    />
  </svg>
);
