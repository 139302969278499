import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../api/axiosInstance';
import { notificatioProps } from '../../../../model/notification';

export const getNotification = createAsyncThunk(
  'notification/getNotification',
  async () => { 
    const response = await axiosInstance.get(`/notifikasi/get`); 
    return response.data;   
  }
);

// Redux slice
const Notification = createSlice({
    name: 'get notification',
    initialState: {
      notificationData: null as notificatioProps | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getNotification.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(getNotification.fulfilled, (state, action) => {
          state.loading = false;
          state.notificationData = action.payload;
        })
        .addCase(getNotification.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default Notification.reducer;
