// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import { DetailProdusenProps } from "../../../model/auth/detailProdusen";

interface dataDetail {
  loading: boolean;
  error: string | null;
  produsenDetail: DetailProdusenProps | null;
}

const initialState: dataDetail = {
  loading: false,
  error: null,
  produsenDetail: null,
};

export const getDetailProdusen = createAsyncThunk(
  "auth/DetailProdusen",
  async (_, { rejectWithValue }) => {
    try {
      const detailResponse = await axiosInstance.get(`/supplier/detail`);
      return detailResponse.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDetailProdusen.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(getDetailProdusen.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.produsenDetail = action.payload; // Simpan data yang berhasil di-fetch
      })
      .addCase(getDetailProdusen.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default authSlice.reducer;