import React, { useEffect } from "react";
import NotificationList from "./list";
import Emptynotification from "./empty";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { getNotification } from "../../redux/slices/pages/notification";
import NotificationListSkeleton from "./list/skeleton";

export default function NotificationSection() {
  const dispatch = useAppDispatch();
  const { loading, notificationData } = useAppSelector(
    (state: RootState) => state.Notification
  );
  useEffect(() => {
    dispatch(getNotification());
  }, [dispatch]);
  return (
    <>
      {notificationData?.total === 0 ? (
        <Emptynotification />
      ) : loading ? (
        <NotificationListSkeleton />
      ) : (
        <NotificationList />
      )}
    </>
  );
}
