export const ChevronBackIcon = () => (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.293633 8.21854C0.106162 8.03101 0.000846782 7.7767 0.000846759 7.51154C0.000846736 7.24637 0.106162 6.99207 0.293633 6.80454L5.95063 1.14754C6.04288 1.05203 6.15322 0.975845 6.27523 0.923436C6.39723 0.871027 6.52845 0.843442 6.66123 0.842288C6.79401 0.841134 6.92569 0.866436 7.04859 0.916717C7.17148 0.966997 7.28314 1.04125 7.37703 1.13514C7.47092 1.22904 7.54517 1.34069 7.59545 1.46358C7.64574 1.58648 7.67104 1.71816 7.66988 1.85094C7.66873 1.98372 7.64114 2.11494 7.58873 2.23694C7.53632 2.35895 7.46014 2.46929 7.36463 2.56154L2.41463 7.51154L7.36463 12.4615C7.54679 12.6501 7.64759 12.9027 7.64531 13.1649C7.64303 13.4271 7.53786 13.6779 7.35245 13.8634C7.16704 14.0488 6.91623 14.1539 6.65403 14.1562C6.39184 14.1585 6.13924 14.0577 5.95063 13.8755L0.293633 8.21854Z"
            fill="#25282B"
        />
    </svg>
)
export const TriangleWarningIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.2365 12.7752L12.5032 2.14183C11.9365 1.32516 10.9949 0.833496 10.0032 0.833496C9.01155 0.833496 8.06988 1.31683 7.47821 2.16683L0.778214 12.7585C-0.0717865 13.9752 -0.23012 15.4335 0.361547 16.5585C0.94488 17.6835 2.16988 18.3252 3.70321 18.3252H16.3032C17.8449 18.3252 19.0615 17.6835 19.6449 16.5585C20.2282 15.4335 20.0699 13.9835 19.2365 12.7752ZM9.16988 5.8335C9.16988 5.37516 9.54488 5.00016 10.0032 5.00016C10.4615 5.00016 10.8365 5.37516 10.8365 5.8335V10.8335C10.8365 11.2918 10.4615 11.6668 10.0032 11.6668C9.54488 11.6668 9.16988 11.2918 9.16988 10.8335V5.8335ZM10.0032 15.8335C9.31155 15.8335 8.75321 15.2752 8.75321 14.5835C8.75321 13.8918 9.31155 13.3335 10.0032 13.3335C10.6949 13.3335 11.2532 13.8918 11.2532 14.5835C11.2532 15.2752 10.6949 15.8335 10.0032 15.8335Z"
            fill="#1069E5"
        />
    </svg>
);
export const BlockIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1339_49027)">
            <path
                d="M16.4895 2.73204C12.9499 -0.868469 7.16165 -0.917844 3.56114 2.62177C-0.0393676 6.16138 -0.0887426 11.9496 3.45087 15.5501C3.49485 15.5949 3.5393 15.6392 3.58423 15.683L6.86126 18.7107C8.61317 20.4245 11.4113 20.4316 13.1718 18.7265L16.4895 15.6606C20.0595 12.0904 20.0595 6.3022 16.4895 2.73204ZM13.1061 11.102C13.4363 11.4209 13.4454 11.947 13.1265 12.2772C12.8076 12.6073 12.2815 12.6165 11.9513 12.2975C11.9444 12.2909 11.9376 12.2841 11.9309 12.2772L10.0252 10.3715L8.1195 12.2772C7.78934 12.5961 7.26321 12.587 6.94434 12.2568C6.63325 11.9347 6.63325 11.4241 6.94434 11.102L8.85005 9.19634L6.94434 7.29063C6.61419 6.97177 6.60505 6.44563 6.92391 6.11548C7.24278 5.78532 7.76891 5.77618 8.09907 6.09505C8.10598 6.10173 8.11282 6.10856 8.1195 6.11548L10.0252 8.02118L11.9309 6.11548C12.2498 5.78532 12.7759 5.77618 13.1061 6.09509C13.4362 6.41399 13.4454 6.94012 13.1265 7.27024C13.1198 7.27716 13.113 7.28395 13.1061 7.29063L11.2004 9.19634L13.1061 11.102Z"
                fill="#E52427"
            />
        </g>
        <defs>
            <clipPath id="clip0_1339_49027">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export const TriangleRedIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.2365 12.7752L12.5032 2.14183C11.9365 1.32516 10.9949 0.833496 10.0032 0.833496C9.01155 0.833496 8.06988 1.31683 7.47821 2.16683L0.778214 12.7585C-0.0717865 13.9752 -0.23012 15.4335 0.361547 16.5585C0.94488 17.6835 2.16988 18.3252 3.70321 18.3252H16.3032C17.8449 18.3252 19.0615 17.6835 19.6449 16.5585C20.2282 15.4335 20.0699 13.9835 19.2365 12.7752ZM9.16988 5.8335C9.16988 5.37516 9.54488 5.00016 10.0032 5.00016C10.4615 5.00016 10.8365 5.37516 10.8365 5.8335V10.8335C10.8365 11.2918 10.4615 11.6668 10.0032 11.6668C9.54488 11.6668 9.16988 11.2918 9.16988 10.8335V5.8335ZM10.0032 15.8335C9.31155 15.8335 8.75321 15.2752 8.75321 14.5835C8.75321 13.8918 9.31155 13.3335 10.0032 13.3335C10.6949 13.3335 11.2532 13.8918 11.2532 14.5835C11.2532 15.2752 10.6949 15.8335 10.0032 15.8335Z"
            fill="#B71D1F"
        />
    </svg>
);