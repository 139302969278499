import { useState } from "react";

const MAX_IMAGES_UPLOAD = 1;
const MAX_FILE_SIZE = 1 * 1024 * 1024;
const MAX_VIDEO_DURATION = 60;

export const useFileUpload = (
  initialDataFiles: File[] = [],
  initialDataPreviews: string[] = []
) => {
  const [dataFiles, setDataFiles] = useState<File[]>(initialDataFiles);
  const [dataPreviews, setDataPreviews] =
    useState<string[]>(initialDataPreviews);
  const [imageCount, setImageCount] = useState<number>(0);
  const [videoCount, setVideoCount] = useState<number>(0);
  const [isShowErr, setIsShowErr] = useState<boolean>(false);
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const [errorImage, setErrorImage] = useState<string>("");
  const [errorVideos, setErrorVideos] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    const newFiles: File[] = [];
    const newPreviews: string[] = [];
    let newImageCount = imageCount;
    let newVideoCount = videoCount;

    Array.from(files).forEach((file) => {
      const modifiedFileName = file.name.replace(/ /g, "_");
      const modifiedFile = new File([file], modifiedFileName, {
        type: file.type,
      });

      if (
        modifiedFile.type.startsWith("image") &&
        newImageCount < MAX_IMAGES_UPLOAD
      ) {
        if (modifiedFile.size > MAX_FILE_SIZE) {
          setErrorImage(
            `File ${modifiedFile.name} melebihi ukuran maksimum 1MB.`
          );
          setIsShowErr(true);
          setIsShowing(false);
        } else {
          newFiles.push(modifiedFile);
          newPreviews.push(URL.createObjectURL(modifiedFile));
          newImageCount += 1;
          setIsShowErr(false);
        }
      } else if (modifiedFile.type.startsWith("video") && newVideoCount < 1) {
        const videoElement = document.createElement("video");
        videoElement.src = URL.createObjectURL(modifiedFile);

        videoElement.onloadedmetadata = () => {
          if (videoElement.duration <= MAX_VIDEO_DURATION) {
            newFiles.push(modifiedFile);
            newPreviews.push(URL.createObjectURL(modifiedFile));
            newVideoCount += 1;
            setDataFiles([...dataFiles, ...newFiles]);
            setDataPreviews([...dataPreviews, ...newPreviews]);
            setImageCount(newImageCount);
            setVideoCount(newVideoCount);
            setIsShowing(false);
          } else {
            setIsShowing(true);
            setIsShowErr(false);
            setErrorVideos("Durasi video melebihi batas maksimal 60 detik");
          }
        };
      }
    });

    setDataFiles([...dataFiles, ...newFiles]);
    setDataPreviews([...dataPreviews, ...newPreviews]);
    setImageCount(newImageCount);
    setVideoCount(newVideoCount);
  };

  const handleRemoveFile = (index: number) => {
    const fileToRemove = dataFiles[index];
    const newFiles = dataFiles.filter((_, i) => i !== index);
    const newPreviews = dataPreviews.filter(
      (_: string, i: number) => i !== index
    );
    if (fileToRemove.type.startsWith("image")) {
      setImageCount(imageCount - 1);
    } else if (fileToRemove.type.startsWith("video")) {
      setVideoCount(videoCount - 1);
    }
    setDataFiles(newFiles);
    setDataPreviews(newPreviews);
  };

  return {
    dataFiles,
    dataPreviews,
    imageCount,
    videoCount,
    isShowErr,
    isShowing,
    errorImage,
    errorVideos,
    handleFileChange,
    handleRemoveFile,
    setDataPreviews,
    setDataFiles,
  };
};
