import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { detailProductState } from '../../../model/catalog/productList';
import { FormattedDate } from '../../../lib';
import { ChevronBackIcon } from '../../../asset/icon/catalog/detail';
import PendingReviewAlert from './alert/pendingReview';
import BlockAlert from './alert/block';
import RejectAlert from './alert/rejected';
import ProductComponent from './productComponent';
import ProductDetail from './productDetail';

export default function DetailProduct() {
    const location = useLocation();
    const navigate = useNavigate();
    const dataProduct: detailProductState = location.state.data

    useEffect(() => {
        if (!location.state) {
            navigate("/catalog");
        }
    }, [location.state, navigate]);
    return (
        <main className="grid grid-row-1 gap-4 p-[24px] rounded-lg shadow-custom">
            {/* {isShowing && <ModalDetailInfoGizi dataValues={productsData} setIsShowing={setIsShowing} />}
    {isOpenPublish && <AlertSucces selectedName={productsData?.name_product || '-'} wording={'berhasil dipublish'}/>} */}
            {/* {isOpen && <AlertNotArchived/>} */}
            {/* {isOpenPreview && (
        <ModalPreviewProduct
            handleShowCloseProduct={onCloseModalPreview}
            handleSubmitProductBervarian={function (): void {
                throw new Error('Function not implemented.');
            }}
            productDatas={productsData}
            loadingUpload={false}
        />
    )} */}
            <section className="flex justify-between items-center flex-col w-full">
                <div className="w-full flex justify-start">
                    <div
                        onClick={() => navigate(-1)}
                        className="w-[86px] flex justify-start items-center cursor-pointer">
                        <ChevronBackIcon />
                        <p className="font-inter font-normal text-base text-[#25282B] capitalize ml-2">kembali</p>
                    </div>
                </div>
                <div className="w-full flex justify-between items-center mt-2">
                    <p className="font-inter font-semibold text-base text-[#222831] capitalize">status kendaraan:</p>
                    <div className="flex justify-center items-center">
                        <div className="w-[82px] h-[20px] rounded-lg py-1 px-2" >
                            <p className={`font-inter font-medium text-xs text-center`}>
                                {dataProduct?.status?.value || '-'}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {dataProduct?.status?.value === 'ditinjau' && <PendingReviewAlert />}
            {dataProduct?.status?.value === 'diblokir' && <BlockAlert />}
            {dataProduct?.status?.value === 'ditolak' && <RejectAlert />}
            <section className="flex justify-between items-center">
                <div className="flex-col">
                    <p className="font-inter font-medium text-base text-[#4A4D4F] capitalize">waktu unggah</p>
                    <p className="font-inter font-normal text-sm text-[#969899] capitalize">
                        {FormattedDate(dataProduct?.createdAt || '-')}
                    </p>
                </div>
                <div className="flex flex-col items-end">
                    <p className="font-inter font-medium text-base text-[#4A4D4F] capitalize">pembayaran downpayment</p>
                    <p className="font-inter font-normal text-sm text-[#969899] capitalize">
                        {`${dataProduct?.minimalDp || 0} %`}
                    </p>
                </div>
            </section>
            {/* <---CONTENTS----> */}
            <ProductComponent productsData={dataProduct} />
            <ProductDetail productData={dataProduct}  />
            <p className="font-inter font-semibold text-lg text-[#4A4D4F] capitalize">deskripsi produk</p>
            <p className="font-inter font-normal text-sm text-[#696B6D] text-justify">
                {dataProduct?.description || '-'}
                {dataProduct?.long_description || '-'}
            </p>
            {/* <NutritionInfo productsData={productsData} setIsShowing={setIsShowing} /> */}
            {/* <---BUTTONS----> */}
            {/* <ProductActions
        setIsOpenPreview={setIsOpenPreview}
        productsData={productsData}
        setGetIdPublish={setGetIdPublish}
        setGetIdArchived={setGetIdArchived}
        loadingArchived={loadingArchived}
        isDisabledEditBtn={isDisabledEditBtn}
        handleToFormEdit={() => handleToFormEdit(productsData)}
    /> */}
        </main>
    )
}
