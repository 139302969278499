// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ProductListProps } from "../../../../../../model/catalog/productList";
import axiosInstance from "../../../../../../api/axiosInstance";

interface productListState {
  loading: boolean; 
  error: string | null;
  productList: ProductListProps | null; 
}

const initialState: productListState = {
  loading: false,
  error: null,
  productList: null,
};
const limit = 5

// AsyncThunk untuk mengambil detail produsen
export const getAllProductList = createAsyncThunk(
  "get all product list / catalog",
  async (page: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/product/list_all?status=&limit=${limit}&page=${page}`);
      return response.data; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Terjadi kesalahan");
    }
  }
);

const catalog = createSlice({
  name: "age list catalog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProductList.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error saat memulai request baru
      })
      .addCase(getAllProductList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.productList = action.payload; // Simpan data yang berhasil di-fetch
      })
      .addCase(getAllProductList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload; // Simpan pesan error jika request gagal
      });
  },
});

export default catalog.reducer;
