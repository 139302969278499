import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ageListState } from "../../../../model/catalog/ageList";
import { defaultDataInput } from "./data";

interface spesificationState {
  longProduct: number
  widthProduct: number
  heightProduct: number
  weightProduct: number
}
// interface dataInputVarianProps {
//   productName: string;
//   rangeAge: ageListState;
//   shortDesc: string;
//   longDesc: string;
//   sepecification: spesificationState
// }
interface categoryState{
  mainCategory: string;
  secondCategory: string;
  thirdCategory: string;
  categoryId: string;
}
interface pricingDetailState {
  diskon: number;
  minOrder: number;
  stok: number
}
interface dataInputNonVarianProps {
  productCover: File[]
  productName: string;
  productPrice: number;
  pricingDetail: pricingDetailState
  rangeAge: ageListState;
  shortDesc: string;
  longDesc: string;
  category: categoryState
  sepecification: spesificationState
}

interface catalogAddStatus {
  isVarian: boolean;
  confirmSetModal: boolean
  confirmUploadProduct: boolean
  // dataInputVarian: null | dataInputVarianProps;
  dataInputNonVarian: null | dataInputNonVarianProps;
}

// Default value untuk kedua state


const initialState: catalogAddStatus = {
  isVarian: true,
  confirmSetModal: false,
  confirmUploadProduct: false,
  // dataInputVarian: null,
  dataInputNonVarian: null,
};

const statusTypeCatalogAdd = createSlice({
  name: "data Input add Product",
  initialState,
  reducers: {
    setConfirmUploadProductModal: (state, action: PayloadAction<boolean>) => {
      state.confirmUploadProduct = action.payload;
    },
    setIsVarianStatus: (state, action: PayloadAction<boolean>) => {
      state.isVarian = action.payload;
    },
    setConfirmSetModal: (state, action: PayloadAction<boolean>) => {
      state.confirmSetModal = action.payload;
    },
    resetDataInputCatalog: (state) => {
      state.dataInputNonVarian = null;
    },
    // setAgeRangeVarian: (state, action:PayloadAction<ageListState>) => {
    //   if (!state.dataInputVarian) {
    //     state.dataInputVarian = { ...defaultDataInput };
    //   }
    //   state.dataInputVarian.rangeAge = action.payload;
    // },
    setAgeRangeUnVarian: (state, action: PayloadAction<ageListState>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.rangeAge = action.payload;
    },
    // setVarianProductName: (state, action: PayloadAction<string>) => {
    //   if (!state.dataInputVarian) {
    //     state.dataInputVarian = { ...defaultDataInput };
    //   }
    //   state.dataInputVarian.productName = action.payload;
    // },
    setNonVarianProductName: (state, action: PayloadAction<string>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.productName = action.payload;
    },
    // setShortDescVarian: (state, action: PayloadAction<string>) => {
    //   if (!state.dataInputVarian) {
    //     state.dataInputVarian = { ...defaultDataInput };
    //   }
    //   state.dataInputVarian.shortDesc = action.payload;
    // },
    setShortDescUnVarian: (state, action: PayloadAction<string>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.shortDesc = action.payload;
    },
    // setLongDescVarian: (state, action: PayloadAction<string>) => {
    //   if (!state.dataInputVarian) {
    //     state.dataInputVarian = { ...defaultDataInput };
    //   }
    //   state.dataInputVarian.longDesc = action.payload;
    // },
    setLongDescUnVarian: (state, action: PayloadAction<string>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.longDesc = action.payload;
    },
    addProductCover: (state, action: PayloadAction<File[]>) => {
      if (!state.dataInputNonVarian) {   
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.productCover = action.payload;
    },
    setMainCategory: (state, action: PayloadAction<string>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.category.mainCategory = action.payload;
    },
    setSecondCategory: (state, action: PayloadAction<string>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.category.secondCategory = action.payload;
    },
    setThirdCategory: (state, action: PayloadAction<string>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.category.thirdCategory = action.payload;
    },
    setCategoryId: (state, action: PayloadAction<string>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.category.categoryId = action.payload;
    },
    setNonVarianPrice: (state, action: PayloadAction<number>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.productPrice = action.payload;
    },
    setDiskon: (state, action: PayloadAction<number>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.pricingDetail.diskon = action.payload;
    },
    setMinOrder: (state, action: PayloadAction<number>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.pricingDetail.minOrder = action.payload;
    },
    setStok: (state, action: PayloadAction<number>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.pricingDetail.stok = action.payload;
    },
    setLongProduct: (state, action: PayloadAction<number>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.sepecification.longProduct = action.payload;
    },
    setWidthProduct: (state, action: PayloadAction<number>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.sepecification.widthProduct = action.payload;
    },
    setHeightProduct: (state, action: PayloadAction<number>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.sepecification.heightProduct = action.payload;
    },
    setWeightProduct: (state, action: PayloadAction<number>) => {
      if (!state.dataInputNonVarian) {
        state.dataInputNonVarian = { ...defaultDataInput };
      }
      state.dataInputNonVarian.sepecification.weightProduct = action.payload;
    },
  },
});

export const {
  setConfirmUploadProductModal,
  setIsVarianStatus,
  setConfirmSetModal,
  resetDataInputCatalog,
  // setAgeRangeVarian,
  setAgeRangeUnVarian,
  // setVarianProductName,
  setNonVarianProductName,
  // setShortDescVarian,
  setShortDescUnVarian,
  // setLongDescVarian,
  setLongDescUnVarian,
  addProductCover,
  setMainCategory,
  setSecondCategory,
  setThirdCategory,
  setCategoryId,
  setNonVarianPrice,
  setDiskon,
  setMinOrder,
  setStok,
  setLongProduct,
  setWidthProduct,
  setHeightProduct,
  setWeightProduct
} = statusTypeCatalogAdd.actions;
export default statusTypeCatalogAdd.reducer;
