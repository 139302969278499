export const PhoneIcon = () => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0992 1.3999H15.8992C14.7853 1.3999 13.717 1.8424 12.9294 2.63005C12.1417 3.41771 11.6992 4.48599 11.6992 5.5999V50.3999C11.6992 51.5138 12.1417 52.5821 12.9294 53.3697C13.717 54.1574 14.7853 54.5999 15.8992 54.5999H41.0992C42.2131 54.5999 43.2814 54.1574 44.0691 53.3697C44.8567 52.5821 45.2992 51.5138 45.2992 50.3999V5.5999C45.2992 4.48599 44.8567 3.41771 44.0691 2.63005C43.2814 1.8424 42.2131 1.3999 41.0992 1.3999ZM14.4992 5.5999C14.4992 5.2286 14.6467 4.8725 14.9093 4.60995C15.1718 4.3474 15.5279 4.1999 15.8992 4.1999H41.0992C41.4705 4.1999 41.8266 4.3474 42.0892 4.60995C42.3517 4.8725 42.4992 5.2286 42.4992 5.5999V50.3999C42.4992 50.7712 42.3517 51.1273 42.0892 51.3899C41.8266 51.6524 41.4705 51.7999 41.0992 51.7999H15.8992C15.5279 51.7999 15.1718 51.6524 14.9093 51.3899C14.6467 51.1273 14.4992 50.7712 14.4992 50.3999V5.5999Z"
      fill="#CC0A58"
    />
    <path
      d="M28.4992 50.3998C27.7566 50.3998 27.0444 50.1048 26.5193 49.5797C25.9942 49.0546 25.6992 48.3424 25.6992 47.5998C25.6992 46.8572 25.9942 46.145 26.5193 45.6199C27.0444 45.0948 27.7566 44.7998 28.4992 44.7998C29.2418 44.7998 29.954 45.0948 30.4791 45.6199C31.0042 46.145 31.2992 46.8572 31.2992 47.5998C31.2992 48.3424 31.0042 49.0546 30.4791 49.5797C29.954 50.1048 29.2418 50.3998 28.4992 50.3998Z"
      fill="#CC0A58"
    />
  </svg>
);

export const LaptopIcon = () => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.00262 43.797C3.67129 43.797 3.39362 43.685 3.16962 43.461C2.94562 43.237 2.8344 42.9593 2.83595 42.628C2.83751 42.2967 2.94873 42.0198 3.16962 41.7973C3.39051 41.5749 3.66818 41.4629 4.00262 41.4613H11.2733V39.667C10.1984 39.667 9.30162 39.3077 8.58295 38.589C7.86429 37.8703 7.50418 36.9735 7.50262 35.8987V15.4353C7.50262 14.362 7.86273 13.466 8.58295 12.7473C9.30318 12.0287 10.1992 11.6685 11.271 11.667H45.7343C46.8076 11.667 47.7036 12.0271 48.4223 12.7473C49.141 13.4675 49.5011 14.3635 49.5026 15.4353V35.8987C49.5026 36.972 49.1425 37.8688 48.4223 38.589C47.7021 39.3092 46.8061 39.6685 45.7343 39.667V41.4637H53.0026C53.3355 41.4637 53.6132 41.5749 53.8356 41.7973C54.0581 42.0198 54.1693 42.2967 54.1693 42.628C54.1693 42.9593 54.0581 43.237 53.8356 43.461C53.6132 43.685 53.3355 43.7962 53.0026 43.7947L4.00262 43.797ZM11.2733 37.3337H45.7343C46.1527 37.3337 46.4965 37.1991 46.7656 36.93C47.0347 36.6609 47.1693 36.3171 47.1693 35.8987V15.4353C47.1693 15.0169 47.0347 14.6731 46.7656 14.404C46.4965 14.1349 46.1527 14.0003 45.7343 14.0003H11.271C10.8525 14.0003 10.5087 14.1349 10.2396 14.404C9.97051 14.6731 9.83595 15.0169 9.83595 15.4353V35.8987C9.83595 36.3171 9.97051 36.6609 10.2396 36.93C10.5087 37.1991 10.8533 37.3337 11.2733 37.3337Z"
      fill="#CC0A58"
    />
  </svg>
);

export const ArrowLeftIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 19.5625L8.5 12.5625L15.5 5.5625"
      stroke="#545F71"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
