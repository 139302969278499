import React from "react";
import { ImageIcon } from "../../../../../../asset/icon/catalog/add";

interface inputCoverProductProps {
  value?: File[]; // Data gambar/video
  handleAddProductCover: (productCover: File[]) => void; 
}

export default function PictureUpload({
  value = [],
  handleAddProductCover,
}: inputCoverProductProps) {
  console.log(value)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      // Validasi format file
      const validFiles = filesArray.filter((file) =>
        ["image/png", "image/jpeg", "video/mp4"].includes(file.type)
      );
      if (validFiles.length > 0) {
        handleAddProductCover([...value, ...validFiles]);
      }
    }
  };

  const removeImage = (index: number) => {
    const updatedValue = value.filter((_, i) => i !== index);
    handleAddProductCover(updatedValue); // Kirim array yang sudah diperbarui ke Redux
  };

  return (
    <>
      <p className="text-[16px] mb-[8px] font-inter font-semibold">
        Unggah Foto Produk <span className="text-[red]">*</span>
      </p>
      <div className={`w-full py-4 border border-dashed border-primaryColor rounded-lg h-[160px] ${value.length >= 5 ? 'cursor-not-allowed': ''}`}>
        <div className={`flex items-center justify-center ${value.length >= 5 ? 'cursor-not-allowed': ''}`}>
          <label
            className={`flex flex-col items-center justify-center rounded-lg w-[409px] h-[118px] ${value.length >= 5 ? 'cursor-not-allowed': 'cursor-pointer '}`}
          >
            <div className={`flex flex-col items-center justify-center pt-5 pb-6`}>
              <ImageIcon />
              <p className="mb-2 text-sm text-gray-500">Unggah Media</p>
              <p className="text-xs text-gray-500">
                Format: PNG, JPG, MP4
              </p>
            </div>
            <input
              type="file"
              accept="image/png,image/jpeg,video/mp4"
              multiple
              className="hidden"
              disabled={value.length >= 5}
              onChange={handleFileChange}
            />
          </label>
        </div>
      </div>
      {value.length > 0 ? (
        <div className="flex flex-wrap gap-4 mt-4">
          {value.map((item, index) => (
            <div
              key={index}
              className="relative w-24 h-24 border border-gray-300 rounded overflow-hidden"
            >
              <img
                src={URL.createObjectURL(item)}
                alt={`Uploaded Preview ${index}`}
                className="w-full h-full object-cover"
              />
              <button
                onClick={() => removeImage(index)}
                className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs font-bold hover:bg-red-600"
              >
                ×
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-sm text-gray-500 mt-4">Belum ada media yang diunggah.</p>
      )}
    </>
  );
}
