export const FormatChatDateTime = (dateString: string): string => {
  // Konversi string tanggal ke objek Date
  const date = new Date(dateString);
  const now = new Date();

  // Ambil tanggal, bulan, tahun, jam, dan menit
  const day = date.getDate();
  const month = date.getMonth() + 1; // getMonth() dimulai dari 0
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format jam dan menit (misal: 07:31)
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  // Bandingkan tanggal dengan hari ini
  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    // Jika tanggal sama dengan hari ini, kembalikan jam dan menit
    return formattedTime;
  } else {
    // Jika tanggal berbeda, kembalikan tanggal dan jam
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;
    return `${formattedDate} ${formattedTime}`;
  }
};

export const GetFormatedMessageValue = (jsonString: string): string | null => {
  try {
    const parsedObject = JSON.parse(jsonString);
    return parsedObject.msg || null;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
};
export function parseStringToJson(input: string) {
  try {
    const jsonObject = JSON.parse(input);
    return jsonObject;
  } catch (error) {
    console.error("Invalid JSON string", error);
    return null;
  }
}
