import React from "react";
import { useAppDispatch } from "../../../../../redux/store";
import { setShowModalChat } from "../../../../../redux/slices/modal/chat";

export default function CSChat() {
  const dispatch = useAppDispatch();
  const hadleOpenModalChat = () => {
    dispatch(setShowModalChat(true));
    console.log("tombol di klik")
  };
  return (
    <div className="w-[1080px] h-[92px] bg-[#E6F2F1CC] rounded-[24px] p-6 mb-14">
      <div className="flex justify-between items-center">
        <div>
          <div className="text-[#25282B] font-bold text-base">
            Masih belum menemukan solusi?
          </div>
          <div className="text-[#25282B] font-normal text-sm mt-2">
            Yuk, Chat Customer Service
          </div>
        </div>
        <div>
          <button
            onClick={hadleOpenModalChat}
            className="border-[#25282B] border-[1px] w-[200px] h-10 rounded-[32px]"
          >
            Chat
          </button>
        </div>
      </div>
    </div>
  );
}
