import React, { useEffect, useState } from 'react'
import DeleteDevices from '../../../../modals/profile/setting/security/dellDevice'
import PhoneCard from './phoneCard'
import Cookies from 'universal-cookie'
import DesktopDevices from './desktopDevice'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { getDeviceLoginList } from '../../../../../redux/slices/profile/security/loginActivity'

export default function LoginActivity() {
  const cookies = new Cookies()
  const dispatch = useAppDispatch()
  const dataDevice = useAppSelector((state) => state.LoginActivity.deviceList)
  const [modalConfirm, setModalConfirm] = useState(false)
  const [selectedDeviceId, setSelectedDeviceId] = useState('')
  const currentDeviceId = cookies.get('deviceId')

  useEffect(() => {
    dispatch(getDeviceLoginList())
  }, [])
  return (
    <div className="grid col-span-1 border-l-[1px] border-[#DDE5E9] px-[50px] py-[16px] text-center">
            <DeleteDevices showModal={modalConfirm} CloseModals={setModalConfirm} deviceId={selectedDeviceId} />
            <h5 className="font-bold text-[24px]">Aktifitas Login</h5>
            <span className="text-start my-[30px] text-[14px]">Aktifitas login saat ini</span>
            <div className="flex flex-col gap-[25px]">
                {dataDevice?.devices?.map((item) => (
                  <>
                        {item.deviceType === 'mobile' ? (
                            <PhoneCard
                                openModal={setModalConfirm}
                                devicesName={item.device || ''}
                                loginAt={item.login_at}
                                deviceId={item.deviceId}
                                isActive={item.deviceId === currentDeviceId}
                            />
                        ) : (
                            <DesktopDevices
                                openModal={setModalConfirm}
                                devicesName={item.device || ''}
                                loginAt={item.login_at}
                                deviceId={item.deviceId}
                                isActive={item.deviceId === currentDeviceId}
                            />
                        )}
                  </>
                ))}
            </div>
        </div>
  )
}
